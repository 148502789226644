import { sliceActionHandler } from "MainApp/Services/Api/helpers";
import { GLOBAL_SEARCH_LIST_PER_PAGE } from "./constants";
import ApiService from "MainApp/Services/Api/ApiService";
import { GLOBAL_SEARCH_INPUT_STATE_UPDATE } from "./actionTypes";


export const fetchGlobalSearchLists = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    const searchKey = userInputData.search;
    const type = userInputData.type
    const response = await sliceActionHandler(() => apiService.get('/post/globalSearch', { page:userInputData.pageNumber, keyword: searchKey, size: userInputData.size || GLOBAL_SEARCH_LIST_PER_PAGE, type:type}), {
        userInputData,
        thunkAPI
    });

    return response;
}


export const globalSearchInputState = (data) => {
    return {
      type: GLOBAL_SEARCH_INPUT_STATE_UPDATE,
      payload: {
        data
      }
    };
}