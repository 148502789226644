export const formatNumberWithADecimal = (num) => {
    const str = num.toString();
    const hasDecimal = str.includes('.');
  
    if (hasDecimal) {
      // If a decimal point exists, use string manipulation to avoid rounding
      const decimalPointIndex = str.indexOf('.');
      const formattedStr = str.slice(0, decimalPointIndex + 2); // Slice to one decimal place (without rounding)
      return formattedStr;
    } else {
      // If no decimal, return the original value
      return num;
    }
}

export const formatNumberAsKMB = (count) => {
    if (count < 1000) {
      return count.toString();
    } else if (count < 1000000) {
      return formatNumberWithADecimal((count / 1000)) + 'K';
    } else if (count < 1000000000) {
      return formatNumberWithADecimal((count / 1000000)) + 'M';
    }
    return count.toString()
}

export const isNumber = (value) => {
  const numberRegex = /^\d+$/;
  return numberRegex.test(value);
}

export const convertMbToBytes = (dataInMb) => {
  if(!dataInMb || !isNumber(dataInMb)) return dataInMb;
  return dataInMb * 1024 * 1024
}