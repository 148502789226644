import { REACT_APP_WEBAPP_API_URL, REACT_APP_WEBAPP_LOGIN_API_URL, REACT_APP_WEBAPP_MEDIA_URL } from "Components/processENV";
import axios from "axios";
import { REACT_APP_WEBAPP_DEVICE_TYPE } from "MainApp/features/App/constants";
import { LoginService } from "../Login/LoginService";
import { getAuthUserDetails, storeAuthUserInLocalStorage } from "../Login/helpers";
import SDK from "Components/SDK";
import { blockOfflineAction } from "MainApp/features/OnlineOffline/helpers";
import { handleApiFailedError, internetFailedStatus } from "./helpers";
import { getSelectedLang } from "MainApp/features/Languages/i18n";

let ApiService_isRefreshing = false;
let ApiService_failedRequestsQueue = [];

class ApiService {
    constructor(baseURL = REACT_APP_WEBAPP_API_URL, customConfig = {}) {

        this.instance = axios.create({
            baseURL,
            // timeout: 5000 // Adjust as needed
        });

        // Request interceptor
        this.instance.interceptors.request.use(
            config => {
                const selectedLang = getSelectedLang();
                config.headers["Content-Type"] = "application/json";
                config.headers["Accept-Language"] = selectedLang === 'id' ? 'idn' : selectedLang;

                // If a token exists, add it to the request headers
                const authUser = getAuthUserDetails();
                if (authUser?.token) {
                    config.headers['Authorization'] = authUser?.token;
                }

                if(customConfig?.headers){
                    config.headers = {...config.headers, ...customConfig.headers};
                }

                if(baseURL === REACT_APP_WEBAPP_MEDIA_URL){
                    config.headers["Content-Type"] = 'multipart/form-data';   
                }

                return config;
            },
            error => {
                console.error('Request error:', error);
                return Promise.reject(error);
            }
        );

        // Response interceptor
        this.instance.interceptors.response.use(
            response => response,
            async (error) => {
                const originalRequest = error.config;

                    // If the error status is 401 and the request hasn't already been retried
                    if (error?.response?.data?.status === 401 && !originalRequest._retry) {
                        if (ApiService_isRefreshing) {
                            return new Promise((resolve, reject) => {
                                ApiService_failedRequestsQueue.push({ resolve, reject, originalRequest });
                            });
                        }
                        originalRequest._retry = true;
                        // Set a flag to prevent multiple simultaneous token refresh attempts
                        ApiService_isRefreshing = true;

                        // Refresh the token and retry the original request
                        return this.refreshToken().then(res => {
                            if(res.status === 200 && res?.data?.token){
                                SDK.setUserToken(res.data.token);
                                this.processQueue(null, res.data.token);
                                return this.recallOriginalRequest(originalRequest, res.data.token);
                            }
                            else {
                                throw(res);
                            }
                        }).catch(async (error) => {
                            if(error?.status === 401){
                                return LoginService.login().then((res) => {
                                    if(res.status === 200 && res?.data?.token){
                                        SDK.setUserToken(res.data.token);
                                        this.processQueue(null, res.data.token);
                                        return this.recallOriginalRequest(originalRequest, res.data.token)
                                    }
                                    else{
                                        LoginService.logout();
                                        this.processQueue(error, null);
                                    }
                                }).catch((error) => {
                                    LoginService.logout();
                                    this.processQueue(error, null);
                                });
                            }
                            this.processQueue(error, null);
                            return Promise.reject(error);
                        });
                    }
                // If the error is not related to token expiration, reject the promise
                return Promise.reject(error);
            }
        );
    }

    async get(url, params = {}) {
        try {
            const response = await this.instance.get(url, { params });
            if(response?.data?.status === 200 || response?.data?.status === 204){
                return {...response.data, options: { serverUTCDate: response.headers.date }};
            }
            throw response.data
        } catch (error) {
            handleApiFailedError(error);
            throw error;
        }
    }

    async post(url, data = {}) {
        try {
            if(blockOfflineAction()){
                throw internetFailedStatus;
            }
            const response = await this.instance.post(url, data);
            if(response?.data?.status === 200){
                return response.data;
            }
            throw response.data
        } catch (error) {
            handleApiFailedError(error);
            throw error;
        }
    }

    async put(url, data = {}) {
        try {
            if(blockOfflineAction()){
                throw internetFailedStatus;
            }
            const response = await this.instance.put(url, data);
            if(response?.data?.status === 200){
                return response.data;
            }
            throw response.data
        } catch (error) {
            handleApiFailedError(error);
            throw error;
        }
    }

    async patch(url, data = {}) {
        // console.log(data,'dodo');
        try {
            if(blockOfflineAction()){
                throw internetFailedStatus;
            }
            const response = await this.instance.patch(url, data);
            if(response?.data?.status === 200){
                return response.data;
            }
            throw response.data
        } catch (error) {
            handleApiFailedError(error);
            throw error;
        }
    }

    async delete(url) {
        try {
            if(blockOfflineAction()){
                throw internetFailedStatus;
            }
            const response = await this.instance.delete(url);
            if(response?.data?.status === 200){
                return response.data;
            }
            throw response.data
        } catch (error) {
            handleApiFailedError(error);
            throw error;
        }
    }

    refreshToken() {
        return new Promise(async (resolve, reject) => {
            try {
                const apiService = new ApiService(REACT_APP_WEBAPP_LOGIN_API_URL);
                const authUser = getAuthUserDetails();
                // Call your API endpoint to refresh the token
                const response = await apiService.post('/refreshToken', {
                    deviceType: REACT_APP_WEBAPP_DEVICE_TYPE,
                    refreshToken: authUser?.refreshToken
                });
                if(response.status === 200){
                    storeAuthUserInLocalStorage(response.data);
                    resolve(response);
                }
                else{
                    reject(response);
                }
            } catch (error) {
                reject(error);
                // reject(`Refresh token failed: ${error.message}`);
            }
        });
    }

    recallOriginalRequest(originalRequest, token) {
        originalRequest.headers['Authorization'] = token;
        return axios(originalRequest);
    }

    processQueue(error, token = null) {
        ApiService_failedRequestsQueue.forEach((prom) => {
            if(token){
                const originalRequest = prom.originalRequest;
                originalRequest.headers['Authorization'] = token;
                prom.resolve(axios(prom.originalRequest));
            }
        });
      
        ApiService_failedRequestsQueue = [];
        ApiService_isRefreshing = false;
    }
}

export default ApiService;