import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PAGE_TYPE } from "MainApp/features/App/constants";
import { clearMapData, deleteMapDataById, formatDataArrayAsMap } from "MainApp/features/Helpers/Map";
import { fetchGuestPostCommentReplies } from "Social/features/Shared/actions";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { POST_COMMENTS_PER_PAGE } from "../constants";
import { getNewCommentObj, updatePostCommentLike } from "../helpers";
import { createPostCommentReply, fetchPostCommentReplies, postCommentReplyDelete, postCommentReplyLike } from "./actions";
import { LoginService } from "MainApp/Services/Login/LoginService";

export const POST_COMMENT_REPLIES_CREATE = 'replies/create';
export const POST_COMMENT_REPLIES_FETCH = 'replies/fetch';
export const POST_COMMENT_REPLY_LIKE = 'replies/like';
export const POST_COMMENT_REPLY_DELETE = 'replies/delete';

export const createPostCommentReplyThunk = createAsyncThunk(
    POST_COMMENT_REPLIES_CREATE, 
    async (userInputData, options) => await createPostCommentReply(userInputData, options)
)

export const fetchPostCommentRepliesThunk = createAsyncThunk(
    POST_COMMENT_REPLIES_FETCH,
    async (userInputData, options) => LoginService.isUserLoggedIn() ? await fetchPostCommentReplies(userInputData, options) : await fetchGuestPostCommentReplies(userInputData, options)
)

export const postCommentReplyLikeThunk = createAsyncThunk(
    POST_COMMENT_REPLY_LIKE,
    async (userInputData, options) => await postCommentReplyLike(userInputData, options)
)

export const postCommentReplyDeleteThunk = createAsyncThunk(
    POST_COMMENT_REPLY_DELETE,
    async (userInputData, options) => await postCommentReplyDelete(userInputData, options)
)

// Structure of data in below object
// Maintain the data based on main/parent commentId
// data = {
//     [COMMENT_ID]: {
//          count: COUNT,
//          totalRecords: TOTAL_RECORDS,
//          data: new Map() // Replies comment maintained here in Map object
//          pageNumber: 1,
//     }
// }

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: {},
    newRepliesData:{},
    actionType: '',
    userInputData: {},
    totalRecords: 0,
    pageNumber: 1,
    count: POST_COMMENTS_PER_PAGE
}

const postCommentRepliesSlice = createSlice({
    name: 'postCommentReplies',
    initialState,
    reducers: {
        resetPostCommentReplies(state){
            return initialState;
        },
        mergeNewRepliesDataIntoData(state, { payload }){
            const parentCommentId = payload.parentCommentId;
            if(state?.newRepliesData?.[parentCommentId]?.data?.size){
                const repliesMap = state.data?.[parentCommentId]?.data || new Map();
                const newRepliesMap = state?.newRepliesData?.[parentCommentId]?.data;
                state.data[parentCommentId].data = new Map([...repliesMap, ...newRepliesMap]);
                state.newRepliesData[parentCommentId].data = clearMapData(state.newRepliesData[parentCommentId].data);
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createPostCommentReplyThunk.pending, (state) => {
            state.loading = ACTION_PENDING;
            state.actionType = POST_COMMENT_REPLIES_CREATE;
        })
        .addCase(createPostCommentReplyThunk.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.actionType = POST_COMMENT_REPLIES_CREATE;
            state.userInputData = payload.userInputData;

            if(payload?.userInputData?.reply && payload?.data?.replyId){
                const data = state.newRepliesData?.[payload.userInputData.commentId]?.data || new Map();
                if(!state.newRepliesData?.[payload.userInputData.commentId]){
                    state.newRepliesData[payload.userInputData.commentId] = {
                        data
                    }
                }
                const newComment = getNewCommentObj({comments: payload?.userInputData?.reply, commentId: payload?.data?.replyId});
                // state.newRepliesData[payload.userInputData.commentId].data = setDataInMapByIndex(data, newComment.commentId, newComment, data.size);
                state.newRepliesData[payload.userInputData.commentId].data.set(newComment.commentId, newComment);
            }
        })
        .addCase(createPostCommentReplyThunk.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.actionType = POST_COMMENT_REPLIES_CREATE;
            state.userInputData = payload.userInputData;
            state.error = payload.error;
        })
        .addCase(fetchPostCommentRepliesThunk.pending, (state) => {
            state.loading = ACTION_PENDING;
            state.actionType = POST_COMMENT_REPLIES_FETCH;
        })
        .addCase(fetchPostCommentRepliesThunk.fulfilled, (state, { payload }) => {
            state.actionType = POST_COMMENT_REPLIES_FETCH;
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            const { userInputData, count, data, totalRecords } = payload;
            if(payload?.status === 200){
                if(state.data[userInputData.commentId]){
                    state.data[userInputData.commentId].data = formatDataArrayAsMap('commentId', data, state.data[userInputData.commentId].data);
                    state.data[userInputData.commentId].pageNumber = userInputData.pageNumber;
                    state.data[userInputData.commentId].count = count;
                    state.data[userInputData.commentId].totalRecords = totalRecords;
                }
                else {
                    state.data[userInputData.commentId] = {
                        count,
                        totalRecords,
                        data: formatDataArrayAsMap('commentId', data, new Map()),
                        pageNumber: userInputData.pageNumber
                    }
                }
            }
        })
        .addCase(fetchPostCommentRepliesThunk.rejected, (state, { payload }) => {
            state.actionType = POST_COMMENT_REPLIES_FETCH;
            state.loading = ACTION_FAILED;
            state.userInputData = payload.userInputData;
            state.error = payload.error;
        })
        .addCase(postCommentReplyLikeThunk.pending, (state) => {
            state.loading = ACTION_PENDING;
            state.actionType = POST_COMMENT_REPLY_LIKE;
        })
        .addCase(postCommentReplyLikeThunk.fulfilled, (state, { payload }) => {
            state.actionType = POST_COMMENT_REPLY_LIKE;
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            const replies = state.data[payload.userInputData.parentCommentId];
            if(replies){
                state.data[payload.userInputData.parentCommentId].data = updatePostCommentLike(replies.data, payload.userInputData);
            }

            const newRepliesData = state.newRepliesData[payload.userInputData.parentCommentId];
            if(newRepliesData){
                state.newRepliesData[payload.userInputData.parentCommentId].data = updatePostCommentLike(newRepliesData.data, payload.userInputData);
            }
        })
        .addCase(postCommentReplyLikeThunk.rejected, (state, { payload }) => {
            state.actionType = POST_COMMENT_REPLY_LIKE;
            state.loading = ACTION_FAILED;
            state.userInputData = payload.userInputData;
            state.error = payload.error;
        })
        .addCase(postCommentReplyDeleteThunk.pending, (state) => {
            state.loading = ACTION_PENDING;
            state.actionType = POST_COMMENT_REPLY_DELETE;
        })
        .addCase(postCommentReplyDeleteThunk.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.actionType = POST_COMMENT_REPLY_DELETE;
            state.userInputData = payload.userInputData;
            const replies = state.data[payload.userInputData.parentCommentId];
            if(replies){
                state.data[payload.userInputData.parentCommentId].data = deleteMapDataById(replies.data, payload.userInputData.commentId);
            }

            const newRepliesData = state.newRepliesData[payload.userInputData.parentCommentId];
            if(newRepliesData){
                state.newRepliesData[payload.userInputData.parentCommentId].data = deleteMapDataById(newRepliesData.data, payload.userInputData.commentId);
            }
        })
        .addCase(postCommentReplyDeleteThunk.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.actionType = POST_COMMENT_REPLY_DELETE;
            state.userInputData = payload.userInputData;
            state.error = payload.error;
        })
    }
});

export const { mergeNewRepliesDataIntoData, resetPostCommentReplies } = postCommentRepliesSlice.actions;
export default postCommentRepliesSlice.reducer;

