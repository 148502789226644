
export const isVideoFileType = fileType => fileType === 'video' || fileType === 1;
export const isImageFileType = fileType => fileType === 'image' || fileType === 0;
export const getFiletypeFromFileInst = (file) => {
  if (!file || !file instanceof File) return;
  return file?.type?.split('/')?.[0];
}
export const getFileExtFromFilenameOrFile = (file) => {
  if (!file) return;

  // Filename - string
  let filename = file;
  // If file is intance of File object. Get the filename from the File object
  if(file instanceof File){
    filename = file.name;
  }

  // Find the last index of the dot (.) character to determine the position of the file extension
  const dotIndex = filename.lastIndexOf(".");
  if (dotIndex !== -1) {
      // If a dot is found, extract the file extension
      return filename.substring(dotIndex + 1);
  }
  return;
}

export const getFilenameWithoutExtension = (file) => {
  if (!file) return;
  // Get the filename from the File object
  let filename = file;
  // If file is intance of File object. Get the filename from the File object
  if(file instanceof File){
    filename = file.name;
  }
  // Find the last index of the dot (.) character to determine the position of the file extension
  const dotIndex = filename.lastIndexOf(".");
  if (dotIndex !== -1) {
      // If a dot is found, extract the filename without the extension
      return filename.substring(0, dotIndex);
  }
    // If no dot is found (i.e., no extension), return the original filename
    return filename;
}

export const base64ToFileInst = (base64Data, fileName) => {
  // Split the base64 string
  const parts = base64Data.split(';base64,');
  const contentType = parts[0].split(':')[1];
  const encodedData = parts[1];

  // Convert binary data
  const binaryData = atob(encodedData);
  const bufferArray = [];
  for (let i = 0; i < binaryData.length; i++) {
    bufferArray.push(binaryData.charCodeAt(i));
  }
  const buffer = new Uint8Array(bufferArray);

  // Create a new Blob
  const blob = new Blob([buffer], { type: contentType });

  // Create a new File object
  return new File([blob], fileName, { type: contentType });
}

export const validateFileExt = (requiredExtensions = [], currentExt) => requiredExtensions.includes(currentExt);
export const validateFileSize = (requiredSize = 0, currentSize) => currentSize <= requiredSize


export const generatBase64FromImage = (imageUrl, quality = 0.1) => {
  const img = new Image();
  img.src = imageUrl;

  const promise = new Promise((resolve, reject) => {
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;

        // Maintain aspect ratio while scaling
        let newWidth = img.naturalWidth;
        let newHeight = img.naturalHeight;
        try {
            // Draw the image onto the canvas (consider downscaling)
            ctx.drawImage(img, 0, 0, newWidth, newHeight);
            // Reduce image quality by setting a lower JPEG compression level
            const imageDataURL = canvas.toDataURL('image/jpeg', quality);
            // Use the imageDataURL as needed
            resolve({base64: imageDataURL, extension: 'jpeg', width: img.naturalWidth, height: img.naturalHeight});
          } catch (error) {
            reject(error);
          }
      };

      // Handle errors if the image fails to load
      img.onerror = function (error) {
        console.error('Error loading image from blob URL:', error);
        // Handle the error (e.g., display a placeholder image)
        reject(error);
      };
  });

  return promise;
}

export const generateBase64FromVideo = (videoUrl, quality = 0.1) => {
  const videoElement = document.createElement('video');
  videoElement.muted = true; // Mute the video by default

  const promise = new Promise((resolve, reject) => {
    videoElement.addEventListener('loadedmetadata', () => {
      setTimeout(() => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const width = videoElement.videoWidth;
            const height = videoElement.videoHeight;

            canvas.width = width;
            canvas.height = height;

            try {
              ctx.drawImage(videoElement, 0, 0, width, height);
              const imageDataURL = canvas.toDataURL('image/jpeg', quality); // Adjust format and quality
              resolve({base64: imageDataURL, fileDuration: videoElement.duration, extension: 'jpeg'});
            } catch (error) {
              console.error('Error generating image from video:', error);
              reject(error);
            }
          }, 100);
    });

    videoElement.addEventListener('error', (error) => {
      console.error('Error loading video:', error);
      reject(error);
    });

    videoElement.src = videoUrl;
    videoElement.play(); // Start playback to ensure a frame is available
  });

  return promise;
}

export const isAbsoluteImageUrl = (url) => {
  return url && (url.startsWith("http://") || url.startsWith("https://") || url.startsWith("blob:") || url.startsWith("data:image/"))
}
