import { formatDataArrayAsMap, getMapDataById, updateMapDataById } from "MainApp/features/Helpers/Map";
import { UPDATE_FOLLOW_STATE_TO_STORE_USER } from "../Follow/actionTypes";
import { FETCH_POST_LIKED_USERS_FAILURE, FETCH_POST_LIKED_USERS_REQUEST, FETCH_POST_LIKED_USERS_RESET, FETCH_POST_LIKED_USERS_SUCCESS, TOGGLE_POST_LIKE_FAILURE, TOGGLE_POST_LIKE_REQUEST, TOGGLE_POST_LIKE_RESET, TOGGLE_POST_LIKE_SUCCESS } from "./actionTypes";
import { POST_LIKED_USERS_PER_PAGE } from "./constants";
import { updateLikedUserFollowing } from "./helpers";
import { cloneDeep } from "lodash";

const initState = {
    isPending: false,
    error: null,
    data: {}
}

export const togglePostLikeReducer = (state = initState, action={}) => {
    const {type, payload = {}} = action;
    switch(type){
        case TOGGLE_POST_LIKE_REQUEST:
            return {
                ...initState,
                isPending: true
            }
        case TOGGLE_POST_LIKE_SUCCESS:
            return {
                ...state,
                isPending: false,
                error: null,
                ...payload
            }
        case TOGGLE_POST_LIKE_FAILURE:
            return {
                ...state,
                isPending: false,
                ...payload
            }
        case TOGGLE_POST_LIKE_RESET:
            return {
                ...initState
            }
        default:
            return state;
    }
}

const postLikedUsersInitState = {
    isPending: false,
    error: null,
    data: new Map(),
    count: POST_LIKED_USERS_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1
}

export const fetchPostLikedUsersReducer = (state=postLikedUsersInitState, action = {}) => {
    const { type, payload = {} } = action;
    switch(type){
        case FETCH_POST_LIKED_USERS_REQUEST:
            return {
                ...state,
                isPending: true
            }
        case FETCH_POST_LIKED_USERS_SUCCESS:
            return {
                ...state,
                isPending: false,
                error: null,
                ...payload,
                data: formatDataArrayAsMap("userId", payload.data, state.data)
            }
        case FETCH_POST_LIKED_USERS_FAILURE:
            return {
                ...state,
                isPending: false,
                ...payload
            }
        case FETCH_POST_LIKED_USERS_RESET:
            const resetNewState = { ...state };
            resetNewState?.data?.clear();
            return {...postLikedUsersInitState, data: resetNewState.data };
        case UPDATE_FOLLOW_STATE_TO_STORE_USER:
            if(!payload.postId){
                const followNewState =  cloneDeep(state);
                const user = getMapDataById(followNewState.data, payload.userId);
                if(user){
                    const userToUpdate = updateLikedUserFollowing(user, payload)
                    followNewState.data = updateMapDataById(followNewState.data, payload.userId, userToUpdate);
                    return followNewState;
                }
            }
            return state;
        default:
            return state;
    }
}