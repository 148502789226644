import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { GLOBAL_SEARCH_LIST_PER_PAGE } from "./constants";
import { fetchGlobalSearchLists } from "./actions";

export const fetchGlobalSearchListsAction = createAsyncThunk(
    'fetch/global/search', 
    async (userInputData, thunkAPI) => await fetchGlobalSearchLists(userInputData, thunkAPI)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: [],
    count: GLOBAL_SEARCH_LIST_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1,
    type:''
}


const globalSearchSlice = createSlice({
    name: 'globalSearchLists',
    initialState,
    reducers: {
        resetGlobalSearchLists(state){
            return initialState;
        },
        setGlobalSearchPending(state){
            state.loading = ACTION_PENDING
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGlobalSearchListsAction.pending, (state, { payload }) => {
            
            state.loading = ACTION_PENDING;
        })
        .addCase(fetchGlobalSearchListsAction.fulfilled, (state, { payload }) => {
            
            state.loading = ACTION_SUCCEEDED;
            //Clear the old data If call is initiated for page 1
            if(payload.userInputData.pageNumber === 1){
                state.data = [];
            }
            const { userInputData, count, data, totalRecords } = payload;
            if(Array.isArray(data)){
                state.data = data || state.data;
                state.count = count || state.count;
                state.totalRecords = totalRecords || state.totalRecords;
                state.pageNumber = userInputData.pageNumber;
                state.type = userInputData.type;
            }else {
                state.data = []
                state.pageNumber = userInputData.pageNumber;
                state.type = userInputData.type;
            }

        })
        .addCase(fetchGlobalSearchListsAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export const { resetGlobalSearchLists,setGlobalSearchPending } = globalSearchSlice.actions;
export default globalSearchSlice.reducer;


