import ApiService from "MainApp/Services/Api/ApiService";
import { SUGGESTED_USERS_PER_PAGE } from "./constants";
import { sliceActionHandler } from "MainApp/Services/Api/helpers";

export const fetchSuggestedUsers = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();

    const response = await sliceActionHandler(() => apiService.get('/users/suggestions', { page:userInputData.pageNumber, search: userInputData.search, size: userInputData.size || SUGGESTED_USERS_PER_PAGE}), {
        userInputData,
        thunkAPI
    });
    return response;
}

export const removeSuggestedUsers = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();

    const response = await sliceActionHandler(() => apiService.post('/users/suggestions/remove', {userId: [userInputData.userId]}), {
        userInputData,
        thunkAPI
    });
    return response;
}