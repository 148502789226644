import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { logout } from "./actions";

export const logoutAction = createAsyncThunk(
    'logout/action',
    async (userInputData, options) => await logout(userInputData, options)
)

const initialState = {
    loading: ACTION_IDLE,
    type: null
}

const logoutSlice = createSlice({
    name: 'logout',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(logoutAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(logoutAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.type = payload?.userInputData?.type;
        })
        .addCase(logoutAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.type = payload?.userInputData?.type;
        })
    }
});
const logoutReducer = logoutSlice.reducer;
export default logoutReducer;