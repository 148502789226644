import { REACT_APP_WEBAPP_LOGIN_API_URL } from "Components/processENV";
import ApiService from "MainApp/Services/Api/ApiService";
import { sliceActionHandler } from "MainApp/Services/Api/helpers";

export const generateInviteCode = async (userInputData, thunkAPI) => {
    const apiService = new ApiService(REACT_APP_WEBAPP_LOGIN_API_URL);

    const response = await sliceActionHandler(() => apiService.get(`/invite`, {mobileNumber: userInputData.inputPayload.mobileNumber, username: userInputData.inputPayload.userId}), {
        userInputData,
        thunkAPI
    });
    return response;
}