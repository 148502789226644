import ApiService from "MainApp/Services/Api/ApiService";
import { sliceActionHandler } from "MainApp/Services/Api/helpers";
import { CLOSE_FRIENDS_LIST_PER_PAGE } from "./constants";

export const fetchCloseFriends = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();

    const response = await sliceActionHandler(() => apiService.get('/users/close-friends', { page:userInputData.pageNumber, search: userInputData.search, size: userInputData.size || CLOSE_FRIENDS_LIST_PER_PAGE}), {
        userInputData,
        thunkAPI
    });
    return response;
}