export const TOGGLE_POST_LIKE_REQUEST = 'TOGGLE_POST_LIKE_REQUEST';
export const TOGGLE_POST_LIKE_SUCCESS = 'TOGGLE_POST_LIKE_SUCCESS';
export const TOGGLE_POST_LIKE_FAILURE = 'TOGGLE_POST_LIKE_FAILURE';
export const TOGGLE_POST_LIKE_RESET = 'TOGGLE_POST_LIKE_RESET';

export const UPDATE_LIKE_STATE_TO_STORE_POST = 'UPDATE_LIKE_STATE_TO_STORE_POST';

export const FETCH_POST_LIKED_USERS_REQUEST = 'FETCH_POST_LIKED_USERS_REQUEST';
export const FETCH_POST_LIKED_USERS_SUCCESS = 'FETCH_POST_LIKED_USERS_SUCCESS';
export const FETCH_POST_LIKED_USERS_FAILURE = 'FETCH_POST_LIKED_USERS_FAILURE';
export const FETCH_POST_LIKED_USERS_RESET = 'FETCH_POST_LIKED_USERS_RESET';