import { DELETED_POST_COMMENT_STATUS, DELETED_POST_STATUS } from "Social/features/Hooks/post";
import toastr from "toastr";

export const internetFailedStatus = {status: 12163, message: 'Internet issue'};

export const handleApiFailedError = (error) => {
    if(error){
        const stringError = typeof error === 'object' && error !== null ? JSON.stringify(error) : String(error);
        if((error?.status && [401, DELETED_POST_STATUS, DELETED_POST_COMMENT_STATUS, internetFailedStatus.status].indexOf(error?.status) > -1) || (typeof stringError?.includes === 'function' && stringError?.includes(401))) return;
        const message = error?.message || 'Something went wrong. Please try again later.';
        toastr.clear();
        toastr.error(message);
    }
}

export const sliceActionHandler = async (action, {userInputData, thunkAPI, successCallback, errorCallback}) => {
    try{
        const response = await action();
        response['userInputData'] = userInputData;
        if(successCallback){
            successCallback(response);
        }
        return response;
    }
    catch(error){
        if(errorCallback){
            errorCallback(error);
        }
        return thunkAPI.rejectWithValue({userInputData, error})
    }
}