import ApiService from "MainApp/Services/Api/ApiService";
import { POST_COMMENTS_PER_PAGE, POST_COMMENT_LIKED_USERS_PER_PAGE } from "../constants";
import { addHashTagsInPayload } from "MainApp/features/HashTagPopup/helpers";

export const createPostCommentReply = async (userInputData, options) => {
    const apiService = new ApiService();
    try{
        let inputPayload = {reply: userInputData.reply};
        inputPayload = addHashTagsInPayload(inputPayload, inputPayload.reply);
        const response = await apiService.post(`/comment/${userInputData.commentId}/reply`, inputPayload);
        response['userInputData'] = userInputData;
        return response;
    }
    catch(error){
        return options.rejectWithValue({userInputData, error})
    }
}

export const fetchPostCommentReplies = async (userInputData, options) => {
    const apiService = new ApiService();
    try{
        const response = await apiService.get(`/comment/${userInputData.commentId}/reply`, { page:userInputData.pageNumber, size:POST_COMMENTS_PER_PAGE, replyCommentId: userInputData.replyCommentId });
        response['userInputData'] = userInputData;
        return response;
    }
    catch(error){
        return options.rejectWithValue({userInputData, error})
    }
}

export const postCommentReplyLike = async (userInputData, options) => {
    const apiService = new ApiService();
    try{
        const response = await apiService.post(`/comment/reply/${userInputData.commentId}/like`, {like: !!userInputData.like});
        response['userInputData'] = userInputData;
        return response;
    }
    catch(error){
        return options.rejectWithValue({userInputData, error})
    }
}

export const postCommentReplyDelete = async (userInputData, options) => {
    const apiService = new ApiService();
    try{
        const response = await apiService.delete(`/comment/reply/${userInputData.commentId}`);
        response['userInputData'] = userInputData;
        return response;
    }
    catch(error){
        return options.rejectWithValue({userInputData, error})
    }
}

export const postCommentReplyLikedUsers = async (userInputData, options) => {
    const apiService = new ApiService();
    try{
        const response = await apiService.get(`/comment/reply/${userInputData.dataId}/like`, { page:userInputData.pageNumber, size:POST_COMMENT_LIKED_USERS_PER_PAGE});
        response['userInputData'] = userInputData;
        return response;
    }
    catch(error){
        return options.rejectWithValue({userInputData, error})
    }
}