import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchCloseFriends } from "./actions";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { CLOSE_FRIENDS_LIST_PER_PAGE } from "./constants";
import { clearMapData, formatDataArrayAsMap } from "MainApp/features/Helpers/Map";

export const fetchCloseFriendsAction = createAsyncThunk(
    'fetch/close/friends', 
    async (userInputData, thunkAPI) => await fetchCloseFriends(userInputData, thunkAPI)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: new Map(),
    count: CLOSE_FRIENDS_LIST_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1
}

const closeFriendsSlice = createSlice({
    name: 'closeFriends',
    initialState,
    reducers: {
        resetCloseFriends(state){
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCloseFriendsAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(fetchCloseFriendsAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            //Clear the old data If call is initiated for page 1
            if(payload.userInputData.pageNumber === 1){
                state.data = clearMapData(state.data);
            }

            const { userInputData, count, data, totalRecords } = payload;
            if(Array.isArray(data)){
                state.data = formatDataArrayAsMap('userId', data, state.data);
                state.count = count || state.count;
                state.totalRecords = totalRecords || state.totalRecords;
                state.pageNumber = userInputData.pageNumber;
            }
        })
        .addCase(fetchCloseFriendsAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.userInputData = payload.userInputData;
        })
    }
});

export const { resetCloseFriends } = closeFriendsSlice.actions;
export default closeFriendsSlice.reducer;