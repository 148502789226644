import Store from "Store";
import { toState } from "Store/helpers";
import { POPUP_COMPONENT_TYPES } from "../Popup/constants";
import { openSocialPopup } from "../Popup/actions";
import { routesPath } from "MainApp/Routes";
import { cloneDeep } from "lodash";
import { formatDataMapAsArray } from "MainApp/features/Helpers/Map";
import { isGivenOverlaySocialPopupOpen, isGivenSocialPopupOpen } from "../Popup/helpers";

export const openPreviewPost = ({post, postIndex, postId, commentId, replyId, pageType}) => {
    Store.dispatch(openSocialPopup(POPUP_COMPONENT_TYPES.previewPost, {post, postIndex, postId, commentId, replyId, pageType}))
}

export const formatPostsArrayAsMap = (postsMap, postsArr) => {
    postsArr.map(post => {
        if(Array.isArray(post?.posts?.files) && post?.posts?.files?.length > 0 && post?.postedUser){
            post.posts.files = post.posts.files.filter((file) => file);
            if(post.posts.files.length > 0){
                postsMap.set(post.posts.id, post);
            }
        }
    });
    return postsMap;
}

export const formatPostsMapAsArray = (posts) => {
    const postsData = posts?.data;
    return formatDataMapAsArray(postsData);
}

export const hasPostsData = (stateful) => {
    const state = toState(stateful);
    return !!state?.posts?.data?.size;
}

export const getPostProfileCompProps = ({post}) => {
    return {
        user: post.postedUser || {},
        postCreatedAt: post.posts.createdAt,
        postLocation: post.posts.location,
        postVisibility: post?.posts?.postVisibility
    }
}

export const isPostPage = (pageType) => {
    return pageType === routesPath.root;
}

export const getStringBetween = (str, start, end) => {
    const startIndex = str.indexOf(start) + start.length;
    const endIndex = str.indexOf(end);
  
    if (startIndex >= 0 && endIndex >= 0 && startIndex < endIndex) {
      return str.substring(startIndex, endIndex);
    } else {
      return ""; // Return empty string if not found
    }
}

export const getPostById = (posts, postId) => {
    if(!posts || !postId) return;
    const postData = posts.data;
    if(postData instanceof Map && postData?.has(postId)){
        return postData.get(postId);
    }
    return;
}

export const updatePostById = (posts, postId, postToUpdate) => {
    if(!posts || !postId || !postToUpdate) return posts;
    const postData = posts.data;
    if(postData && postData instanceof Map){
        postData.set(postId, postToUpdate);
        posts.data = postData;
    }
    return posts;
}
/**
 * Updated the liked count in post which is in redux store
 * @param {*} post 
 * @param {*} likeInfo 
 * @returns 
 */
export const updatePostLikeCount = (post, likeInfo) => {
    if(!likeInfo || !post) return post;
    const postToUpdate = cloneDeep(post);
    postToUpdate.ownUserLikeStatus = Number(likeInfo.like);
    postToUpdate.posts.noOfLikes = likeInfo.count;
    return postToUpdate;
}

/**
 * Update the user follow/following state in post which is in redux store
 * @param {*} post 
 * @param {*} followInfo 
 * @returns 
 */
export const updatePostUserFollowState = (post, followInfo) => {
    if(!followInfo || !post) return post;
    const postToUpdate = cloneDeep(post);
    postToUpdate.isFollowing = Number(followInfo.following);
    return postToUpdate;
}

export const isRejectPostPopupOpen = () => {    
    return isGivenSocialPopupOpen(POPUP_COMPONENT_TYPES.rejectPostPopup) || isGivenOverlaySocialPopupOpen(POPUP_COMPONENT_TYPES.rejectPostPopup)
}

export const deletePostByIdFromPostsArr = (posts, postId) => {
    if(!Array.isArray(posts) || !postId) return posts;
    const postIndex = posts.findIndex((post) => post?.posts?.id === postId);
    if(postIndex > -1){
        return posts.slice(0, postIndex).concat(posts.slice(postIndex + 1));
    }
    return posts;
}