import { RESET_TOGGLE_PROFILE_FOLLOW, TOGGLE_PROFILE_FOLLOW_FAILURE, TOGGLE_PROFILE_FOLLOW_REQUEST, TOGGLE_PROFILE_FOLLOW_SUCCESS } from "./actionTypes";

const initState = {
    isPending: false,
    error: null,
    data: {}
}

export const toggleProfileFollowReducer = (state = initState, action={}) => {
    const {type, payload = {}} = action;
    switch(type){
        case TOGGLE_PROFILE_FOLLOW_REQUEST:
            return {
                ...initState,
                isPending: true,
                ...payload
            }
        case TOGGLE_PROFILE_FOLLOW_SUCCESS:
            return {
                ...state,
                isPending: false,
                error: null,
                ...payload
            }
        case TOGGLE_PROFILE_FOLLOW_FAILURE:
            return {
                ...state,
                isPending: false,
                ...payload
            }
        case RESET_TOGGLE_PROFILE_FOLLOW:
            return {
                ...initState
            }
        default:
            return state;
    }
}