import { deleteItemFromLocalStorage, encryptAndStoreInLocalStorage } from "Components/WebChat/WebChatEncryptDecrypt";
import { toast } from "react-toastify";
import ApiService from "../Api/ApiService";
import { getAuthUserDetails, getLoginObj, storeAuthUserInLocalStorage } from "./helpers";
import { REACT_APP_WEBAPP_LOGIN_API_URL } from "Components/processENV";
import { SERVER_LOGOUT } from "Helpers/Chat/Constant";
import Store from "Store";
import { logoutAction } from "MainApp/features/Login/logoutSlice";
import { routesPath } from "MainApp/Routes";

export const LoginService = {
    login: async () => {
        const authUser = getAuthUserDetails();
        const inputData = getLoginObj(authUser);
        // API call for login
        const apiService = new ApiService(REACT_APP_WEBAPP_LOGIN_API_URL);
        const loginRes = await apiService.post('/login', inputData);
        if(loginRes?.status === 200){
            storeAuthUserInLocalStorage(loginRes.data);
        }
        else{
            if(loginRes?.message){
                toast.error(loginRes.message);
            }
        }
        return loginRes;
    },
    logout: (type) => {
        Store.dispatch(logoutAction({type}));
    },
    logoutClearData: (type) => {
        deleteItemFromLocalStorage('username');
        deleteItemFromLocalStorage("auth_user");
        deleteItemFromLocalStorage("auth_user_social");
        deleteItemFromLocalStorage('getuserprofile');
        deleteItemFromLocalStorage("chatTagsIdOrder");
        localStorage.clear();
        // Delete all indexDB record
        window.indexedDB.deleteDatabase("mirrorflydb");
        if (type === "accountDeleted") {
            encryptAndStoreInLocalStorage("deleteAccount", true);
        }
        if(type === SERVER_LOGOUT){
            encryptAndStoreInLocalStorage("serverLogout",type);
        }
        window.location.reload();
    },
    isUserLoggedIn: () => {
        // return true;
        return !!getAuthUserDetails();
    },
    checkLoginStatus: (history) => {
        if(!LoginService.isUserLoggedIn()){
            history.push(routesPath.login);
            return false;
        }
        return true;
    }
}