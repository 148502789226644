import ApiService from "MainApp/Services/Api/ApiService";
import { FETCH_POST_LIKED_USERS_FAILURE, FETCH_POST_LIKED_USERS_REQUEST, FETCH_POST_LIKED_USERS_RESET, FETCH_POST_LIKED_USERS_SUCCESS, TOGGLE_POST_LIKE_FAILURE, TOGGLE_POST_LIKE_REQUEST, TOGGLE_POST_LIKE_RESET, TOGGLE_POST_LIKE_SUCCESS, UPDATE_LIKE_STATE_TO_STORE_POST } from "./actionTypes";
import { POST_LIKED_USERS_PER_PAGE } from "./constants";


export const togglePostLikeRequest = () => {
    return {
      type: TOGGLE_POST_LIKE_REQUEST
    };
}
  
export const togglePostLikeSuccess = (data) => {
    return {
      type: TOGGLE_POST_LIKE_SUCCESS,
      payload: {
        data
      }
    };
}
  
export const togglePostLikeFailure = (error, userInputData) => {
    return {
      type: TOGGLE_POST_LIKE_FAILURE,
      payload: {
        error,
        userInputData
      } 
    };
}

export const togglePostLikeReset = () => {
  return {
    type: TOGGLE_POST_LIKE_RESET 
  };
}

export const updateLikeStateToStoreUser = (payload) => {
  return {
    type: UPDATE_LIKE_STATE_TO_STORE_POST,
    payload: {
      ...payload 
    }
  }
}

export const togglePostLike = (payload) => {
  const apiService = new ApiService();
  return (dispatch) => {
      dispatch(togglePostLikeRequest());
      apiService.post(`/like/${payload.postId}`, {like: !!payload.like})
      .then((response) => {
          if(response?.status === 200){
            dispatch(updateLikeStateToStoreUser(payload));
            dispatch(togglePostLikeSuccess(payload));
          }
          else{
            throw response;
          }
      })
      .catch((error) => {
          dispatch(togglePostLikeFailure(error, payload));
      });

  }
}


export const fetchPostLikedUsersRequest = () => {
  return {
    type: FETCH_POST_LIKED_USERS_REQUEST
  };
}

export const fetchPostLikedUsersSuccess = (likeUsersObj) => {
  return {
    type: FETCH_POST_LIKED_USERS_SUCCESS,
    payload: {
      ...likeUsersObj
    }
  };
}

export const fetchPostLikedUsersFailure = (error, userInputData) => {
  return {
    type: FETCH_POST_LIKED_USERS_FAILURE,
    payload: {
      error,
      userInputData
    } 
  };
}

export const fetchPostLikedUsersReset = () => {
  return {
    type: FETCH_POST_LIKED_USERS_RESET
  };
}
export const fetchPostLikedUsers = ({dataId, pageNumber = 1}) => {
    const apiService = new ApiService();
    return (dispatch) => {
        dispatch(fetchPostLikedUsersRequest());
        apiService.get(`/like/${dataId}`, { page:pageNumber, size:POST_LIKED_USERS_PER_PAGE, search:'' })
        .then((response) => {
            if(response?.status === 200){
              const{count, data, totalRecords} = response;
              dispatch(fetchPostLikedUsersSuccess({count, data, totalRecords, pageNumber}));
            }
            else{
              throw response;
            }
        })
        .catch((error) => {
            dispatch(fetchPostLikedUsersFailure(error, {postId: dataId}));
        });

    }
}