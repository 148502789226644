import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { usernameAvailable } from "./actions";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";

export const usernameAvailableAction = createAsyncThunk(
    'profile/username/suggestion', 
    async (userInputData, thunkAPI) => await usernameAvailable(userInputData, thunkAPI)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: [],
    message: null
}

const usernameAvailableSlice = createSlice({
    name: 'usernameAvailable',
    initialState,
    reducers: {
        resetUsernameAvailable(state, { payload }){
            const message = typeof payload?.message !== 'undefined' ? payload.message : state.message;
            const newState = {...initialState, message};
            return newState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(usernameAvailableAction.pending, (state) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(usernameAvailableAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            const suggestions = payload?.data?.suggestions || [];
            state.data = suggestions;
            if(suggestions.length){
                state.message = payload.message;
            }
            else{
                state.message = null;
            }
        })
        .addCase(usernameAvailableAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export const { resetUsernameAvailable } = usernameAvailableSlice.actions;
export default usernameAvailableSlice.reducer;