import { PAGINATION_RECORD_PER_PAGE } from "MainApp/features/App/constants";

export const IN_APP_NOTIFY_PER_PAGE = PAGINATION_RECORD_PER_PAGE;
export const NOTIFICATION_TYPE = {
    follow: "follow",
    followRequest: "follow_request",
    postLike:"post_like",
    comment: "comment",
    commentLike: "comment_like",
    commentReply: "comment_reply",
    commentReplyLike: "comment_reply_like"
}