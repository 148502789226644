// Display DISCONNECTED text duration in milli seconds
export const DISCONNECTED_SCREEN_DURATION = 1000;
export const MISSEDCALL_SCREEN_DURATION = 3000;
// Call Ringing duration in milli seconds
export const CALL_RINGING_DURATION = 30000;
// CALL SESSION STATUS
export const CALL_SESSION_STATUS_CLOSED = 'closed';
export const CALL_SESSION_STATUS_EXIT = 'exit';
// CALL SWITCH PROCESS
export const CALL_CONVERSION_STATUS_ACCEPT = 'accept';
export const CALL_CONVERSION_STATUS_DECLINE = 'decline';
export const CALL_CONVERSION_STATUS_CANCEL = 'cancel';
export const CALL_CONVERSION_STATUS_REQ_WAITING = 'request_waiting';
export const CALL_CONVERSION_STATUS_REQUEST_INIT = 'request_init';
export const CALL_CONVERSION_STATUS_REQUEST = 'request';
// CALL STATUS
export const CALL_STATUS_RECONNECT = 'reconnecting';
export const CALL_STATUS_CONNECTED = 'connected';
export const CALL_STATUS_DISCONNECTED = 'disconnected';
export const CALL_STATUS_ENDED = 'ended';
export const CALL_STATUS_CONNECTING = 'connecting';
export const CALL_STATUS_CALLING = 'calling';
export const CALL_STATUS_BUSY = 'busy';
export const CALL_STATUS_ENGAGED = 'engaged';
export const CALL_STATUS_RINGING = 'ringing';
export const CALL_STATUS_HOLD = 'hold';
export const CALL_STATUS_ATTENDED = 'attended';

// MESSAGE BASED ON CALL STATUS
export const CALL_BUSY_STATUS_MESSAGE = 'User is busy...';
export const CALL_ENGAGED_STATUS_MESSAGE = 'Busy on another call';
export const CALL_HOLD_STATUS_MESSAGE = 'Call on hold';
export const CALL_USER_UNAVAILABLE_STATUS_MESSAGE = 'Unavailable, Try again later';
// CALL TYPE
export const CALL_TYPE_AUDIO = "audio";
export const CALL_TYPE_VIDEO = "video";

export const COMMON_ERROR_MESSAGE = "Something went wrong. Please try again.";
export const PERMISSION_DENIED = "Permission denied";

export const FEATURE_RESTRICTION_ERROR_MESSAGE = "Feature unavailable for your plan";

export const MIC_PERMISSION_DENIED = 100606;
export const CAMERA_PERMISSION_DENIED = 100607;
export const CAMERA_MIC_PERMISSION_DENIED = 100608;
export const MIC_MEDIA_NOT_FOUND_ERROR = 100609;
export const CAMERA_MEDIA_NOT_FOUND_ERROR = 100610;
export const MIC_MEDIA_NOT_READABLE_ERROR = 100611;
export const CAMERA_MEDIA_NOT_READABLE_ERROR = 100612;
export const MEDIA_OVER_CONSTRAINED_ERROR = 100613;
export const MEDIA_NOT_ALLOWED_ERROR = 100614;
export const MEDIA_TYPE_ERROR = 100615;