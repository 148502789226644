export const id = {
    "common": {
        "text": {
            "enkripcomingsoon...": "Enkrip Segera Hadir...",
            "hi": "Hai",
            "trythewholesomeexperienceofENKRIP": "Coba pengalaman ENKRIP yang menyenangkan",
            "profile": "Profil",
            "contacts": "Kontak",
            "noresponsefrom": "Tidak ada tanggapan dari",
            "s": "detik",
            "an": "seorang",
            "a": "sebuah",
            "call": "panggilan",
            "call": "Panggilan",
            "at": "pada",
            "offline": "offline",
            "notsent": "tidak terkirim",
            "sent": "terkirim",
            "processing": "sedang diproses",
            "left": "keluar",
            "removedyou": "menghapus Anda",
            "removed": "menghapus",
            "youupdated": "Anda memperbarui",
            "updated": "diperbarui",
            "profile": "profil",
            "retry": "Coba lagi",
            "loading": "Memuat...",
            "connected": "TERHUBUNG",
            "enkrip": "ENKRIP",
            "termsandprivacypolicyus": "Syarat dan Kebijakan Privasi Kami",
            "contactpermission": "Izin Kontak",
            "thelast5messagesfromthis": "5 pesan terakhir dari",
            "single": "tunggal",
            "contact": "kontak",
            "willbeforwardedtoadmin": "akan diteruskan ke admin.",
            "nooneinthisgroupwillbenotified": "Tidak ada yang akan diberitahu dalam grup ini.",
            "onunblockingthiscontactwillbeabletocallyouorsendmessagestoyou": "Jika membuka blokir, kontak ini dapat menelepon Anda atau mengirim pesan kepada Anda.",
            "onblockingthiscontactwillnotbeabletocallyouorsendmessagestoyou": "Jika diblokir, kontak ini tidak dapat menelepon Anda atau mengirim pesan kepada Anda.",
            "unblock": "Buka blokir",
            "block": "Memblokir ",
            "mutualFriends": "Teman Bersama"
        }
        ,
        "button": {
            "next": "Selanjutnya",
            "cancel": "Batal",
            "clear": "Hapus",
            "no": "Tidak",
            "delete": "Hapus",
            "send": "Kirim",
            "continue": "Lanjutkan",
            "ok": "Ok",
            "remove": "Hapus",
            "deleteaction": "HapusAksi",
            "clearaction": "HapusAksi",
            "pleaseselectareason": "Silakan pilih alasan",
            "deleteforme":"Hapus Untuk Saya",
            "deleteforeveryone":"Hapus Untuk Semua Orang",
            "thisuserisnolongeravailable":"Pengguna ini tidak lagi tersedia",
            "clickon":"Klik",
            "switch":"MENGALIHKAN",
            "decline":"MENOLAK",
            "accept":"MENERIMA",
            "usehere":"Gunakan Di Sini",
            "yes": "Ya",
            "create":"Membuat",
            "update":"Memperbarui",
            "add": "Menambahkan",
            "clearCallLog": "Hapus log panggilan",
            "viewProfile": "Lihat Profil"
        }
         
    },
    "label": {
        "search": "Cari",
        "closesearch":"TutupPencarian",
        "yesterday": "Kemarin",
        "you": "Anda",
        "contactinfo": "Info Kontak",
        "aboutandphonenumber": "Tentang dan nomor telepon",
        "blockcontact": "Blokir Kontak",
        "admin": "Admin",
        "chat": "Obrolan",
        "chats": "Obrolan",
        "archivedsettings": "Pengaturan Diarsipkan",
        "notifications": "Notifikasi",
        "starred": "Diberi Bintang",
        "unstarred":"Tidak Berbintang",
        "starredmessages": "Pesan yang Diberi Bintang",
        "boxedview": "Tampilan Kotak",
        "translatemessage": "Terjemahkan Pesan",
        "enabletranslatemessagetochoosetranslationlanguage": "Aktifkan Terjemahkan Pesan untuk memilih Bahasa Terjemahan",
        "choosetranslationlanguage": "Pilih Bahasa Terjemahan",
        "mouseoverreceivedmessagechatbubblewhichdisplaystranslatemessageiconclickittotranslatethemessage": "Arahkan kursor ke balon obrolan pesan yang diterima yang menampilkan ikon Terjemahkan Pesan, klik untuk menerjemahkan pesan",
        "keepchatsarchived": "Simpan obrolan diarsipkan",
        "archivedchatswillremainarchivedwhenyoureceiveanewmessage": "Obrolan yang diarsipkan akan tetap diarsipkan ketika Anda menerima pesan baru",
        "unstarall": "Batalkan bintang semua",
        "1messageunstarred": "1 pesan tidak diberi bintang",
        "nostarredmessages": "Tidak ada pesan yang diberi bintang",
        "title": "Judul",
        "description": "Deskripsi",
        "Send": "Kirim",
        "country": "Negara",
        "mobilenumber": "Nomor ponsel",
        "entermobilenumber": "Masukkan Nomor Ponsel",
        "starttyping": "Mulai Mengetik...",
        "groupcall": "Panggilan Grup",
        "inviteparticipants":"Undang Peserta",
        "callnow": "Panggil sekarang",
        "aboutandemail": "Tentang dan email",
        "tryingtoconnect": "Mencoba terhubung",
        "userseemstobeofflinetryingtoconnect": "Pengguna tampaknya offline, mencoba terhubung",
        "videocall": "Panggilan Video",
        "audiocall": "Panggilan Audio",
        "youarealreadyincall":"Anda sudah menerima panggilan",
        "hangup": "Tutup panggilan",
        "calldisabled":"panggilan dinonaktifkan",
        "mute": "Bisukan",
        "unmute": "Membunyikan",
        "muteunmute":"Bisukan / Suarakan",
        "reply": "Balas",
        "forward": "Teruskan",
        "unstar": "Batalkan bintang",
        "star": "Bintang",
        "oops": "Ups...",
        "callended": "Panggilan Berakhir!",
        "nooneelseishere": "Tidak ada orang lain di sini",
        "maximum": "Maksimum",
        "charactersallowed":"karakter diperbolehkan",
        "membersallowedinacall":"anggota diperbolehkan dalam panggilan",
        "to": "ke",
        "recentchat": "Obrolan Terbaru",
        "user": "Pengguna",
        "message": "pesan",
        "deleted": "dihapus",
        "pinned": "Ditahan",
        "unpinned": "Tidak ditahan",
        "moreoptions": "Opsi Lainnya",
        "back": "Kembali",
        "thisgroupisnolongeravailable":"Grup ini tidak lagi tersedia",
        "chooseacountry":"Pilih satu negara",
        "pleaseentervalidmobilenumber":"Silakan masukkan nomor ponsel yang valid",
        "pleaseselectcountry":"Silakan pilih negara",
        "backtohome ":"Kembali ke rumah",
        "youhave":"Kamu punya",
        "snewchat":"obrolan baru",
        "total":"Total",
        "unreadmessage":"pesan yang belum dibaca",
        "members":"anggota",
        "members":"anggota",
        "make":"Membuat",
        "fromthisgroup?":"dari grup ini?",
        "deleteduser":"Pengguna yang Dihapus",
        "enteryourname":"Masukkan nama Anda",
        "orsearchtostartacall":"atau Cari untuk memulai Panggilan!",
        "makegroupadmin":"Jadikan admin grup",
        "exit":"KELUAR",
        "sunday":"Minggu",
        "monday":"Senin",
        "tuesday":"Selasa", 
        "wednesday":"Rabu", 
        "thursday":"Kamis", 
        "friday":"Jumat", 
        "saturday":"Sabtu",
        "today": "Hari ini",
        "jan":"Jan", 
        "feb":"Feb", 
        "mar":"Mar", 
        "apr":"Apr", 
        "may":"May", 
        "jun":"Jun", 
        "jul":"Jul", 
        "aug":"Aug", 
        "sep":"Sep", 
        "oct":"Oct", 
        "nov":"Nov", 
        "dec":"Dec",
        "isopeninanotherwindow":"terbuka di jendela lain.",
        "clickuseheretouse":"Klik 'Gunakan Di Sini' untuk menggunakan",
        "inthiswindow":"di jendela ini.",
        "yourenkripaccounthasbeendeleted":"Akun Enkrip Anda telah dihapus.",
        "morehave":"lebih banyak",
        "joined":"bergabung",
        "left":"kiri",
        "and":"Dan",
        "thisapplicationisnolongeravailableforyou":"Aplikasi ini tidak lagi tersedia untuk Anda.",
        "pleasecontactadminifyouhaveanyquery":"Silakan hubungi admin jika Anda memiliki pertanyaan.",
        "mailto":"surat ke:",
        "readby":"Baca oleh",
        "deliveredto":"Dikirim ke",
        "member":"anggota",
        "members":"anggota",
        "termsandconditions": "syarat dan Ketentuan",
        "privacypolicy": "Kebijakan pribadi."


    }
    ,
    "icontext": {
        "chatting": "MENGOBROL",
        "voice": "SUARA",
        "photo": "Foto",
        "audio": "Audio",
        "video": "Video",
        "photovideo":"Foto / Video",
        "file": "Berkas",
        "contact": "Kontak",
        "location": "Lokasi",
        "gif": "Gif",
        "sticker": "Stiker",
        "viewphoto": "Lihat Foto",
        "takephoto": "Ambil Foto",
        "removephoto": "Hapus Foto",
        "uploadphoto": "Unggah Foto",
        "newchat": "Obrolan Baru",
        "mute": "Diamkan",
        "report": "Laporkan",
        "participants": "Peserta",
        "addparticipants": "Tambah Peserta",
        "addgroupparticipants": "Tambahkan Peserta Grup",
        "exitgroup": "Keluar dari Grup",
        "reportgroup": "Laporkan Grup",
        "deletegroup": "Hapus Grup",
        "document": "Dokumen",
        "camera": "Kamera",
        "stoprecord": "Stop Rekaman",
        "sendrecord": "Kirim Rekaman",
        "addfile": "Tambahkan Berkas",
        "recapture": "Ambil Ulang",
        "updateprofile": "Perbarui Profil",
        "participantismuted": "Peserta dimute",
        "participanthasstoppedthecamera": "Peserta telah menghentikan kamera",
        "backtochat": "Kembali ke Obrolan",
        "toggletileview": "Beralih ke Tampilan Kotak",
        "clearsearch": "Bersihkan Pencarian",
        "record": "Rekam",
        "previous": "Sebelumnya",
        "findinchat": "Cari di Obrolan",
        "close": "Tutup",
        "attachment": "Lampiran",
        "gifsandstickers": "GIF dan Stiker",
        "messageInfo": "Info Pesan",
        "addasfavourite": "Tambahkan ke Favorit",
        "removefromfavourite": "Hapus dari Favorit",
        "selectgroupmembers": "Pilih Anggota Grup",
        "creategroup": "Buat Grup",
        "editname": "Edit Nama",
        "editstatus": "Edit Status"
    }
    ,
    "infotext": {
        "yourstatus": "Status Anda",
        "profileimagenameandstatuscanbechangedatanytime": "Gambar profil, nama, dan status dapat diubah kapan saja",
        "itseemsliketherearenocontacts": "Sepertinya tidak ada kontak",
        "calllogs": "Log Panggilan",
        "nocallloghistoryfound": "Tidak ditemukan riwayat log panggilan",
        "ohsnapitseemsliketheyrenocallhistory":"Oh sial. Sepertinya tidak ada riwayat panggilan!",
        "anynewcallswillappearhere": "Panggilan baru akan muncul di sini",
        "noresultsfound": "Tidak ditemukan hasil",
        "nocontactsfound": "Tidak ditemukan kontak",
        "newgroup": "Grup Baru",
        "archived": "Arsip",
        "archivedchat":"Obrolan yang Diarsipkan",
        "archivedchats":"Obrolan yang Diarsipkan",
        "unarchivedchat":"Obrolan Tidak Diarsipkan",
        "settings": "Pengaturan",
        "logout": "Keluar",
        "addgroupicon": "Tambahkan Ikon Grup",
        "typegroupnamehere": "Ketik nama grup di sini...",
        "noarchivedchats": "Tidak ada obrolan terarsip",
        "ohsnapitseemsliketherearenochat":"Oh jepret Sepertinya tidak ada obrolan!",
        "tryingtoconnect": "Mencoba untuk terhubung",
        "userseemstobeofflinetryingtoconnect": "Pengguna tampaknya offline, mencoba untuk terhubung",
        "media":"Media",
        "docs":"dokumen",
        "links":"Tautan",
        "mediaanddocs":"Media dan Dokumen",
        "thereisnoconversation":"Tidak ada percakapan.",
        "shortstoriestxt":"Cerita pendek.txt",
        "clearchat": "Obrolan Yang Jelas",
        "deletechat": "Hapus Obrolan",
        "report": "Laporkan",
        "onblockingthiscontactwillnotbeabletocallyouorsendmessagestoyou": "Jika diblokir, kontak ini tidak akan bisa menelepon atau mengirim pesan kepada Anda.",
        "thelast5messagesfromthiscontactwillbeforwardedtoadminthiscontactwillnotbenotified.": "5 pesan terakhir dari kontak ini akan diteruskan ke admin. Kontak ini tidak akan diberi tahu.",
        "groupinfo": "Info Grup",
        "nochatsorcontactsfound": "Tidak ditemukan obrolan atau kontak",
        "thelast5messagesfromthisgroupwillbeforwardedtoadminnooneinthisgroupwillbenotified.": "5 pesan terakhir dari grup ini akan diteruskan ke admin. Tidak ada yang akan diberi tahu dalam grup ini.",
        "blocked": "Diblokir",
        "blockedcontacts": "Kontak Diblokir",
        "noblockedcontactsfound": "Tidak ditemukan Kontak Diblokir",
        "aboutandhelp": "Tentang dan Bantuan",
        "deletemyaccount": "Hapus Akun Saya",
        "releasedon": "Dirilis Pada:",
        "version": "Versi:",
        "thereisnostarredmessage": "Tidak ada pesan yang ditandai bintang.",
        "youdeletedthismessage": "Anda menghapus pesan ini",
        "thismessagewasdeleted": "Pesan ini dihapus",
        "aboutus": "Tentang Kami",
        "enkripisareadytogomessagingsolutionforbuildingenterprisegraderealtimechatimapplicationsthatmeetvariousdegreesofrequirementsliketeamdiscussiondatasharingtaskdelegationandinformationhandlingonthego": "ENKRIP adalah solusi pesan siap pakai untuk membangun aplikasi obrolan real-time tingkat perusahaan yang memenuhi berbagai tingkat kebutuhan seperti diskusi tim, berbagi data, delegasi tugas, dan penanganan informasi saat bepergian.",
        "contactus": "Hubungi Kami",
        "tohaveadetailedinteractionwithourexperts": "Untuk berinteraksi secara detail dengan para ahli kami",
        "FAQ": "FAQ",
        "ENKRIP":"ENKRIP",
        "kindlycheckoutFAQsectionfordoubtsregardingenkripwemighthavealreadyansweredyourquestion": "Silakan lihat bagian FAQ untuk keraguan seputar ENKRIP. Mungkin kami sudah menjawab pertanyaan Anda.",
        "thankyouforcontactingus": "Terima kasih telah menghubungi kami!",
        "termsandprivacypolicy": "Syarat dan Kebijakan Privasi",
        "searchfor":"pencarian untuk",
        "gifs":"GIF",
        "stickers":"Stiker",
        "wehatetoseeyouleaveTelluswhyyouaredeletingyouraccount": "Sayang melihat Anda pergi! Beri tahu kami mengapa Anda menghapus akun Anda:",
        "tellushowwecanimprove": "Beritahu kami bagaimana kami bisa memperbaiki",
        "wewillstoreyourfeedbackforfuturepurpose": "Kami akan menyimpan umpan balik Anda untuk keperluan mendatang.",
        "iamchangingmydevice": "Saya mengganti perangkat saya",
        "iamchangingmyphonenumber": "Saya mengganti nomor telepon saya",
        "enkripismissingafeature": "ENKRIP kehilangan fitur",
        "enkripisnotworking": "ENKRIP tidak berfungsi",
        "other": "Lainnya",
        "nomediafound": "Tidak ditemukan Media",
        "preview": "Pratinjau",
        "addacaption": "Tambahkan keterangan",
        "retake": "Ambil ulang",
        "youcreatedthisgroup": "Anda membuat grup ini",
        "youcreated": "Anda membuat ",
        "created":"dibuat",
        "thisgroup":"kelompok ini",
        "broadcast":"siaran",
        "youadded": "Anda menambahkan",
        "isnowadmin": "sekarang admin",
        "isnowanadmin": "sekarang menjadi admin",
        "isremovedfromgroup": "dihapus dari grup",
        "youremoved ": "Anda menghapus",
        "added": "menambahkan",
        "removed": "menghapus",
        "dragandadjust": "Tarik dan Sesuaikan",
        "cameranotfound": "Kamera Tidak Ditemukan!",
        "makeadmin": "Jadikan admin",
        "removefromgroup": "Hapus dari Grup",
        "today": "Hari ini",
        "online": "Online",
        "lastseentodayat": "terakhir terlihat hari ini pada",
        "lastseenyesterdayat": "terakhir terlihat kemarin pada",
        "lastseenon": "terakhir terlihat pada",
        "lastseen": "terakhir terlihat",
        "now": "Sekarang",
        "minago": "menit yang lalu",
        "allmembersofyourcontactsarealreadyinthecall": "Semua anggota kontak Anda sudah dalam panggilan.",
        "calllinkcopied": "Tautan panggilan disalin",
        "microphoneoff": "Mikrofon dimatikan",
        "cameraoff": "Kamera dimatikan",
        "archivedchatsaremuted": "Obrolan yang diarsipkan dibisukan",
        "youcantsendmessagetothisblockedcontact": "Anda tidak dapat mengirim pesan ke kontak yang diblokir ini.",
        "youcantsendmessagestothisgroupbecauseyourenolongeraparticipant": "Anda tidak dapat mengirim pesan ke grup ini karena Anda bukan lagi peserta.",
        "thismessagewillbeforwardedtoadmin": "Pesan ini akan diteruskan ke admin.",
        "thiscontactwillnotbenotified": "Kontak ini tidak akan diberi tahu.",
        "messageinfo": "Info Pesan",
        "download": "Unduh",
        "report": "Laporkan",
        "selected": "Dipilih",
        "drag": "Tarik",
        "dropyourfileshere": "Letakkan file Anda di sini",
        "eraseyourmessagehistory": "Hapus riwayat pesan Anda",
        "invalidcalllink": "Tautan panggilan tidak valid",
        "returntochat": "Kembali ke obrolan",
        "viewcontact": "Lihat kontak",
        "forwardmessage": "Teruskan pesan",
        "filedoesntexists": "File tidak ada",
        "requestdeclined": "Permintaan ditolak",
        "cannotreportdeleteduser": "Tidak dapat melaporkan pengguna yang dihapus",
        "thisusernolongeravailable": "Pengguna ini tidak lagi tersedia",
        "featureunavailableforyourplan": "Fitur tidak tersedia untuk paket Anda",
        "youareonlyonememberinthegroup": "Anda adalah satu-satunya anggota dalam grup",
        "thesessionhasbeenloggedout": "Sesi telah keluar",
        "nomessagestoreport": "Tidak ada pesan untuk dilaporkan",
        "cannotreportdeletedusersmessage": "Tidak dapat melaporkan pesan pengguna yang dihapus",
        // "1messageunstarred": "1 pesan tidak ditandai bintang",
        "incoming": "Masuk",
        "group": "Grup",
        "newincomingcall": "Panggilan Masuk Baru",
        "youmissed": "Anda melewatkan",
        "newmissedcall": "Panggilan Terlewat Baru",
        "audio": "audio",
        "sgroup": "grup",
        "ongoing":"Sedang berlangsung",
        "youarenowanadmin": "Anda sekarang adalah admin",
        "orsearchtostartaconvo":"atau Telusuri untuk memulai Obrolan!",
        "forwardmessageuserlimit": "Anda hanya dapat meneruskan hingga {{size}} pengguna atau grup"
    },
    "alertText": {
        "areyousureyouwantto": "Apakah Anda yakin ingin melakukannya",
        "areyousureyouwanttoclearthechat?": "Apakah Anda yakin ingin menghapus obrolan?",
        "areyousureyouwanttodeletethechat?": "Apakah Anda yakin ingin menghapus obrolan?",
        "report?": "Laporkan?",
        "areyousureyouwanttoexitfromthegroup?": "Apakah Anda yakin ingin keluar dari grup?",
        "areyousureyouwanttodeletethegroup?": "Apakah Anda yakin ingin menghapus grup?",
        "areyousureyouwanttologout?": "Apakah Anda yakin ingin keluar?",
        "areyousureyouwanttounstarallmessages?": "Apakah Anda yakin ingin menghapus bintang dari semua pesan?",
        "deletingyouraccountwill": "Menghapus akun Anda akan:",
        "deleteyouraccountfromenkrip": "Menghapus akun Anda dari ENKRIP",
        "eraseyourmessagehistory": "Menghapus riwayat pesan Anda",
        "deleteyoufromallofyourenkripgroups": "Menghapus Anda dari semua grup ENKRIP Anda",
        "todeleteyouraccountconfirmyourcountryandenteryourphonenumber": "Untuk menghapus akun Anda, konfirmasikan negara Anda dan masukkan nomor telepon Anda.",
        "selectminimumoftwocontacts": "Pilih minimal dua kontak",
        "unblockcontact": "Buka blokir kontak",
        "removeyourprofilephoto": "Hapus foto profil Anda?",
        "anadminforthisgroup?": "admin untuk grup ini?",
        "nomessagedeliveredyet": "Belum ada pesan yang terkirim!",
        "nooneisreadyet": "Belum ada yang siap!",
        "clickittotranslatethemessage": "Klik untuk menerjemahkan pesan",
        "chatarchived": "Obrolan diarsipkan",
        "chatunarchived": "Obrolan diunarsipkan",
        "pinchat": "Sematkan obrolan",
        "unpinchat": "Lepaskan sematan obrolan",
        "mouseoverreceivedmessagechatbubblewhichdisplaystranslatemessageicon,": "Arahkan kursor pada gelembung obrolan pesan yang diterima yang menampilkan ikon Terjemahkan Pesan,",
        "sorrycouldn'tstartthecallbecauseofnetworkerrorpleasecheckyourinternetconnectionandtryagain": "Maaf, tidak bisa memulai panggilan karena kesalahan jaringan. Harap periksa koneksi internet Anda dan coba lagi",
        "deleteyoufromallofyourenkripgroups": "Hapus Anda dari semua grup ENKRIP Anda",
        "todeleteyouraccountconfirmyourcountryandenteryourphonenumber": "Untuk menghapus akun Anda, konfirmasikan negara Anda dan masukkan nomor telepon Anda.",
        "readytojoin": "Siap untuk bergabung?",
        "pleasecheckyourinternetconnection":"Silakan periksa koneksi internet Anda",
        "joinnow":"Bergabung sekarang",
        "yourealreadyinacall": "Anda sudah dalam panggilan",
        "joinvideocall": "Bergabung dalam panggilan video",
        "proceedtodeleteyouraccount?": "Lanjutkan untuk menghapus akun Anda?",
        "deletingyouraccountispermanentyourdatacannotberecoveredifyoureactivateyourenkripaccountinfuture.": "Menghapus akun Anda bersifat permanen. Data Anda tidak dapat dipulihkan jika Anda mengaktifkan kembali akun ENKRIP Anda di masa depan.",
        "areyousureyouwanttodeleteselectedmessage":"Apakah Anda yakin ingin menghapus pesan yang dipilih",
        "doyouwanttoleaveyourcalltojointhisone":"Apakah Anda ingin meninggalkan panggilan Anda untuk bergabung dengan yang ini?",
        "areyousureyouwanttoswitchtovideocall?":"Apakah Anda yakin ingin beralih ke panggilan video?",
        "requestingtoswitchtovideocall":"Meminta untuk beralih ke panggilan video.",
        "allmembersofthegrouparealreadyinthecall":"Semua anggota grup sudah ada dalam panggilan.",
        "alltheusersofyourcontactsalreadyincall":"Semua pengguna kontak Anda sudah menelepon.",
        "cameraandmicrophoneaccessrequiredtocontinuevideocallpleaseenableandtryagain":"Akses kamera dan mikrofon diperlukan untuk melanjutkan panggilan video, harap aktifkan dan coba lagi!",
        "microphoneaccessrequiredtocontinuetheaudiocallpleaseenableandtryagain":"Akses mikrofon diperlukan untuk melanjutkan panggilan audio, harap aktifkan dan coba lagi!",
        "cameraaccessrequiredtosharethevideopleaseenableandtryagain":"Akses kamera diperlukan untuk membagikan video, harap aktifkan dan coba lagi!",
        "seemslikeyouhaveaproblemwithyourmicrophonepleasecheckandtryagain":"Sepertinya Anda mempunyai masalah dengan mikrofon Anda, silakan periksa dan coba lagi!",
        "clearCallLogConfirmMsg":"Apakah Anda ingin menghapus seluruh log panggilan Anda?"
    },
    "toastsucess": {
        "profileupdatedsuccessfully": "Profil berhasil diperbarui",
        "success": "sukses",
        "youwerenolongerfromthegroup": "Anda tidak lagi dari grup.",
        "profileupdatedsuccessfully": "Profil berhasil diperbarui",
        "profilepictureremovedsuccessfully":"Gambar profil berhasil dihapus"


    },
    "toasterror": {
        "cantsharemorethan": "Tidak dapat berbagi lebih dari",
        "mediaitems":"item media.",
        "unsupportedfileformatfilesallowed": "Format file tidak didukung. File yang diizinkan:",
        "pngjpgjpeggifbmp":"png, jpg, jpeg, gif, bmp",
        "hasbeenunblocked": "telah dibuka blokirnya",
        "hasbeenblocked": "telah diblokir",
        "reportsent": "Laporan terkirim",
        "tryagain":"Try Again",
        "pleaseselectanyparticipant":"Silakan pilih Peserta mana saja",
        "imagetypeonlyallowedtoupload":"Jenis gambar hanya diperbolehkan untuk diunggah",
        "unabletorecordaudiowhileoncall":"Tidak dapat merekam audio saat dipanggil."


    },
    "permission": {
        "cameranotauthorizedpleasecheckyourmediapermissionssettings": "Kamera tidak diotorisasi. Harap periksa pengaturan izin media Anda",
        "audiomicrophonenotauthorizedpleasecheckyourmediapermissionssettings": "Mikrofon Audio tidak diotorisasi. Harap periksa pengaturan izin media Anda",
        "permissiondenied": "Izin ditolak",
        "camerapermissiondisabled":"Izin Kamera Dinonaktifkan",
        "startstopcamera":"Kamera Mulai / Hentikan",
        "notallowed":"tidak diizinkan",
        "pleaseenablebrowsernotifications": "Harap aktifkan notifikasi browser"
    },
    "validation": {
        "makesuretouploadajpgjpegpngmp3mp4pgfxlsxlsxdocdocpptpptxrarzipcsvortxtfile": "Pastikan untuk mengunggah file 'JPG, JPEG, PNG, MP3, MP4, PDF, XLS, XLSX, DOC, DOC, PPT, PPTX, RAR, ZIP, CSV, atau TXT'",
        "membersallowedincall": "anggota diizinkan dalam panggilan!",
        "imagetypeonlyallowedtoupload": "Hanya jenis gambar yang diizinkan untuk diunggah",
        "selectminimumofonecontacts": "Pilih setidaknya satu kontak",
        "cantplaceanewcallwhileyourealreadyinacall": "Tidak dapat melakukan panggilan baru saat Anda sudah dalam panggilan."
    },
    
    "errors": {
        "pleaseprovidegroupname": "Harap berikan nama grup",
        "themobilenumberyouentereddoesntmatchyouraccount": "Nomor ponsel yang Anda masukkan tidak cocok dengan akun Anda.",
        "statuscannotbeempty": "Status tidak boleh kosong.",
        "checkyournetworkconnectivity": "Periksa koneksi jaringan Anda.",
        "filesizeshouldnotexceed2mb": "Ukuran file tidak boleh melebihi 2MB.",
        "haveexceededthesizelimitof":"have exceeded the size limit of",
        "therewasanerrorwithaccessingthecamera": "Ada kesalahan saat mengakses kamera.",
        "pleasecheckyourinternetconnection": "Harap periksa koneksi internet Anda.",
        "makesureyourcomputerhasactiveinternetconnection": "Pastikan komputer Anda memiliki koneksi internet aktif.",
        "theserverisnotrespondingpleasetryagainlater": "Server tidak merespons. Silakan coba lagi nanti.",
        "pleaseenteryouremail":"Masukkan email Anda",
        "pleaseentervalidemail":"Silakan masukkan email yang valid",
        "pleaseenteryourusername":"Silakan masukkan nama pengguna Anda",
        "usernameistooshort":"Nama pengguna terlalu pendek",
        "pleaseenteryourstatus":"Silakan masukkan status Anda",
        "somethingwentwrongpleasetryagain":"Ada yang salah. Tolong coba lagi."
    },
    "call": {
        "status":{
            "reconnecting": "menghubungkan kembali",
            "connected": "terhubung",
            "disconnected": "terputus",
            "connecting": "menghubungkan",
            "calling": "panggilan",
            "engaged": "bertunangan",
            "ringing": "dering",
            "busy": "sibuk",
            "hold": "memegang"
        },
        "message": {
            "callonhold": "Panggilan ditunda",
            "userisbusy": "Pengguna sedang sibuk...",
            "busyonanothercall": "Sibuk pada panggilan lain"
        }
    },
    "chatTag":{
        "title": "Tag Obrolan",
        "createTag":{
            "title": "Buat Tanda",
            "tagLine": "Buat tag obrolan untuk berbagai grup obrolan dan beralih cepat di antara grup tersebut.",
            "buttonLabel": "Buat Tag Baru",
            "tagName": "Nama Tag",
            "includePeopleorGroup": "Sertakan Orang atau Grup",
            "tagLine1": "Pilih orang atau grup yang akan muncul di bawah tag ini",
            "addPeopleOrGroup": "Tambahkan Orang atau Grup",
            "addPeople": "Tambahkan orang",
            "discardConfirmMsg": "Tag ini Belum Tersimpan. Apakah Anda yakin untuk kembali?",
            "validation":{
                "tagNameEmpty": "Nama tag wajib diisi",
                "tagNameDuplicate": "Nama tag sudah ada",
                "selectChatsEmpty": "Silakan pilih orang atau grup"
            },
            "maxAllowedErrMsg": "Anda telah membuat tag obrolan dalam jumlah maksimum"
        },
        "deleteTag":{
            "title": "Hapus Tanda",
            "deleteConfirmMsg": "Ini akan menghapus tag obrolan Anda. Obrolan Anda tidak akan dihapus."
        },
        "recommendedTags":{
            "title": "Tag Obrolan yang Direkomendasikan",
            "optionsTagLine": "Pilih orang atau grup yang akan muncul di bawah tag ini"
        },
        "editTag": {
            "title": "Sunting Tandai",
            "tagLine": "Ketuk -Edit' untuk mengubah urutan penghapusan tag"
        }
    },
    "webchatUserHere": {
        "useHereBtn": "GUNAKAN DI SINI",
        "desc": "CHAT terbuka di jendela lain. Klik “Gunakan Di Sini” untuk menggunakan CHAT di jendela ini."
    }
}

