import ThemeService from "MainApp/Services/Theme/ThemeService";
import i18n from "../Languages/i18n";
import { REACT_APP_ENKRIP_APP_ABOUT_US, REACT_APP_PRIVACY_POLICY, REACT_APP_TERMS_AND_CONDITIONS } from "Components/processENV";

export const generateRandomStrOnly = (length=6) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export const pluralize = (count, word, pluralSuffix = 's') => {
  return `${count === 1 ? word : word + pluralSuffix}`;
}

const setThemeAndLangInUrl = (url) => {
    const lang = i18n.language;
    const theme = ThemeService.isDarkTheme() ? 0 : 1;
    
    return `${url}?lang=${lang}&theme=${theme}`;
}

export const getWebsiteAboutPageLink = () => {
  return setThemeAndLangInUrl(REACT_APP_ENKRIP_APP_ABOUT_US);
}

export const getWebsiteTermsPageLink = () => {
  return setThemeAndLangInUrl(REACT_APP_TERMS_AND_CONDITIONS);
}

export const getWebsitePrivacyPageLink = () => {
  return setThemeAndLangInUrl(REACT_APP_PRIVACY_POLICY);
}