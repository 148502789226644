import { NOTIFICATION_TYPE } from "Social/features/InAppNotification/constants";

export const en = {
    "sidebar":{
        "home": "Home",
        "explore": "Explore",
        "saved": "Saved",
        "chat": "Chat",
        "settings": "Settings"
    },
    "common": {
        "button": {
            "follow": "Follow",
            "following": "Following",
            "unfollow": "Unfollow",
            "createPost": "Create Post",
            "upload": "Upload",
            "discard": "Discard",
            "cancel": "Cancel",
            "post": "Post",
            "likes": "Likes",
            "reply": "Reply",
            "viewReplies": "View Replies",
            "hideReplies": "Hide Replies",
            "watchAgain": "Watch again",
            "delete": "Delete",
            "editProfile": "Edit Profile",
            "followBack": "Follow Back",
            "message": "Message",
            "addLink": "Add Link",
            "update": "Update",
            "seeAll": "See All",
            "remove": "Remove",
            "save": "Save",
            "done": "Done",
            "clearAll": "Clear all",
            "forYou": "For you",
            "accounts": "Accounts",
            'tags': "Tags",
            "copyLink": "Copy link",
            "block": "Block",
            "unblock": "Unblock"
        },
        "label": {
            "like": "Like",
            "unlike": "Unlike",
            "comment": "Comment",
            "crop": "Crop",
            "next": "Next",
            "share": "Share",
            "commentOptions": "Comment options",
            "or": 'or',
            "posts": "Posts",
            "tagged": "Tagged",
            "followers": "Followers",
            "images": "Images",
            "videos": "Videos",
            "search": "Search",
            "changePhoto": "Change Photo",
            "uploadPhoto": "Upload Photo",
            "suggestions": "Suggestions",
            'seeAllResult': "See all results",
            'NoRelevantResultFound': "No relevant result found",
            "about": "About",
            "help": "Help",
            "privacy": "Privacy",
            "terms": "Terms",
            "language": "Language",
            "enkripVerified": "Enkrip Verified",
            "ok": "Ok",
            "donwloadUsOn": "Download us on",
            "justNow": "Just now",
            "now": "now",
            "report": "Report",
            "continue": "Continue",
            "reportThisPost": "Report this Post",
            "reportThisUser": "Report this User"
        }
    },
    "post": {
        "createNewPost": "Create new post",
        "tagPeople": "Tag people",
        "newPostPostingMsg": "Posting, just a moment....",
        "newPostPostedSuccessfullyMsg": "Posted successfully",
        "noPostFound": "No Posts Found",
        "discardPost": {
            "discardPost": "Discard post?",
            "warningMessage": "If you leave, your edits won’t be saved."
        },
        "comment":{
            "emptyMsg": "No comments yet",
            "addComment": "Add Comment",
            "replyingTo": "Replying to"
        },
        "likedUsersPopupTitle": "Who has liked this post",
        "createPostCaptionPlaceholder": "What is on your mind?",
        "createPost":{
            "dragAndDropMessage": "Drag and drop, images and videos here",
            "maxFilesAllowedMessage": "You can upload maximum of {{MAX_MEDIA_FILES_ALLOWED}} files",
            "deletePost":{
                "video":{
                    "title":"Discard video",
                    "message":"This will remove the video from your post."
                },
                "image":{
                    "title":"Discard photo",
                    "message":"This will remove the photo from your post."
                },
                "alert":{
                    "alertContext": "Delete this post?"
                }
            }
        },
        "rejectPost":{
            "title": "Uploaded post removed",
            "desc": "Your recent post is removed due to moderation policy violation."
        },
        "cropOptions":{
            "label": "Crop",
            "options":{
                "original":"Original"
            }
        },
        "validation":{
            "message":{
                "emptyFiles": 'No file selected',
                "maximumAllowedFiles": "Maximum of {{MAX_MEDIA_FILES_ALLOWED}} media files is allowed",
                "unsupportedFilesMessage": "Unsupported file format. Files allowed: {{MEDIA_FILES_ALLOWED_EXT}}.",
                "inValidImagesSizeMsg": "{{fileCount}} of the selected images exceed the size limit of {{IMAGE_MAX_ALLOWED_SIZE_MB}} MB each.",
                "inValidVideoDurationMsg": "{{fileCount}} of the selected videos exceed the duration limit of {{VIDEO_MAX_ALLOWED_DURATION_SEC}} seconds.",
                "inValidImageSizeMsg": "The selected image exceeds the size limit of 30 MB.",
                "usernameRequired": "Username is required",
                "usernamePattern": "Should contain only combination of lowercase alphabets, numbers and _. Requires at least 3 alphabets.",
                "displayNameRequired": 'Display Name is required',
                "urlInvalid": 'Please enter a valid URL',
                "usernameUnderscorePattern": "Username cannot start with underscore and should not have consecutive underscore.",
                "requires3Alphabets": "Requires at least 3 alphabets"
            }
        },
        "profile":{
            "noPostsYet": "No Posts Yet",
            "whyNotCreateOne": "Why not create one ?",
            "profilePhoto": "Profile photo",
            "removeCurrentPhoto": "Remove current photo",
            "updateProfileSuccess": "Profile updated successfully",
            "noUserFound": "No user found",
            "noUsersFound": "No users found",
            "editProfileCancelConfirmTitle": "Are you sure you want to save the changes?",
            "dragAndAdjust": "Drag and Adjust",
            "unfollowedSuccessMsg": "You unfollowed {{name}} successfully",
            "block":{
                "info":{
                    "step1": "They won't be able to message you or find your profilre or content on ENKRIP",
                    "step2": "They won't be notified that you blocked them.",
                    "step3": "You can unblock them at any time in Settings"
                },
                "blockedViewProfileMsg": "You cannot view this user profile",
                "blockedViewPostMsg": "You have blocked this user. Unblock to view the posts",
                "noBlockedAccount": "No blocked account"
            }
        },
        "user":{
            "username": "Username",
            "displayName": "Display Name",
            "bio": "Bio",
            "links": "Links",
            "title": "Title",
            "url": "URL",
        },
        "inviteCode":{
            "inviteCode": "Invite Code",
            "invitedByInfo": "You have been invited by",
            "mobileNumberInfo": "Please use the same number for login into the Enkrip application",
            "copyCodeInfo": "Please enter the below invite code in Enkrip application",
            "generateInviteCode": "Generate Invite Code"
        },
        "peopleYouMightKnow": "People you might know",
        "removeSuggestedUserWarningTitle": "Are you sure you want to remove {{USER_NAME}}?",
        "unfollowUserWarningTitle": "Are you sure you want to unfollow {{USER_NAME}}?",
        "whoCanViewThisPost":{
            "whoCanViewThisPost": "Who can view this post?",
            "everyone": "Everyone",
            "closeFriends": "Close friends",
            "postAudience": "Post audience",
            "whoCanSeeYourPost": "Who can see your post?",
            "whoCanSeeYourPostSubTitle": "Your post will appear in Feed, on your profile and in search results.",
            "addPeople": "Add People",
            "peopleWithCount": "{{COUNT}} People",
            "selectedFriends": "Selected friends",
            "emptyCloseFriendsValidationMsg": "Please select at least one friend"
        },
        "hashTag":{
            "lengthValidationMsg": "You can add only {{MAX_ALLOWED_HASH_TAG}} hashtags"
        },
        "notifications":{
            "title":"Notifications",
            "emptyNotificationsMsg":"No notifications found",
            "content": {
                [NOTIFICATION_TYPE.follow]: "{{USER_NAME}} has started following you",
                [NOTIFICATION_TYPE.comment]: "{{USER_NAME}} has commented on your post",
                [NOTIFICATION_TYPE.commentLike]: "{{USER_NAME}} liked your comment",
                [NOTIFICATION_TYPE.commentReply]: "{{USER_NAME}} replied to your comment",
                [NOTIFICATION_TYPE.commentReplyLike]: "{{USER_NAME}} liked your comment",
                [NOTIFICATION_TYPE.postLike]: "{{USER_NAME}} liked your post",
            }
        },
        "sharePost":{
            "title": "Share Post",
            "copyLink": "Copy Link",
            "copyLinkSuccess": "The link copied",
            "invalidSharedPostUrl": "The link may be deleted or restricted. Please, Check the app for the latest updates and fresh content!"
        },
        "shareProfile":{
            "copyLinkSuccess": "The link copied",
        },
        "calculateTimeAgo": {
            "aYearAgo": "a year ago",
            "yearsAgo": "{{years}} years ago",
            "aMonthAgo": "a month ago",
            "monthsAgo": "{{months}} months ago",
            "aWeekAgo": "a week ago",
            "weeksAgo": "{{weeks}} weeks ago",
            "yesterday": "Yesterday",
            "daysAgo": " days ago",
            "anHourAgo": "an hour ago",
            "hoursAgo": "{{hours}} hours ago",
            "aMinuteAgo": "a minute ago",
            "minutesAgo": "{{minutes}} minutes ago"
        },
        "postRemovedByOwnerMsg": "This post has been removed by post owner",
        "postCommentRemovedByOwnerMsg": "This comment has been removed by comment owner",
        "deletePost":{
            "successMsg": "Post deleted successfully"
        },
        "report": {
            "reasonPopup": {
                "title": "Why are you reporting this post?",
                "info": "Your Feedback is Essential! Let US Know Your Concerns to Help Us Maintain a Safe, Supportive, Respectful Community for Everyone."
            },
            "successPopup":{
                "title": "Thanks for letting us know",
                "desc": "When you see something that you don’t like on enkrip, you can report it if it doesn’t follow our <span>Guidelines</span>, or you can remove the person who shared it from your experience.",
                "otherSteps": "Others steps you can take"  
            }
        }
    },
    "settings":{
        "settingsSidebar":{
            "accountSettings": "Account Settings",
            "privacyAndSafety": "Privacy & Safety",
            "pushNotification": "Push Notification",
            "languageSettings": "Language Settings",
            "deleteMyAccount": "Delete my account",
            "logout": "Logout",
            "inviteFriends": {
                "title": "Invite Friends",
                "copyLinkSuccessMsg": "Invite friends link copied successfully"
            },
            "aboutUs": "About Us",
            "description":{
                "accountSettings": "See your account information like your phone number and email address.",
                "privacyAndSafety": "Manage what information you see and share on enkrip.",
                "pushNotification": "Select the kinds of notifications you get about your activities, interests, and recommendations",
                "languageSettings": "Manage which languages are used to personalise your Enkrip experience.",
                "deleteMyAccount": "Find out how you can deactivate your account.",
                "inviteFriends": "Share this invite link to let your international friends easily sign up and start using the app.",
                "aboutUs": "Uncover our journey and mission as we transform with ENKRIP"
            }
        },
        "headers":{
            "myAccount": "My Account",
            "privacyAndSafety": "Privacy and safety",
            "pushNotifications": "Push Notifications",
            "languageSettings": "Language Settings",
            "deleteMyAccount": "Delete my account",
            "headerDescription":{
                "myAccount": "See information about your account, download an archive of your data, or learn about your account deactivation options.",
                "pushNotifications": "Select the kinds of notifications you get about your activities, interests, and recommendations.",
                "languageSettings": "Select your preferred language for headlines, buttons, and other text from enkrip.",
                "deleteMyAccount": "Below added steps for deleting account, we will show this in web",
                "blockedAccounts": "See accounts that you have blocked."
            },
            "blockedAccounts": "Blocked Accounts"
        },
        "common":{
            "button":{
                "update": "Update",
                "deleteAccount": "Delete Account",
                "loadMore": "Load more"
            }
        },
        "accountDetails":{
            "firstName": "First Name",
            "lastName": "Last Name",
            "noLastName": "No LastName",
            "phoneNumber": "Phone Number",
            "emailAddress": "Email Address",
            "gender": "Gender",
            "dateOfBirth": "Date of Birth",
            "validationMsg": {
                "nameMinLength": "A minimum of {{LENGTH}} characters is required",
                "invalidEmail": "Invalid email address",
                "firstnameReq": "First Name is required",
                "lastnameReq": "Last Name is required",
                "emailReq": "Email Address is required",
                "dob": "Date of Birth is required"
            },
            "updateSuccess": "Profile Updated Successfully"
        },
        "interests":{
            "interest": "Interest",
            "header": `These are some of the interests matched to you based on your profile, activity, and the Topics you follow. These
            are used to personalize your experience across X, including the ads you see. You can adjust your interests if
            something doesn’t look right. Any changes you make may take a little while to go into effect.`,
            "interestUpdateSuccess": "Interests updated successfully"
        },
        "pushNotification":{
            "off": "Off",
            "fromProfileIFollow": "From Profile I folow",
            "fromEveryone": "From Everyone",
            "likes": "Likes",
            "comments": "Comments",
            "commentLikes": "Comment Likes",
            "taggedPosts": "Tagged Posts",
            "acceptFollowRequest": "Accept follow request"
        },
        "deleteAccount":{
            "deleteMyAccount": "Delete my account",
            "step1": "Log in to your ENKRIP account on Mobile App",
            "step2": "Go To 'Profile page' by tapping on profile picture (top left of the screen) > Settings (top right of the screen) > Delete My Account",
            "step3": "Select a reason and Click \"Continue\"",
            "step4": "Enter the Mobile number and OTP",
            "step5": "In the Account Delete page, Click \"Continue\"",
            "step6": "Account will be deleted immediately once the \"Delete\" button is clicked",
        },
        "logout":{
            "logout": "Logout"
        },
        "popups":{
            "confirmLogout": "Confirm Logout",
            "wouldYouLikeToLogOut": "Are you sure do you want to logout?",
            "cancel": "Cancel",
            "confirm": "Confirm",
            "confirmLanguageChange": "Confirm Language Change",
            "changeLanguageDescription": "Are you sure you want to change language from English to Indonesian?",
            "yes": "Yes",
            "no": "No"
        }
    },
    "login": {
        "title": "Login to ENKRIP by QR code",
        "loginSteps": {
            "step1": "Open ENKRIP on your phone",
            "step2": "Go to your Profile &gt; Settings &gt; Linked Device",
            "step3": "Point your phone at this screen to capture the QR Code to Login"
        }
    }
}