import { REACT_APP_WEBAPP_MEDIA_URL } from "Components/processENV";
import ApiService from "MainApp/Services/Api/ApiService";
import { sliceActionHandler } from "MainApp/Services/Api/helpers";
import { getFileExtFromFilenameOrFile, getFiletypeFromFileInst, isVideoFileType } from "MainApp/features/Media/helpers";
import { createPostAction, failedCreatePost } from "./createPostSlice";
import { isVideoOrStreamingFile } from "./helpers";
import { addHashTagsInPayload } from "MainApp/features/HashTagPopup/helpers";

export const createPost = async (userInputData, thunkAPI) => {
    const {fileTokensData, inputPayload = {}, uploadedFiles} = userInputData;

    // const createPostFilesInfo = fileTokensData?.map((data, index) => {
    //     const fileExt = getFileExtFromFilenameOrFile(data.fileToken);
    //     const payload = {
    //         fileType: isVideoOrStreamingFile(fileExt) ? 1 : 0,
    //         ...data
    //         // fileDuration: 0,
    //         // file: fileToken,
    //         // filePreview: '',
    //         // currentVideoProgress: 0
    //     }

    //     // if(fileTokensData?.thumbFileTokens?.[index]){
    //     //     payload['fileThumbnail'] = fileTokensData?.thumbFileTokens[index]; 
    //     // }

    //     // if(fileTokensData?.previewTokens?.[index]){
    //     //     payload.filePreview = fileTokensData?.previewTokens[index]; 
    //     // }

    //     return payload;
    // });

    // Fileter If there is undefined data
    const postId = fileTokensData?.postId;
    const files = fileTokensData?.files || [];
    const createPostFilesInfoToSend = files.filter((payload) => payload?.fileToken);
    if(Array.isArray(createPostFilesInfoToSend) && createPostFilesInfoToSend.length === 0 || !postId){
        thunkAPI.dispatch(failedCreatePost());
        return;
    }

    let inputPayloadToSend = {
        postId,
        contentType: 'MEDIA',
        files: createPostFilesInfoToSend,
        ...inputPayload
    }
    inputPayloadToSend = addHashTagsInPayload(inputPayloadToSend, inputPayloadToSend.postContent);

    const apiService = new ApiService();

    return await sliceActionHandler(() => apiService.post('/post/', inputPayloadToSend), {userInputData, thunkAPI});
  }

export const createPostMediaUpload = async (userInputData, thunkAPI) => {
    const {selectedFiles, inputPayload = {}} = userInputData;

    const apiService = new ApiService(REACT_APP_WEBAPP_MEDIA_URL, {
        headers: {
            'Content-Type': 'multipart/form-data', // Set content type for file uploads
        }
    });

    const formData = new FormData();
    const selectedFilesToUpload = selectedFiles.map((file) => {
        const newFile = {...file};
        if(newFile.updatedFile){
            formData.append('file', newFile.updatedFile, newFile.filename);
            if(newFile?.thumbnail?.file && isVideoFileType(getFiletypeFromFileInst(newFile.updatedFile))){
                formData.append('thumb', newFile.thumbnail.file, newFile?.thumbnail?.file?.name);
            }
            return newFile;
        }
        return;
    });
    userInputData['uploadedFiles'] = selectedFilesToUpload;
    const response = await sliceActionHandler(() => apiService.post('/uploadMedia?type=post', formData), {
        userInputData,
        thunkAPI,
        successCallback: (response) => {
            thunkAPI.dispatch(createPostAction({fileTokensData: response.data, inputPayload, uploadedFiles: selectedFilesToUpload}))
        },
        errorCallback: () => {
            thunkAPI.dispatch(failedCreatePost());
        }
    });
    return response;
}