import ApiService from "MainApp/Services/Api/ApiService";
import { USERS_LIST_PER_PAGE } from "Social/features/Profile/constants";
import { sliceActionHandler } from "MainApp/Services/Api/helpers";
import { log } from "util";
import { REACT_APP_WEBAPP_LOGIN_API_URL } from "Components/processENV";

export const getPushNotificationByUserId = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();

    const response = await sliceActionHandler(() => apiService.get('users/profile/notification-settings'),{
        userInputData,
        thunkAPI
    });
    return response;
}

export const updatePushNotificationByUserId = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();

    const response = await sliceActionHandler(() => apiService.patch('users/profile/notification-settings',{...userInputData}),{
        userInputData,
        thunkAPI
    });
    return response;
}

export const getInterestData = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();

    const response = await sliceActionHandler(() => apiService.get(`users/interests?page=${userInputData.page}&size=15`),{
        userInputData,
        thunkAPI
    });
    return response;
}

export const getUserInterestData = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    const response = await sliceActionHandler(() => apiService.get('users/interests/user'),{
        userInputData,
        thunkAPI
    });
    return response;
}

export const updateUserInterestData = async (userInputData, thunkAPI) => {

    const apiService = new ApiService();

    const response = await sliceActionHandler(() => apiService.post('users/interests',{interestIds: userInputData.interestIds, notInterestIds: userInputData.notInterestIds}),{
        userInputData,
        thunkAPI
    });
    return response;
}

export const getMyAccoutProfileData = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();

    const response = await sliceActionHandler(() => apiService.get(`users/profile?userId=${userInputData.userId}&detailedInfo=true`),{userInputData},{
        userInputData,
        thunkAPI
    });
    return response;
}

export const updateMyAccoutProfileData = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();

    const response = await sliceActionHandler(() => apiService.put(`users/profile`,{...userInputData}),{userInputData},{
        userInputData,
        thunkAPI
    });
    return response;
}