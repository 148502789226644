import { getAuthUserDetails } from "MainApp/Services/Login/helpers";
import { getMapDataById, updateMapDataById } from "MainApp/features/Helpers/Map";
import { cloneDeep } from "lodash";
import { getUtcDateTimeString } from "../DateTime/helpers";

export const getNewCommentObj = ({comments, commentId}) => {
    const authUser = getAuthUserDetails();
    return {
        "comments": comments,
        "noOfLikes": null,
        "noOfReplies": null,
        "userId": authUser?.userId,
        "firstName": authUser?.firstName,
        "lastName": authUser?.lastName,
        "displayName": authUser?.displayName,
        "username": authUser?.username,
        "image": authUser?.image,
        "commentId": commentId,
        "createdAt": getUtcDateTimeString(),
        "ownUserLikeStatus": 0
    }
}

export const updatePostCommentLike = (mapData, likeInfo) => {
    if(!mapData || !mapData instanceof Map || !likeInfo) return mapData;
    const comment = getMapDataById(mapData, likeInfo.commentId);
    if(comment){
        const dataToUpdate = cloneDeep(comment);
        const noOfLikes = dataToUpdate.noOfLikes;
        dataToUpdate.ownUserLikeStatus = likeInfo.like;
        dataToUpdate.noOfLikes = likeInfo.like ? noOfLikes + 1 : noOfLikes - 1;
        return updateMapDataById(mapData, likeInfo.commentId, dataToUpdate);
    }
    return mapData;
}