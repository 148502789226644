export const id = {
    "internet": {
        'noInternet': 'Tidak ada internet',
        'popupOfflineMsg': 'Silakan periksa koneksi internet Anda dan coba lagi'
    },
    "common": {
        "label": {
            "tryAgain": "Coba lagi"
        }
    },
    "invalidUrl":{
        "title": "Maaf, Halaman ini tidak tersedia.",
        "description": "Tautan yang Anda ikuti mungkin rusak, Atau halaman tersebut mungkin telah dihapus. ",
        "linkLabel": "Kembali ke ENKRIP",
        "invalidUrl": "URL tidak valid"
    }
}