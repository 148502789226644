export const AudioOffSvg = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.611" height="20.463" viewBox="0 0 14.611 20.463">
            <g id="mic-off" transform="translate(0.75 0.072)">
                <path 
                    id="Union_43" 
                    data-name="Union 43" 
                    d="M4103.651-4386.125a.513.513,0,0,1-.515-.51.512.512,0,0,1,.515-.509h3.611v-2.576A6.68,6.68,0,0,1,4103.8-4391l.6-.827a5.652,5.652,0,0,0,3.376,1.109,5.647,5.647,0,0,0,5.672-5.608.513.513,0,0,1,.515-.51.514.514,0,0,1,.517.51,6.563,6.563,0,0,1-1.8,4.516,6.7,6.7,0,0,1-4.391,2.086v2.576h3.61a.512.512,0,0,1,.515.509.513.513,0,0,1-.515.51Zm-3.472-2.254a.749.749,0,0,1-.165-1.047l2.265-3.115a6.589,6.589,0,0,1-1.207-3.78.514.514,0,0,1,.516-.51.513.513,0,0,1,.515.51,5.506,5.506,0,0,0,.819,2.9l1.2-1.647a3.787,3.787,0,0,1-.211-1.249v-6.372a3.85,3.85,0,0,1,3.869-3.823,3.884,3.884,0,0,1,3.317,1.854l1.176-1.618a.749.749,0,0,1,1.047-.165.75.75,0,0,1,.166,1.047l-12.257,16.852a.745.745,0,0,1-.606.31A.751.751,0,0,1,4100.18-4388.379Zm4.761-14.314v6.372c0,.04,0,.079,0,.118l5.471-7.523a2.841,2.841,0,0,0-2.637-1.77A2.823,2.823,0,0,0,4104.941-4402.693Zm.509,9.428.6-.827a2.841,2.841,0,0,0,1.728.575,2.823,2.823,0,0,0,2.836-2.8v-4.053l1.031-1.42v5.473a3.85,3.85,0,0,1-3.867,3.823A3.862,3.862,0,0,1,4105.45-4393.266Z" 
                    transform="translate(-4100.621 4406.517)" 
                    fill={props.pathFill || "#fff"}
                />
                <path 
                    id="Path_10161" 
                    data-name="Path 10161" 
                    d="M0,16.853,12.257,0" 
                    transform="translate(0 0.678)" 
                    fill={props.pathFill || "#fff"}
                />
            </g>
        </svg>
    )
}