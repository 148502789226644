import ApiService from "MainApp/Services/Api/ApiService";
import { sliceActionHandler } from "MainApp/Services/Api/helpers";
import { REACT_APP_WEBAPP_DEVICE_TYPE } from "../App/constants";
import { REACT_APP_WEBAPP_LOGIN_API_URL } from "Components/processENV";

export const logout = async (userInputData, thunkAPI) => {
    const apiService = new ApiService(REACT_APP_WEBAPP_LOGIN_API_URL);
    
    const response = await sliceActionHandler(() => apiService.post(`/logout?deviceType=${REACT_APP_WEBAPP_DEVICE_TYPE}`), {
        userInputData,
        thunkAPI
    });
    return response;
}