import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchUsersReportReasons, getReportedUserDetails, reportUser } from "./actions";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import toastr from "toastr";

export const fetchUsersReportReasonsAction = createAsyncThunk(
    'fetch/report/user/reasons',
    async (userInputData, options) => await fetchUsersReportReasons(userInputData, options)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: []
}

const usersReportReasonsSlice = createSlice({
    name: 'usersReportReasons',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUsersReportReasonsAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(fetchUsersReportReasonsAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.data = payload?.data || [];            
        })
        .addCase(fetchUsersReportReasonsAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

const usersReportReasonsReducer = usersReportReasonsSlice.reducer;
export default usersReportReasonsReducer;


export const reportUserAction = createAsyncThunk(
    'report/user', 
    async (userInputData, thunkAPI) => await reportUser(userInputData, thunkAPI)
)

const reportUserInitState = {
    loading: ACTION_IDLE,
    error: null
}

const reportUserSlice = createSlice({
    name: 'reportUser',
    initialState: reportUserInitState,
    reducers: {
        resetReportUser() {
            return reportUserInitState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(reportUserAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(reportUserAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
        })
        .addCase(reportUserAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export const { resetReportUser } = reportUserSlice.actions;
export const reportUserReducer = reportUserSlice.reducer;

export const getReportedUserDetailsAction = createAsyncThunk(
    'get/reported/user/details', 
    async (userInputData, thunkAPI) => await getReportedUserDetails(userInputData, thunkAPI)
)

const getReportedUserInitState = {
    loading: ACTION_IDLE,
    error: null,
    data: null
};

const getReportedUserDetailsSlice = createSlice({
    name: 'getReportedUserDetails',
    initialState: getReportedUserInitState,
    reducers: {
        resetGetReportedUserDetails() {
            return getReportedUserInitState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getReportedUserDetailsAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(getReportedUserDetailsAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.data = payload?.data;
            if(state.data?.isReported){
                toastr.error(payload.message);
            }
        })
        .addCase(getReportedUserDetailsAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export const { resetGetReportedUserDetails } = getReportedUserDetailsSlice.actions;
export const getReportedUserDetailsReducer = getReportedUserDetailsSlice.reducer;