import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { USERS_LIST_PER_PAGE } from "Social/features/Profile/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearMapData, formatDataArrayAsMap } from "MainApp/features/Helpers/Map";
import { getInterestData, getUserInterestData, updateUserInterestData } from "./actions";
import { options } from "toastr";


const GET_INTERESTS_DATA = 'users/interests?page=3&size=15';
const GET_USER_INTEREST_DATA = 'users/interests/user';
const UPDATE_USER_INPUT_DATA = 'users/interests';

export const getInterestDataAction = createAsyncThunk(
    GET_INTERESTS_DATA,
    async (userInputData, options) => await getInterestData(userInputData, options)
)

export const getUserInterestDataAction = createAsyncThunk(
    GET_USER_INTEREST_DATA,
    async(userInputData, options) => await getUserInterestData(userInputData, options)
)

export const updateUserInterestDataAction = createAsyncThunk(
    UPDATE_USER_INPUT_DATA,
    async(userInputData, options) => await updateUserInterestData(userInputData, options)
)

const interestDataInitState = {
    loading: ACTION_FAILED,
    error: null,
    data: {},
    interestList: [],
    userInterestList:[],
}

const interestDataSlice = createSlice({
    name: 'interestData',
    initialState: interestDataInitState,
    reducers:{
        resetInterestData(state){
            return interestDataInitState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getInterestDataAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(getInterestDataAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            if(payload?.userInputData?.page === 1){
                state.interestList = [];
            }
            state.interestList = payload.data;
            state.userInterestList = state.userInterestList;
        })
        .addCase(getInterestDataAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
        .addCase(getUserInterestDataAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(getUserInterestDataAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.userInterestList = payload.data;
        })
        .addCase(getUserInterestDataAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
        .addCase(updateUserInterestDataAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
           
        })
        .addCase(updateUserInterestDataAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
        })
        .addCase(updateUserInterestDataAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
        
    }

})

export const { resetInterestData } = interestDataSlice.actions;

export const settingInterestDataReducer = interestDataSlice.reducer;