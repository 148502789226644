import ApiService from "MainApp/Services/Api/ApiService";
import { HASH_TAG_POSTS_PER_PAGE } from "./constants";
import { sliceActionHandler } from "MainApp/Services/Api/helpers";

export const fetchPostsByHashTag = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    const response = await sliceActionHandler(() => apiService.get(`post/globalSearch`, { page:userInputData.pageNumber, size:HASH_TAG_POSTS_PER_PAGE, type:"hashTagPosts", keyword: userInputData.hashTag }), {
        userInputData,
        thunkAPI
    });
    return response;
}

export const followHashTag = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    const response = await sliceActionHandler(() => apiService.post(`users/hashTags/follow`, { hashTag: userInputData.hashTag, follow: userInputData.follow }), {
        userInputData,
        thunkAPI
    });
    return response;
}
