import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { blockUserByUserId, fetchFollowersByUserId, fetchFollowingByUserId, fetchPostsByUserId, getBlockedUsers, unblockUserByUserId } from "./actions";
import { USERS_LIST_PER_PAGE, USER_POSTS_PER_PAGE } from "./constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearMapData, deleteMapDataById, formatDataArrayAsMap, isMapDataHasRecord } from "MainApp/features/Helpers/Map";
import { fetchGuestUserPostsByUserId } from "../Shared/actions";
import { LoginService } from "MainApp/Services/Login/LoginService";
import { blockUserBySDK, unblockUserBySDK, unblockUserHelper } from "./helpers";
import toastr from "toastr";

const FETCH_POSTS_BY_USERID = 'fetch/user/posts';

export const fetchPostsByUserIdThunk = createAsyncThunk(
    FETCH_POSTS_BY_USERID,
    async (userInputData, options) => LoginService.isUserLoggedIn() ? await fetchPostsByUserId(userInputData, options) : await fetchGuestUserPostsByUserId(userInputData, options)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: new Map(),
    count: USER_POSTS_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1
}

const userPostsSlice = createSlice({
    name: 'userPosts',
    initialState,
    reducers: {
        resetUserPosts(){
            return initialState;
        },
        deleteUserPostsByPostId(state, { payload }){   
            state.data = deleteMapDataById(state.data, payload?.postId);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPostsByUserIdThunk.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(fetchPostsByUserIdThunk.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            //Clear the old data If call is initiated for page 1
            if(payload.userInputData.pageNumber === 1){
                state.data = clearMapData(state.data);
            }
            const { userInputData, count, data, totalRecords } = payload;
            if(Array.isArray(data)){
                state.data = formatDataArrayAsMap('posts.id', data, state.data);
                state.count = count || state.count;
                state.totalRecords = totalRecords || state.totalRecords;
                state.pageNumber = userInputData.pageNumber;
            }
        })
        .addCase(fetchPostsByUserIdThunk.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.userInputData = payload.userInputData;
        })
    }
});

export const { resetUserPosts, deleteUserPostsByPostId } = userPostsSlice.actions;

export default userPostsSlice.reducer;


const FETCH_FOLLOWERS_BY_USERID = 'fetch/user/followers';

export const fetchFollowersByUserIdAction = createAsyncThunk(
    FETCH_FOLLOWERS_BY_USERID,
    async (userInputData, options) => await fetchFollowersByUserId(userInputData, options)
)

const followersInitState = {
    loading: ACTION_IDLE,
    error: null,
    data: new Map(),
    count: USERS_LIST_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1
}

const userFollowersSlice = createSlice({
    name: 'userFollowers',
    initialState: followersInitState,
    reducers: {
        resetUserFollowers(state){
            return followersInitState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFollowersByUserIdAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(fetchFollowersByUserIdAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            //Clear the old data If call is initiated for page 1
            if(payload.userInputData.pageNumber === 1){
                state.data = clearMapData(state.data);
            }
            const { userInputData, count, data, totalRecords } = payload;
            if(Array.isArray(data)){
                state.data = formatDataArrayAsMap('userId', data, state.data);
                state.count = count || state.count;
                state.totalRecords = totalRecords || state.totalRecords;
                state.pageNumber = userInputData.pageNumber;
            }
        })
        .addCase(fetchFollowersByUserIdAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.userInputData = payload.userInputData;
        })
    }
});

export const { resetUserFollowers } = userFollowersSlice.actions;

export const userFollowersReducer =  userFollowersSlice.reducer;


const FETCH_FOLLOWING_BY_USERID = 'fetch/user/following';

export const fetchFollowingByUserIdAction = createAsyncThunk(
    FETCH_FOLLOWING_BY_USERID,
    async (userInputData, options) => await fetchFollowingByUserId(userInputData, options)
)

const followingInitState = {
    loading: ACTION_IDLE,
    error: null,
    data: new Map(),
    count: USERS_LIST_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1
}

const userFollowingSlice = createSlice({
    name: 'userFollowing',
    initialState: followingInitState,
    reducers: {
        resetUserFollowing(state){
            return followingInitState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFollowingByUserIdAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(fetchFollowingByUserIdAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            //Clear the old data If call is initiated for page 1
            if(payload.userInputData.pageNumber === 1){
                state.data = clearMapData(state.data);
            }
            const { userInputData, count, data, totalRecords } = payload;
            if(Array.isArray(data)){
                state.data = formatDataArrayAsMap('userId', data, state.data);
                state.count = count || state.count;
                state.totalRecords = totalRecords || state.totalRecords;
                state.pageNumber = userInputData.pageNumber;
            }
        })
        .addCase(fetchFollowingByUserIdAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.userInputData = payload.userInputData;
        })
    }
});

export const { resetUserFollowing } = userFollowingSlice.actions;

export const userFollowingReducer =  userFollowingSlice.reducer;

// Block User
export const blockUserByUserIdAction = createAsyncThunk(
    'block/user',
    async (userInputData, options) => await blockUserByUserId(userInputData, options)
)

const blockUserInitState = {
    loading: ACTION_IDLE,
    error: null,
    data: null
}


const blockUserByUserIdSlice = createSlice({
    name: 'blockUser',
    initialState: blockUserInitState,
    reducers: {
        resetBlockUserByUserId() {
            return blockUserInitState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(blockUserByUserIdAction.pending, (state, action) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(blockUserByUserIdAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            if(payload?.message){
                toastr.success(payload.message);
            }
        })
        .addCase(blockUserByUserIdAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.userInputData = payload.userInputData;
            // If the block API fails, then revert SDK block call
            if(payload?.userInputData?.userId && payload?.error?.status != 400){
                unblockUserBySDK(payload?.userInputData?.userId);
            }
        })
    }
});

export const { resetBlockUserByUserId } = blockUserByUserIdSlice.actions;
export const blockUserByUserIdReducer = blockUserByUserIdSlice.reducer;


// Unblock User
export const unblockUserByUserIdAction = createAsyncThunk(
    'unblock/user',
    async (userInputData, options) => await unblockUserByUserId(userInputData, options)
)

const unblockUserInitState = {
    loading: ACTION_IDLE,
    error: null,
    data: null
}

const unblockUserByUserIdSlice = createSlice({
    name: 'unblockUser',
    initialState: unblockUserInitState,
    reducers: {
        resetunblockUserByUserId() {
            return unblockUserInitState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(unblockUserByUserIdAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(unblockUserByUserIdAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            if(payload?.message){
                toastr.success(payload.message);
            }
        })
        .addCase(unblockUserByUserIdAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.userInputData = payload.userInputData;
            // If the unblock API fails, then revert SDK unblock call
            if(payload?.userInputData?.userId && payload?.error?.status != 400){
                blockUserBySDK(payload?.userInputData?.userId);
            }
        })
    }
});

export const { resetunblockUserByUserId } = unblockUserByUserIdSlice.actions;
export const unblockUserByUserIdReducer = unblockUserByUserIdSlice.reducer;

// Get blocked users list
export const getBlockedUsersAction = createAsyncThunk(
    'get/blocked/users', 
    async (userInputData, thunkAPI) => await getBlockedUsers(userInputData, thunkAPI)
)

const getBlockedUsersInitState = {
    loading: ACTION_IDLE,
    error: null,
    data: new Map(),
    count: USERS_LIST_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1
};

const getBlockedUsersSlice = createSlice({
    name: 'getBlockedUsers',
    initialState: getBlockedUsersInitState,
    reducers: {
        resetGetBlockedUsersDetails() {
            return getBlockedUsersInitState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBlockedUsersAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(getBlockedUsersAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            //Clear the old data If call is initiated for page 1
            if(payload?.userInputData?.pageNumber === 1){
                state.data = clearMapData(state.data);
            }
            
            const { userInputData, count, data, totalRecords } = payload;
            if(Array.isArray(data)){
                state.data = formatDataArrayAsMap('userId', data, state.data);
                state.count = count || state.count;
                state.totalRecords = totalRecords || state.totalRecords;
                state.pageNumber = userInputData.pageNumber;
            }
        })
        .addCase(getBlockedUsersAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
        .addMatcher(
            (action) => action.type === "unblock/user/fulfilled",
            (state, action) => {
                const { payload } = action;
                if(isMapDataHasRecord(state.data, payload?.userInputData?.userId)){
                    state.data = deleteMapDataById(state.data, payload?.userInputData?.userId);
                }
                return state;
            }
        )
    }
});

export const { resetGetBlockedUsersDetails } = getBlockedUsersSlice.actions;
export const getBlockedUsersReducer = getBlockedUsersSlice.reducer;