export const formatDataArrayAsMap = (keyProperty, dataArr, dataMap = new Map()) => {
    if(!keyProperty || !Array.isArray(dataArr)) return dataMap;
    dataArr.map(user => {
        const value = getNestedPropertyValue(user, keyProperty);
        if(!value) return;
        dataMap.set(value, user);
    });
    return dataMap;
}

export const formatDataMapAsArray = (data) => {
    if(data instanceof Map){
        return data?.size ? [...data.values()] : [];
    }
    return [];
}

export const isMapDataHasRecord = (mapData, dataId) => {
    return mapData && mapData instanceof Map && mapData?.has(dataId);
}

export const getMapDataById = (mapData, dataId) => {
    if(!mapData || !dataId) return;
    if(isMapDataHasRecord(mapData, dataId)){
        return mapData.get(dataId);
    }
    return;
}

export const updateMapDataById = (mapData, dataId, dataToUpdate) => {
    if(!mapData || !dataId ||  !dataToUpdate) return mapData;
    if(isMapDataHasRecord(mapData, dataId)){
        mapData.set(dataId, dataToUpdate);
    }
    return mapData;
}

export const deleteMapDataById = (mapData, dataId) => {
    if(!mapData || !dataId) return mapData;
    if(isMapDataHasRecord(mapData, dataId)){
        mapData.delete(dataId);
    }
    return mapData;
}

export const clearMapData = (mapData) => {
    if(!mapData || !mapData instanceof Map) return mapData;
    mapData.clear();
    return mapData;
}

export const setDataInMapByIndex = (map, key, value, index = 0) => {
    if (index < 0 || index > map.size) {
      throw new Error('Invalid index');
    }
  
    const newMap = new Map();
    let counter = 0;
  
    for (const [existingKey, existingValue] of map) {
      if (counter === index) {
        newMap.set(key, value); // Insert at the desired index
      }
      newMap.set(existingKey, existingValue);
      counter++;
    }
  
    if (counter === index) { // Handle insertion at the end (after iterating)
      newMap.set(key, value);
    }
    map.clear(); // clear the old map data
  
    return newMap;
}

export const getNestedPropertyValue = (obj, path) => {
    const parts = path.split('.');
    let current = obj;
    for (const part of parts) {
      if (current && typeof current === 'object' && part in current) {
        current = current[part];
      } else {
        return undefined; // Property not found
      }
    }
    return current;
}