import {
  ROSTER_DATA,
  ROSTER_DATA_UPDATE,
  ROSTER_DATA_ADD,
  ROSTER_DATA_UPSERT,
  FETCHING_USER_LIST,
  ROSTER_DATA_CONTACTS
} from '../Actions/Constants';
import {
  compare
} from '../Helpers/Utility';

const updateRoster = (rosterData = [], newData = {}) => {
  return rosterData.map(profile => {
    if (newData.userId === profile.userId) {
      return {
        ...profile,
        ...newData
      }
    }
    return profile
  })
}

const upsertRoster = (rosterData = [], newData = [], pageNumber = 1) => {

  if (pageNumber === 1) {
    for (var roster in rosterData) {
      if (rosterData[roster].isFriend) {
        rosterData[roster].isFriend = false;
      }
    }
  }

  for (var user in newData) {
    let key = userExists(rosterData, newData[user].userId)
    if (key > -1) {
      rosterData[key] = newData[user];
    } else {
      rosterData.push(newData[user]);
    }
  }
 
  let contacts = rosterData.sort(compare);
  return [...contacts];
}

const userExists = (rosterData, userId) => {
  for (var user in rosterData) {
    if (rosterData[user].userId === userId) {
      return user;
    }
  }
  return -1;
}

const getDisplayNamefromRoster = (state,roster = []) => {
  let { rosterNames } = state;
  rosterNames = rosterNames instanceof Map ? rosterNames : new Map();
  const getAllNames = roster.map(profile => {
    let profileToUpdate = {...profile};
    const jid = profileToUpdate.userId;
    if(rosterNames.has(jid)){
      const existingRoster = rosterNames.get(jid);
      profileToUpdate = {...existingRoster, ...profileToUpdate};
    }
    return [jid, profileToUpdate]
  })
  const newRosterNames = new Map(getAllNames);
  return new Map([...rosterNames, ...newRosterNames]);
}

const addNewRoster = (state, newData = {}) => {
  let {
    data = [], rosterNames
  } = state;
  if (newData.userId && rosterNames.has && !rosterNames.has(newData.userId)) {
    data = [...data, newData];
    rosterNames = getDisplayNamefromRoster(state,data);
  }
  return {
    rosterNames,
    data
  }
}

const addRosterData = (state, rosters = []) => {
  const rosterNamesToUpdate = state.rosterNames instanceof Map ? state.rosterNames : new Map();
  let dataToUpdate = state.data;
  rosters.map((roster) => {
      if(roster?.userId && rosterNamesToUpdate.has && !rosterNamesToUpdate.has(roster.userId)){
        dataToUpdate = [...dataToUpdate, roster];
        rosterNamesToUpdate.set(roster.userId, roster);
      }
  });

  return {
    rosterNames: rosterNamesToUpdate,
    data: dataToUpdate
  }
}

const ROSTER_DEFAULT_STATE = {
  isFetchingUserList: false,
  rosterNames: {},
  data: [],
  contacts: []
}

export default function RosterReducer(state = ROSTER_DEFAULT_STATE, action = {}) {
  const {
    payload: {
      id,
      data,
      contacts = []
    } = {}
  } = action
  switch (action.type) {
    case ROSTER_DATA:
      const newrosters = addRosterData(state, data);
      return {
        ...state,
        id,
        ...action.payload,
          contacts,
          ...newrosters
      }
      case ROSTER_DATA_UPSERT:
        const pageNumber = action.payload.pageNumber;
        const roster = upsertRoster(state.data, data, pageNumber);
        return {
          ...state,
          ...action.payload,
            data: roster,
            rosterNames: getDisplayNamefromRoster(state,roster),
            isFetchingUserList: false,
            contacts
        }
        case ROSTER_DATA_UPDATE:
          const rosterDetails = updateRoster(state.data, data)
          return {
            ...state,
            id,
            data: rosterDetails,
              rosterNames: getDisplayNamefromRoster(state,rosterDetails)
          }
        case ROSTER_DATA_ADD:
          const rosters = addNewRoster(state, data)
          return {
            ...state,
            id,
            ...rosters
          }
        case FETCHING_USER_LIST:
            return {
              ...state,
              id,
              isFetchingUserList: data
            }
        case ROSTER_DATA_CONTACTS:
          return {
            ...state,
            id,
            contacts,
            isFetchingUserList: false
          }

        default:
          return state;
  }
}