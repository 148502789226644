import { toggleProfileFollowReducer } from "Social/features/Follow/reducer";
import popupReducer from "Social/features/Popup/reducer";
import { postsListApiDatetimeReducer, fetchPostsReducer, deletePostReducer, fetchPostByPostIdReducer, videoPlayerOptionReducer } from "Social/features/Post/reducer";
import { fetchPostCommentLikedUsersReducer, fetchPostCommentsReducer } from "Social/features/PostComments/Comments/reducer";
import postCommentRepliesReducer from "Social/features/PostComments/Replies/repliesSlice";
import { fetchPostLikedUsersReducer, togglePostLikeReducer } from "Social/features/PostLike/reducer";
import postCommentReplyLikedUsersReducer from "Social/features/PostComments/Replies/replyLikedUsersSlice";
import postCommentsReducer from "Social/features/PostComments/Comments/commentsSlice";
import userProfileReducer, { localUserProfileSliceReducer, profileMessageUserSliceReducer } from "Social/features/Profile/profileSlice";
import { createPostReducer, createPostMediaUploadReducer } from "Social/features/CreatePost/createPostSlice";
import userPostsReducer, { blockUserByUserIdReducer, getBlockedUsersReducer, unblockUserByUserIdReducer, userFollowersReducer, userFollowingReducer } from 'Social/features/Profile/userSlice';
import { settingPushNotificationReducer} from "Social/features/Settings/Components/pushNotifySlice";
import { profileImageUploadReducer, updateProfileReducer } from "Social/features/Profile/updateProfileSlice";
import usernameAvailableReducer from "Social/features/Profile/usernameAvailableSlice";
import suggestedUsersReducer from "Social/features/SuggestedUsers/suggestedUsersSlice";
import generateInviteCodeReducer from "Social/features/InviteCode/inviteCodeSlice";
import hashTagPostsReducer from "Social/features/HashTagPosts/hashTagPostsSlice";
import closeFriendsReducer from "Social/features/CloseFriends/closeFriendsSlice";
import { settingInterestDataReducer } from "Social/features/Settings/Components/privacyInterestSlice";
import { settingMyAccountDataReducer } from "Social/features/Settings/Components/myAccountSlice";
import inAppNotificationsReducer from "Social/features/InAppNotification/inAppNotifySlice";
import globalSearchListReducer from "Social/features/Global/globalSearchSlice";
import globalAccuntsSearchReducer from "Social/features/Global/globalAccountsSearchSlice";
import { globalSearchInputStateReducer } from "Social/features/Global/reducer";
import updatePostViewReducer from "Social/features/PostView/updatePostViewSlice";
import { fetchSharedPostByPostIdReducer } from "Social/features/Shared/Post/sharedPostSlice";
import usersReportReasonsReducer, { getReportedUserDetailsReducer, reportUserReducer } from "Social/features/Profile/reportSlice";
import { getReportedPostDetailsReducer, reportPostReducer } from "Social/features/Post/slice/reportSlice";


// import 

export default {
    posts: fetchPostsReducer,
    socialPopup: popupReducer,
    createPostMediaUpload: createPostMediaUploadReducer,
    createPost: createPostReducer,
    postsListApiDatetime: postsListApiDatetimeReducer,
    togglePostLike: togglePostLikeReducer,
    postLikedUsers: fetchPostLikedUsersReducer,
    toggleProfileFollow: toggleProfileFollowReducer,
    postComments: postCommentsReducer,
    postCommentLikedUsers: fetchPostCommentLikedUsersReducer,
    postCommentReplies: postCommentRepliesReducer,
    postCommentReplyLikedUsers: postCommentReplyLikedUsersReducer,
    postUserProfile: userProfileReducer,
    localUserProfile: localUserProfileSliceReducer,
    userPosts: userPostsReducer,
    userFollowers: userFollowersReducer,
    userFollowing: userFollowingReducer,
    profileMessageUser : profileMessageUserSliceReducer,
    profileImageUpload: profileImageUploadReducer,
    updateProfile: updateProfileReducer,
    usernameAvailable: usernameAvailableReducer,
    settingPushNotification: settingPushNotificationReducer,
    suggestedUsers: suggestedUsersReducer,
    inviteCode: generateInviteCodeReducer,
    hashTagPosts: hashTagPostsReducer,
    closeFriends: closeFriendsReducer,
    settingPrivacyInterest: settingInterestDataReducer,
    settingMyAccount: settingMyAccountDataReducer,
    inAppNotifications: inAppNotificationsReducer,
    post: fetchPostByPostIdReducer,
    deletePost : deletePostReducer,
    videoPlayerOption: videoPlayerOptionReducer,
    globalSearchLists:globalSearchListReducer,
    globalAccountsSearchLists:globalAccuntsSearchReducer,
    globalSearchInputState:globalSearchInputStateReducer,
    updatePostView: updatePostViewReducer,
    sharedPost: fetchSharedPostByPostIdReducer,
    usersReportReasons: usersReportReasonsReducer,
    reportPost: reportPostReducer,
    reportUser: reportUserReducer,
    reportedPostDetails: getReportedPostDetailsReducer,
    reportedUserDetails: getReportedUserDetailsReducer,
    blockUser: blockUserByUserIdReducer,
    unblockUser: unblockUserByUserIdReducer,
    blockedUsers: getBlockedUsersReducer
}