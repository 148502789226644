import ApiService from "MainApp/Services/Api/ApiService";
import { sliceActionHandler } from "MainApp/Services/Api/helpers";

export const updatePostView = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    const response = await sliceActionHandler(() => apiService.post('/post/view', { postIds: userInputData.postIds }), {
        userInputData,
        thunkAPI
    });
    return response;
}