import i18n from "../Languages/i18n";
import { MAX_ALLOWED_HASH_TAG } from "./contants";
import toastr from "toastr";

export const getHashtagsArr = (text) => {
    // const regex = /#(\w+\b)(?:\s+(?=#))?/g; // Working without emojis
    // const regex = /(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)/gu; // Emojis only working
    // const regex = /#([\w\p{Emoji_Presentation}\p{Emoji}\uFE0F-]+)/gu; // Working. without space next # taken as single hash tag
    const regex = /#([\w\p{Emoji_Presentation}\p{Emoji}\uFE0F-]+)/gu;
    // Returns the hashtags array. But this won't split at the hash symbol when there's no space
    const matches = Array.from(text.matchAll(regex), m => {
      return `#${m[1]}`
    });
    // Now Split the hash tags without spaces. Ex: From #test#hello to #test, #hello
    let hashTags = [];
    matches.map((match) => {
      const hashTagsArr = match.split(/(?=#)/u).map(part => part.trim());
      hashTags = hashTags.concat(hashTagsArr);
    })
    return hashTags;
}

export const replaceHashTagMatchesWithLinks = (content) => {
    if(!content || typeof content !== 'string') return;
    // const regex = /#\w+/g; // Regex for hashtags
    const regex = /#([\w\p{Emoji_Presentation}\p{Emoji}\uFE0F-]+)/gu;
    return content.replace(
        regex,
        (match) => {
          const hashTagsArr = match.split(/(?=#)/u).map(part => part.trim());
          const linkArr = hashTagsArr.map((hashTag) => {
            const matchWitnNoHash = hashTag.replace('#', '');
            return `<span class="tag_name" data-hash-tag-name='${matchWitnNoHash}'>${hashTag}</span>`;
          });
          return linkArr.join('');
        }
      );
}

export const addHashTagsInPayload = (inputPayload, data) => {
  if(inputPayload && data){
    inputPayload['hashTags'] = getHashtagsArr(data);
  }
  return inputPayload
}

export const isValidHashTagLength = (text) => {
    if(!text || typeof text != "string") return true;
    const hashTagArr = getHashtagsArr(text);

    if(Array.isArray(hashTagArr) && hashTagArr.length > MAX_ALLOWED_HASH_TAG){
        toastr.error(i18n.t('post.hashTag.lengthValidationMsg', {MAX_ALLOWED_HASH_TAG}));
        return false;
    }
    return true;
}