import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { fetchInAppNotifications } from "./actions";
import { IN_APP_NOTIFY_PER_PAGE } from "./constants";

export const fetchInAppNotificationsAction = createAsyncThunk(
    'fetch/inapp/notification', 
    async (userInputData, thunkAPI) => await fetchInAppNotifications(userInputData, thunkAPI)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: [],
    count: IN_APP_NOTIFY_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1
}

const inAppNotificationsSlice = createSlice({
    name: 'inAppNotifications',
    initialState,
    reducers: {
        resetInAppNotifications(state){
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchInAppNotificationsAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(fetchInAppNotificationsAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            //Clear the old data If call is initiated for page 1
            if(payload.userInputData.pageNumber === 1){
                state.data = [];
            }

            const { userInputData, count, data, totalRecords } = payload;
            if(Array.isArray(data)){
                state.data = [...state.data, ...data];
                state.count = count || state.count;
                state.totalRecords = totalRecords || state.totalRecords;
                state.pageNumber = userInputData.pageNumber;
            }
        })
        .addCase(fetchInAppNotificationsAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export const { resetInAppNotifications } = inAppNotificationsSlice.actions;
export default inAppNotificationsSlice.reducer;