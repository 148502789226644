import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { GLOBAL_SEARCH_LIST_PER_PAGE } from "./constants";
import { fetchGlobalSearchLists } from "./actions";

export const fetchGlobalAccountsSearchListsAction = createAsyncThunk(
    'fetch/global/accountsearch', 
    async (userInputData, thunkAPI) => await fetchGlobalSearchLists(userInputData, thunkAPI)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: [],
    count: GLOBAL_SEARCH_LIST_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1
}


const globalAccuntsSearchSlice = createSlice({
    name: 'globalAccountsSearchLists',
    initialState,
    reducers: {
        resetGlobalSearchLists(state){
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchGlobalAccountsSearchListsAction.pending, (state, { payload }) => {
            
            state.loading = ACTION_PENDING;
        })
        .addCase(fetchGlobalAccountsSearchListsAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            //Clear the old data If call is initiated for page 1
            // if(payload.userInputData.pageNumber === 1){
            //     state.data = clearMapData(state.data);
            // }

            const { userInputData, count, data, totalRecords } = payload;
            if(Array.isArray(data)){
                state.data = data || state.data;
                state.count = count || state.count;
                state.totalRecords = totalRecords || state.totalRecords;
                state.pageNumber = userInputData.pageNumber;
            }

        })
        .addCase(fetchGlobalAccountsSearchListsAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export const { resetGlobalSearchLists } = globalAccuntsSearchSlice.actions;
export default globalAccuntsSearchSlice.reducer;

