import ApiService from "MainApp/Services/Api/ApiService";
import { POSTS_LIST_API_DATETIME, FETCH_POSTS_FAILURE, FETCH_POSTS_REQUEST, FETCH_POSTS_SUCCESS, FETCH_POSTS_RESET, DELETE_POST_REQUEST, DELETE_POST_SUCCESS, DELETE_POST_FAILURE, DELETE_POST_RESET, FETCH_POSTS_DELETE_BY_POST_ID } from "./actionTypes";
import { POSTS_PER_PAGE } from "./constants";
import { sliceActionHandler } from "MainApp/Services/Api/helpers";

export const fetchPostsRequest = () => {
    return {
      type: FETCH_POSTS_REQUEST,
      payload: {
        isFetching: true
      }
    };
}
  
export const fetchPostsSuccess = (postsObj) => {
    return {
      type: FETCH_POSTS_SUCCESS,
      payload: {
        ...postsObj
      }
    };
}
  
export const fetchPostsFailure = (error) => {
    return {
      type: FETCH_POSTS_FAILURE,
      payload: {
        error
      } 
    };
}

export const fetchPostsReset = () => {
  return {
    type: FETCH_POSTS_RESET
  };
}

export const fetchPostsDeleteByPostId = (payload) => {
  return {
    type: FETCH_POSTS_DELETE_BY_POST_ID,
    payload
  };
}

export const fetchPosts = (pageNumber = 1, fetchAfterCreatePost = false) => {
  const apiService = new ApiService();
  return (dispatch) => {
      dispatch(fetchPostsRequest());
      apiService.get('/feed/', { page:pageNumber, size:POSTS_PER_PAGE })
      .then((response) => {
          if(response?.status === 200){
            const{count, data, totalRecords, options: { serverUTCDate } = {}} = response;
            dispatch(postsListApiDatetime(serverUTCDate));
            dispatch(fetchPostsSuccess({count, data, totalRecords, pageNumber, fetchAfterCreatePost}))
          }
          else if(response?.status === 204){
            dispatch(fetchPostsSuccess({data: []}));
          }
      })
      .catch((error) => {
          dispatch(fetchPostsFailure(error.message));
      });
  }  
}

export const deletePostRequest = (userInputData) => {
  return{
    type: DELETE_POST_REQUEST,
    payload:{
      isFetching: true
    }
  }
}

export const deletePostSuccess = ({...userInputData}) => {
  return{
    type: DELETE_POST_SUCCESS,
    payload:{
      userInputData
    }
  }
}

export const deletePostFailure = (error, userInputData) => {
  return{
    type: DELETE_POST_FAILURE,
    payload:{
      error,
      userInputData
    }
  }
}

export const deletePostReset = () => {
  return{
    type: DELETE_POST_RESET,
  }
}

export const deletePosts = (userInputData) => {
  const apiService = new ApiService();
  return(dispatch) => {
    dispatch(deletePostRequest());
    apiService.delete(`/post/${userInputData.postId}`, {...userInputData})
    .then((response) => {
      if(response?.status === 200){
        dispatch(deletePostSuccess({ postId:userInputData.postId }));
      }
      else if(response?.status === 204){
        dispatch(deletePostSuccess());
      } 
    })
    .catch((error) =>{
      dispatch(deletePostFailure(error, userInputData))
    })
  }
}

export const postsListApiDatetime = (datetimeString) => {
  return {
    type: POSTS_LIST_API_DATETIME,
    payload: {
      datetimeString
    }
  }
}

export const fetchPostByPostId = async (userInputData, thunkAPI) => {
  const apiService = new ApiService();
  try{
      const response = await apiService.get(`/post/${userInputData.postId}`);
      response['userInputData'] = userInputData;
      return response;
  }
  catch(error){
      return thunkAPI.rejectWithValue({userInputData, error})
  }
}

export const reportPost = async (userInputData, thunkAPI) => {
  const apiService = new ApiService();
  return await sliceActionHandler(() => apiService.post('/post/report', userInputData), {userInputData, thunkAPI});
}

export const getReportedPostDetails = async (userInputData, thunkAPI) => {
  const apiService = new ApiService();
  return await sliceActionHandler(() => apiService.get(`/post/report/${userInputData.postId}`), {userInputData, thunkAPI});
}