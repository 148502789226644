import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postCommentReplyLikedUsers } from "./actions";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { POST_COMMENT_LIKED_USERS_PER_PAGE } from "../constants";
import { formatDataArrayAsMap } from "MainApp/features/Helpers/Map";

export const postCommentReplyLikedUsersThunk = createAsyncThunk(
    'replies/likedUsers', 
    async (userInputData, options) => await postCommentReplyLikedUsers(userInputData, options)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: new Map(),
    count: POST_COMMENT_LIKED_USERS_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1,
    userInputData: {}
}

const postCommentReplyLikedUsersSlice = createSlice({
    name: 'postCommentReplyLikedUsers',
    initialState,
    reducers: {
        resetPostCommentReplyLikedUsers(state){
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(postCommentReplyLikedUsersThunk.pending, (state) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(postCommentReplyLikedUsersThunk.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            const { userInputData, count, data, totalRecords } = payload;
            state.data = formatDataArrayAsMap('userId', data, state.data);
            state.count = count;
            state.totalRecords = totalRecords;
            state.pageNumber = userInputData.pageNumber;
        })
        .addCase(postCommentReplyLikedUsersThunk.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.userInputData = payload.userInputData;
            state.error = payload.error;
        })
    }
});

export const { resetPostCommentReplyLikedUsers } = postCommentReplyLikedUsersSlice.actions;
export default postCommentReplyLikedUsersSlice.reducer;