import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { fetchGuestPostByPostId } from "../actions";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchGuestPostByPostIdAction = createAsyncThunk(
    "fetch/shared/post/by/postId",
    async (userInputData, options) => await fetchGuestPostByPostId(userInputData, options)
  );
  
  const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: null
  };
  
  const fetchGuestPostByPostIdSlice = createSlice({
    name: "fetchGuestPostByPostId",
    initialState,
    reducers: {
      resetSharedPost() {
        return initialState;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchGuestPostByPostIdAction.pending, (state, { payload }) => {
          state.loading = ACTION_PENDING;
        })
        .addCase(fetchGuestPostByPostIdAction.fulfilled, (state, { payload }) => {
          state.loading = ACTION_SUCCEEDED;
          state.data = payload?.data?.[0] || null;
        })
        .addCase(fetchGuestPostByPostIdAction.rejected, (state, { payload }) => {
          state.loading = ACTION_FAILED;
        });
    }
  });
  
  export const { resetSharedPost } = fetchGuestPostByPostIdSlice.actions;
  export const fetchSharedPostByPostIdReducer = fetchGuestPostByPostIdSlice.reducer;