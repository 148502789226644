import { GET_CHAT_TAGS, SET_REORDER_CHAT_TAGS } from "Actions/Constants"
import { sortedChatTags } from "Components/WebChat/Setting/ChatTags/helpers";
import { getFromLocalStorageAndDecrypt } from "Components/WebChat/WebChatEncryptDecrypt"

const initState ={
    data: []
}

export const chatTagReducer = (state = initState, action = {}) => {
    switch(action.type){
        case GET_CHAT_TAGS:
            const chatTagsIdOrder = getFromLocalStorageAndDecrypt('chatTagsIdOrder');
            const isDataArr = Array.isArray(action?.payload?.data);
            let chatTags = []
            if(Array.isArray(chatTagsIdOrder) && chatTagsIdOrder?.length > 0 && isDataArr && action?.payload?.data?.length > 0){
                chatTags = sortedChatTags(action?.payload?.data, chatTagsIdOrder);
            }
            else if(isDataArr){
                chatTags = action?.payload?.data;
            }
            
            return {
                ...state,
                data: chatTags
            }
        case SET_REORDER_CHAT_TAGS:
            return {
                ...state,
                data: action.payload.data
            }
        default:
            return state;
    }
}