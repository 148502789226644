import { formatUserIdToJid } from "Helpers/Chat/User";
import i18n from "MainApp/features/Languages/i18n";
import Store from "Store";
import { blockUserByUserIdAction, unblockUserByUserIdAction } from "./userSlice";
import SDK from "Components/SDK";

export const validateForm = (formData) => {
    const newErrors = {};
    const usernameRegex = /^[a-z0-9_]+$/;
    const notStartWithUnderscoreRegex = /^[^_]/;
    const consecutiveUnderscoreRegex = /^(?!.*__).*$/;
    const requires3Alphabets = /^(?=(.*[a-z]){3,}).*$/;
    
    if (!formData.username.trim()){
        newErrors.username = i18n.t('post.validation.message.usernameRequired');
    }
    else if(formData.username && (formData.username.length <= 3 || !usernameRegex.test(formData.username))){
        newErrors.username = i18n.t('post.validation.message.usernamePattern');
    }
    else if(formData.username && (!notStartWithUnderscoreRegex.test(formData.username) || !consecutiveUnderscoreRegex.test(formData.username))){
        newErrors.username = i18n.t('post.validation.message.usernameUnderscorePattern');
    }
    else if(formData.username && !requires3Alphabets.test(formData.username)){
        newErrors.username = i18n.t('post.validation.message.requires3Alphabets');
    }

    if (!formData.displayName.trim()){
        newErrors.displayName = i18n.t('post.validation.message.displayNameRequired');
    }

    const links = [];
    const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)(\.[a-zA-Z]{2,})+(\/[^\s]*)?$/;
    formData?.links?.map((link) => {
        if(link.link && !urlRegex.test(link.link)){
            links.push({link: i18n.t('post.validation.message.urlInvalid')});
        }
    });

    if(links.length){
        newErrors.links = links;
    }

    return {isValid: Object.keys(newErrors).length === 0, errors: newErrors }; // Return true if no errors
};

export const blockUserBySDK = async (userId) => {
    const userJid = formatUserIdToJid(userId);
    return await SDK.blockUser(userJid);
}

export const blockUserHelper = async (userId) => {
    const res = await blockUserBySDK(userId);

    if (res && res.statusCode === 200) {
        const blockApiRes = await Store.dispatch(blockUserByUserIdAction({userId}));
        if(blockApiRes?.payload?.status === 200){
            return res;
        }
    }
    return {statusCode: 500};
}

export const unblockUserBySDK = async (userId) => {
    const userJid = formatUserIdToJid(userId);
    return await SDK.unblockUser(userJid);
}

export const unblockUserHelper = async (userId) => {
    const res = await unblockUserBySDK(userId);

    if (res && res.statusCode === 200) {
        const unblockApiRes = await Store.dispatch(unblockUserByUserIdAction({userId}));
        if(unblockApiRes?.payload?.status === 200){
            return res;
        }
    }
    return {statusCode: 500};
}

export const isUserBlockedHelper = (user) => user?.isUserBlocked == 1;
export const isUserBlockedMeHelper = (user) => user?.isUserBlockedMe == 1;

export const getPostedUserFromPostObj = (post) => {
    return {...post?.postedUser, following:post?.isFollowing, follower:post?.isFollower, isUserBlocked:post.isUserBlocked, isUserBlockedMe:post.isUserBlockedMe};
}