import ApiService from "MainApp/Services/Api/ApiService";
import { BLOCKED_USERS_LIST_PER_PAGE, USERS_LIST_PER_PAGE, USER_POSTS_PER_PAGE } from "./constants";
import { sliceActionHandler } from "MainApp/Services/Api/helpers";
import { REACT_APP_WEBAPP_MEDIA_URL } from "Components/processENV";
import { failedUpdateProfile, requestUpdateProfile, updateProfileAction } from "./updateProfileSlice";

export const fetchUserProfile = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    try{
        const response = await apiService.get(`users/profile?userId=${userInputData.userId}&detailedInfo=${userInputData.detailedInfo || false}`);
        response['userInputData'] = userInputData;
        return response;
    }
    catch(error){
        return thunkAPI.rejectWithValue({userInputData, error})
    }
}

export const fetchPostsByUserId = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    try{
        const response = await apiService.get(`post/user/${userInputData.userId}`, { page:userInputData.pageNumber, size:USER_POSTS_PER_PAGE});
        response['userInputData'] = userInputData;
        return response;
    }
    catch(error){
        return thunkAPI.rejectWithValue({userInputData, error})
    }
}

export const fetchFollowersByUserId = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    
    const response = await sliceActionHandler(() => apiService.get('/users/followers', { userId: userInputData.dataId, search: userInputData.search, page:userInputData.pageNumber, size: USERS_LIST_PER_PAGE }), {
        userInputData,
        thunkAPI
    });
    return response;
}

export const fetchFollowingByUserId = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    
    const response = await sliceActionHandler(() => apiService.get('/users/following', { userId: userInputData.dataId, search: userInputData.search, page:userInputData.pageNumber, size: USERS_LIST_PER_PAGE }), {
        userInputData,
        thunkAPI
    });
    return response;
}

export const updateProfile = async (userInputData, thunkAPI) => {
    const {fileTokensData, inputPayload = {}, uploadedFiles} = userInputData;
    const { user } = inputPayload;
    if(fileTokensData?.fileToken){
        user.image = fileTokensData.fileToken;
    }
    const apiService = new ApiService();
    return await sliceActionHandler(() => apiService.put('/users/profile', user), {userInputData, thunkAPI});
}

export const profileImageUpload = async (userInputData, thunkAPI) => {
    thunkAPI.dispatch(requestUpdateProfile());
    const { file, inputPayload } = userInputData;

    const apiService = new ApiService(REACT_APP_WEBAPP_MEDIA_URL);
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await sliceActionHandler(() => apiService.post('/uploadMedia/profile', formData), {
        userInputData,
        thunkAPI,
        successCallback: (response) => {
            thunkAPI.dispatch(updateProfileAction({fileTokensData: response.data, inputPayload}))
        },
        errorCallback: () => {
            thunkAPI.dispatch(failedUpdateProfile());
        }
    });
    return response;
}

export const usernameAvailable = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    
    const response = await sliceActionHandler(() => apiService.get('/users/username/checkAvailability', { username: userInputData.username }), {
        userInputData,
        thunkAPI
    });
    return response;
}

export const fetchUsersReportReasons = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    
    const response = await sliceActionHandler(() => apiService.get('/users/report-reasons'), {
        userInputData,
        thunkAPI
    });
    return response;
}

export const reportUser = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    return await sliceActionHandler(() => apiService.post('/users/report', userInputData), {userInputData, thunkAPI});
}

export const getReportedUserDetails = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    return await sliceActionHandler(() => apiService.get(`/users/report/${userInputData.userId}`), {userInputData, thunkAPI});
  }

export const blockUserByUserId = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    return await sliceActionHandler(() => apiService.post(`/users/user-block`, userInputData), {userInputData, thunkAPI});
}

export const unblockUserByUserId = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    return await sliceActionHandler(() => apiService.post(`/users/user-unblock`, {...userInputData}), {userInputData, thunkAPI});
}

export const getBlockedUsers = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    return await sliceActionHandler(() => apiService.get(`/users/user-block`, { page:userInputData.pageNumber, size: BLOCKED_USERS_LIST_PER_PAGE }), {userInputData, thunkAPI});
}