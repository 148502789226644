import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createPost, createPostMediaUpload } from "./actions";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";

const POST_CREATE = 'post/create';
export const createPostAction = createAsyncThunk(
    POST_CREATE, 
    async (userInputData, thunkAPI) => await createPost(userInputData, thunkAPI)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    actionType: POST_CREATE
}

const createPostSlice = createSlice({
    name: 'createPost',
    initialState,
    reducers: {
        resetCreatePost(state){
            return initialState;
        },
        requestCreatePost(state){
            state.loading = ACTION_PENDING;
        },
        failedCreatePost(state){
            state.loading = ACTION_FAILED;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createPostAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(createPostAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
        })
        .addCase(createPostAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export const { requestCreatePost, resetCreatePost, failedCreatePost} = createPostSlice.actions;

export const createPostReducer = createPostSlice.reducer;


const POST_CREATE_MEDIA_UPLOAD = 'post/create/mediaUpload';
export const createPostMediaUploadAction = createAsyncThunk(
    POST_CREATE_MEDIA_UPLOAD, 
    async (userInputData, thunkAPI) => await createPostMediaUpload(userInputData, thunkAPI)
)

const mediaInitialState = {
    loading: ACTION_IDLE,
    error: null,
    actionType: POST_CREATE_MEDIA_UPLOAD
}

const createPostMediaUploadSlice = createSlice({
    name: 'createPostMediaUpload',
    initialState: mediaInitialState,
    reducers: {
        resetCreatePostMediaUpload(state){
            return mediaInitialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createPostMediaUploadAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(createPostMediaUploadAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
        })
        .addCase(createPostMediaUploadAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export const { resetCreatePostMediaUpload } = createPostMediaUploadSlice.actions;
export const createPostMediaUploadReducer = createPostMediaUploadSlice.reducer;