import { CONTACT_DATA_LIST } from '../Actions/Constants';

const CONTACT_LIST_DEFAULT_STATE = {
    id: null,
    contacts: [],
    pageNumber:1
  }

export function ContactList(state = CONTACT_LIST_DEFAULT_STATE, action = {}) {
  switch (action.type) {
    case CONTACT_DATA_LIST:
      return {
        ...state,
        contacts: action.payload.contacts,
        pageNumber: action.payload.pageNumber,
      };
    default:
      return state;
  }
}
