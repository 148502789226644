import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { HASH_TAGS_PER_PAGE } from "./contants";
import { clearMapData, formatDataArrayAsMap } from "../Helpers/Map";
import { fetchHashTags } from "./actions";

export const fetchHashTagsAction = createAsyncThunk(
    'fetch/hash/tag', 
    async (userInputData, thunkAPI) => await fetchHashTags(userInputData, thunkAPI)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: new Map(),
    count: HASH_TAGS_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1
}

const hashTagsSlice = createSlice({
    name: 'hashTags',
    initialState,
    reducers: {
        resetHashTags(state){
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchHashTagsAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(fetchHashTagsAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            //Clear the old data If call is initiated for page 1
            if(payload.userInputData.pageNumber === 1){
                state.data = clearMapData(state.data);
            }

            const { userInputData, count, data, totalRecords } = payload;
            if(Array.isArray(data)){
                state.data = formatDataArrayAsMap('id', data, state.data);
                state.count = count || state.count;
                state.totalRecords = totalRecords || state.totalRecords;
                state.pageNumber = userInputData.pageNumber;
            }
        })
        .addCase(fetchHashTagsAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export const { resetHashTags } = hashTagsSlice.actions;
export default hashTagsSlice.reducer;