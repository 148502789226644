import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { getPushNotificationByUserId, updatePushNotificationByUserId } from "./actions";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const GET_PUSH_NOTIFICATION_BY_USERID = 'users/profile/notification-settings';
const UPDATE_PUSH_NOTIFICATION_BY_USERID = 'update/users/profile/notification-settings';

export const getPushNotificationByUserIdAction =
 createAsyncThunk(
    GET_PUSH_NOTIFICATION_BY_USERID,
    async (userInputData, options) => await getPushNotificationByUserId(userInputData, options)
)
export const updatePushNotificationByUserIdAction = createAsyncThunk(
    UPDATE_PUSH_NOTIFICATION_BY_USERID,
    async (userInputData, options) => await updatePushNotificationByUserId(userInputData, options)
)

const pushNotificationInitState = {
    loading: ACTION_IDLE,
    error: null,
    data: {},
}

const pushNotificationSlice = createSlice({
    name: 'pushNotification',
    initialState: pushNotificationInitState,
    reducers: {
        resetPushNotification(state){
            return pushNotificationInitState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPushNotificationByUserIdAction.pending,(state, {payload}) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(getPushNotificationByUserIdAction.fulfilled, (state, {payload}) => {
            state.loading = ACTION_SUCCEEDED;
            state.data = payload.data;
        })
        .addCase(getPushNotificationByUserIdAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
        .addCase(updatePushNotificationByUserIdAction.pending,(state, {payload}) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(updatePushNotificationByUserIdAction.fulfilled, (state, {payload}) => {
            state.loading = ACTION_SUCCEEDED;
            state.data = payload.data;
        })
        .addCase(updatePushNotificationByUserIdAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.userInputData = payload.userInputData;
            state.error = payload?.error
        })
    }
});


export const { resetPushNotification } = pushNotificationSlice.actions;

export const settingPushNotificationReducer = pushNotificationSlice.reducer;
