import { decrypt, encrypt, getEncryptKey } from "Components/WebChat/WebChatEncryptDecrypt";

export const isSharedUrl = (pathname, keyword="post") => {
    const segment1 = pathname?.split('/')?.[1];
    if(!segment1) return;

    const targetPaths = ['post', 'profile'];
    return targetPaths.find((path) => path === segment1);
}

export const decryptSharedData = (dataId) => decrypt(decodeURIComponent(dataId), getEncryptKey());

export const encryptSharedData = (dataId) => encrypt(dataId, getEncryptKey());