import { sliceActionHandler } from "MainApp/Services/Api/helpers";
import { IN_APP_NOTIFY_PER_PAGE } from "./constants";
import ApiService from "MainApp/Services/Api/ApiService";
import { postsListApiDatetime } from "../Post/actions";

export const fetchInAppNotifications = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();

    const response = await sliceActionHandler(() => apiService.get('/users/notifications', { page:userInputData.pageNumber, size: userInputData.size || IN_APP_NOTIFY_PER_PAGE}), {
        userInputData,
        thunkAPI,
        successCallback: (response) => {
            if(response?.options?.serverUTCDate)
            thunkAPI.dispatch(postsListApiDatetime(response.options.serverUTCDate));
        },
    });
    
    return response;
}