export const REACT_APP_WEBAPP_DEVICE_TYPE='WEB'
export const PAGINATION_RECORD_PER_PAGE = 15;
export const RICH_TEXT_EDITOR_MAX_LENGTH = 2200;
export const PHONE_NUMBER_MAX_LENGTH = 15;
export const PAGE_TYPE = {
    home: "home",
    explore: "explore",
    globalSearch: "globalSearch",
    hashTag: "hashTag",
    profile: "profile",
    sharedPost: "sharedPost"
};