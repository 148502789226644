import { getAuthUserDetails } from "MainApp/Services/Login/helpers";
import { pluralize } from "MainApp/features/App/helpers";
import { base64ToFileInst, generatBase64FromImage, generateBase64FromVideo, getFileExtFromFilenameOrFile, getFiletypeFromFileInst, isImageFileType, isVideoFileType, validateFileExt, validateFileSize } from "MainApp/features/Media/helpers";
import { toState } from "Store/helpers";
import { getUTCMilliseconds } from "../DateTime/helpers";
import { POPUP_COMPONENT_TYPES } from "../Popup/constants";
import { IMAGE_ALLOWED_EXT, IMAGE_MAX_ALLOWED_SIZE_MB, MAX_MEDIA_FILES_ALLOWED, POST_VISIBILITY, VIDEO_ALLOWED_EXT, VIDEO_ALLOWED_STREAMING_EXT, VIDEO_MAX_ALLOWED_DURATION_SEC, VIDEO_MAX_ALLOWED_SIZE_BYTES, VIDEO_MAX_ALLOWED_SIZE_MB } from "./constants";
import toastr from 'toastr';
import i18n from "MainApp/features/Languages/i18n";
import { isObject } from "MainApp/features/Helpers/Object";
import { cloneDeep } from "lodash";
import { convertMbToBytes, isNumber } from "MainApp/features/Helpers/NumberFormats";
import SettingsService from "MainApp/Services/Settings/SettingsService";

const getImageMaxSizeInMb = () => {
  const settings = SettingsService.getLocalSettings();  
  return settings?.imageSizeLimitInMB || IMAGE_MAX_ALLOWED_SIZE_MB;
}

const getVideoMaxSizeInMb = () => {
  const settings = SettingsService.getLocalSettings();
  return settings?.videoSizeLimitInMB || VIDEO_MAX_ALLOWED_SIZE_MB;
}

const getVideoMaxDuration = () => {
  const settings = SettingsService.getLocalSettings();
  return settings?.videoDurationInSecs || VIDEO_MAX_ALLOWED_DURATION_SEC;
}

export const isCreatePostApiInitiated = () => {
    const state = toState();
    return state.createPost.isPosting;
}
/**
 * Check here, create post has selected the file and in crop or after file select page
 * @param {*} popup 
 * @returns 
 */
export const hasCreatePostSelectedTheFile = (popup) => {
    return popup?.type === POPUP_COMPONENT_TYPES.createPost && popup?.nestedType
}

export const getCreatePostFilename = (fileExt) => {
    const authUser = getAuthUserDetails();
    let filename = `${authUser.userId}_${getUTCMilliseconds()}`;
    if(fileExt){
        filename = `${filename}.${fileExt}`;
    }
    return filename;
}

export const getCreatePostThumbFilename = (filename, fileExt) => {
    // Find the last occurrence of the separator
    const lastIndex = filename.lastIndexOf('.');
    filename = lastIndex !== -1 ? filename.substring(0, lastIndex) : filename;
    fileExt = fileExt || filename.substring(lastIndex + 1);
    return `${filename}_thumb.${fileExt}`;
}

export const validateFiles = (files = [], selectedFiles = []) => {
    if (!files || !files.length) return { isValid: false, message: i18n.t('post.validation.message.emptyFiles') };
  
    const maxAllowedFiles = MAX_MEDIA_FILES_ALLOWED;
    const filesLength = files.length + selectedFiles.length;
    if (filesLength > maxAllowedFiles) return { isValid: false, message: i18n.t('post.validation.message.maximumAllowedFiles', {MAX_MEDIA_FILES_ALLOWED}) };
    const imgAllowedExtensions = IMAGE_ALLOWED_EXT;
    const imgMaxSizeInMb = getImageMaxSizeInMb();
    const imgMaxSize = convertMbToBytes(imgMaxSizeInMb);
  
    const videoAllowedExtensions = VIDEO_ALLOWED_EXT;
    const videoMaxSizeInMb = getVideoMaxSizeInMb();
    const videoMaxSize = convertMbToBytes(videoMaxSizeInMb);
  
    let inValidExtFileCount = 0;
    let inValidSizeImgFileCount = 0;
    let inValidSizeVideoFileCount = 0;
    const filesToUpdate = [];
    [...files].map((file) => {
      const extension = getFileExtFromFilenameOrFile(file)?.toLowerCase();
      const fileType = getFiletypeFromFileInst(file);
      if (isImageFileType(fileType)) {
        if (!validateFileExt(imgAllowedExtensions, extension)) {
            inValidExtFileCount += 1;
        }
        else if(!validateFileSize(imgMaxSize, file.size)){
          inValidSizeImgFileCount += 1;
        }
        else {
          filesToUpdate.push(file);
        }
      }
      else if (isVideoFileType(fileType)) {
        if (!validateFileExt(videoAllowedExtensions, extension)) {
            inValidExtFileCount += 1;
        }
        else if(!validateFileSize(videoMaxSize, file.size)){
          inValidSizeVideoFileCount += 1;
        }
        else {
          filesToUpdate.push(file);
        }
      }
      else {
        inValidExtFileCount +=1;
      }
    });
    const inValidFileCount = inValidExtFileCount + inValidSizeImgFileCount + inValidSizeVideoFileCount;
  
    let message = '';
    if(inValidExtFileCount){
      message = `Unsupported file format. Files allowed: ${videoAllowedExtensions.join(',')}, ${imgAllowedExtensions.join(', ')}. `;
    }
    if (inValidSizeImgFileCount) {
      message = `${message}${inValidSizeImgFileCount} ${pluralize(inValidSizeImgFileCount, 'image')} have exceeded the size limit of ${imgMaxSizeInMb} MB. `;
    }
    if (inValidSizeVideoFileCount) {
      message = `${message}${inValidSizeVideoFileCount} ${pluralize(inValidSizeVideoFileCount, 'video')} have exceeded the size limit of ${videoMaxSizeInMb} MB.`;
    }
  
    if (inValidFileCount === files.length) {
      // 2 files have exceeded the size limit of 200 MB
      return { isValid: false, message };
    }
  
    const validObj = { isValid: true, files: filesToUpdate };
    if (inValidFileCount) {
      validObj['message'] = message;
    }
  
    return validObj;
};

export const isVideoFile = (filenameOrExt) => {
  let fileExt = getFileExtFromFilenameOrFile(filenameOrExt);
  fileExt = fileExt || filenameOrExt;
  return VIDEO_ALLOWED_EXT.indexOf(fileExt) > -1;
}
export const isImageFile = (filenameOrExt) => {
  let fileExt = getFileExtFromFilenameOrFile(filenameOrExt);
  fileExt = fileExt || filenameOrExt;
  return IMAGE_ALLOWED_EXT.indexOf(fileExt) > -1;
}
export const isVideoStreamingFile = (filenameOrExt) => {
  let fileExt = getFileExtFromFilenameOrFile(filenameOrExt);
  fileExt = fileExt || filenameOrExt;
  return VIDEO_ALLOWED_STREAMING_EXT.indexOf(fileExt) > -1;
}

export const isVideoOrStreamingFile = (filenameOrExt) => {
  return isVideoStreamingFile(filenameOrExt) || isVideoFile(filenameOrExt);
}

export const onGenerateMediaThumb = async (file, previewSrcKey = 'originalFilePreviewSrc') => {
  let thumbGeneratePromise = null;
  try{
      if(isImageFileType(file.fileInfo.fileType)){
          thumbGeneratePromise = await generatBase64FromImage(file[previewSrcKey], 0);
      }

      if(isVideoFileType(file.fileInfo.fileType) && !file.thumbnail){
          thumbGeneratePromise = await generateBase64FromVideo(file[previewSrcKey], 1);
      }

      if(thumbGeneratePromise){
          let {base64, fileDuration = 0, extension, width, height} = thumbGeneratePromise;
          const thumbFile = base64ToFileInst(base64, getCreatePostThumbFilename(file.filename, extension));
          fileDuration = typeof fileDuration === 'number' ? parseInt(fileDuration) : fileDuration;

          file['fileInfo'] = {
            ...file.fileInfo,
            width,
            height,
            fileDuration,
            fileMimeType: file.originalFile.type,
            aspectRatio: 0,
            croppedAreaPixels: null
          }

          if(isVideoFileType(file.fileInfo.fileType)){
            file['thumbnail'] = {
                file: thumbFile,
                previewSrc: URL.createObjectURL(thumbFile)
            };
          }
      }
      return file;
  }
  catch(error){
      return file;
  }
}

export const validateImageFiles = (files = [], selectedFiles = [], options={}) => {
  if (!files || !files.length) return { isValid: false, message: i18n.t('post.validation.message.emptyFiles') };

  const maxAllowedFiles = MAX_MEDIA_FILES_ALLOWED;
  const filesLength = files.length + selectedFiles.length;
  if (filesLength > maxAllowedFiles) return { isValid: false, message: i18n.t('post.validation.message.maximumAllowedFiles', {MAX_MEDIA_FILES_ALLOWED}) };

  const imgAllowedExtensions = IMAGE_ALLOWED_EXT;
  const imgMaxSizeInMb = getImageMaxSizeInMb();
  const imgMaxSize = convertMbToBytes(imgMaxSizeInMb);

  let inValidExtFileCount = 0;
  let inValidSizeImgFileCount = 0;
  const filesToUpdate = [];
  [...files].map((file) => {
    const extension = getFileExtFromFilenameOrFile(file)?.toLowerCase();
    const fileType = getFiletypeFromFileInst(file);
    if (isImageFileType(fileType)) {
      if (!validateFileExt(imgAllowedExtensions, extension)) {
          inValidExtFileCount += 1;
      }
      else if(!validateFileSize(imgMaxSize, file.size)){
        inValidSizeImgFileCount += 1;
      }
      else {
        filesToUpdate.push(file);
      }
    }
    else if (isVideoFileType(fileType)) {
      filesToUpdate.push(file);
    }
    else {
      inValidExtFileCount +=1;
    }
  });
  const inValidFileCount = inValidExtFileCount + inValidSizeImgFileCount;

  let inValidFileMsg = null;
  if(inValidExtFileCount){
    inValidFileMsg = i18n.t('post.validation.message.unsupportedFilesMessage', {MEDIA_FILES_ALLOWED_EXT: `${VIDEO_ALLOWED_EXT.join(',')}, ${IMAGE_ALLOWED_EXT.join(', ')}`});
  }
  let inValidImageSizeMsg = null;
  if (inValidSizeImgFileCount) {
    inValidImageSizeMsg = options.inValidImageSizeMsg || `<b>${i18n.t('common.label.images')}:</b> ${i18n.t('post.validation.message.inValidImagesSizeMsg', {fileCount: inValidSizeImgFileCount, IMAGE_MAX_ALLOWED_SIZE_MB: imgMaxSizeInMb})}`;
  }

  if (inValidFileCount === files.length) {
    let message = null;
    if(inValidFileMsg){
      message = inValidFileMsg;
    }

    if(inValidImageSizeMsg){
      message = message ? `${message}<br><br>` : '';
      message = `${message}${inValidImageSizeMsg}`;
    }
    return { isValid: false, message };
  }

  const validObj = { isValid: true, files: filesToUpdate, inValidImageSizeMsg, inValidFileMsg };

  return validObj;
}

export const validateVideoFiles = (files = []) => {
    const videoAllowedExtensions = VIDEO_ALLOWED_EXT;
    const videoMaxDuration = getVideoMaxDuration();

    let inValidExtFileCount = 0;
    let inValidDurationVideoFileCount = 0;
    const filesToUpdate = [];
    const filesArr = Object.keys(files);
    [...filesArr].map((fileKey) => {
      const file = files[fileKey];
      const extension = getFileExtFromFilenameOrFile(file.originalFile)?.toLowerCase();
      if (isVideoFileType(file.fileInfo.fileType)) {
        if (!validateFileExt(videoAllowedExtensions, extension)) {
            inValidExtFileCount += 1;
        }
        else if(!validateFileSize(videoMaxDuration, file.fileInfo.fileDuration)){
          inValidDurationVideoFileCount += 1;
        }
        else {
          filesToUpdate.push(file);
        }
      }
      else if (isImageFileType(file.fileInfo.fileType)) {
        filesToUpdate.push(file); 
      }
    });
    const inValidFileCount = inValidExtFileCount + inValidDurationVideoFileCount;
  
    let inValidFileMsg = null;
    if(inValidExtFileCount){
      inValidFileMsg = i18n.t('post.validation.message.unsupportedFilesMessage', {MEDIA_FILES_ALLOWED_EXT: `${VIDEO_ALLOWED_EXT.join(',')}, ${IMAGE_ALLOWED_EXT.join(', ')}`});
    }
    let inValidVideoDurationMsg = null;
    if (inValidDurationVideoFileCount) {
      inValidVideoDurationMsg = `<b>${i18n.t('common.label.videos')}:</b> ${i18n.t('post.validation.message.inValidVideoDurationMsg', {fileCount: inValidDurationVideoFileCount, VIDEO_MAX_ALLOWED_DURATION_SEC: videoMaxDuration})}`;
    }
  
    if (inValidFileCount === filesArr.length) {
      let message = null;
      if(inValidFileMsg){
        message = inValidFileMsg;
      }
  
      if(inValidVideoDurationMsg){
        message = message ? `${message}<br><br>` : '';
        message = `${message}${inValidVideoDurationMsg}`;
      }
      return { isValid: false, message };
    }
  
    const validObj = { isValid: true, files: filesToUpdate, inValidFileMsg, inValidVideoDurationMsg };
  
    return validObj;
}

export const generateFileObj = async (currentlySelectedFiles, selectedFiles) => {
    const validationResult = validateImageFiles(currentlySelectedFiles, selectedFiles);

    if(!validationResult?.isValid){
        toastr.error(validationResult.message);
        return;
    }

    const files = validationResult.files;
    const filesToUpdate = [];
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileType = getFiletypeFromFileInst(file); // Check if it's an image or video

        if(isImageFileType(fileType) || isVideoFileType(fileType)){
            const fileExt = getFileExtFromFilenameOrFile(file);
            const filename = getCreatePostFilename();
            const newFile = {
                originalFile: file,
                originalFilePreviewSrc: URL.createObjectURL(file), // Used as source for Crop and Preview screen
                updatedFile: undefined, // File to send in API
                imageCropperRef: { current: null }, // Used to get the cropped image
                filename: `${filename}.${fileExt}`,
                fileInfo: {
                  fileType
                }
            }
            const fileWithThumb = await onGenerateMediaThumb(newFile);
            filesToUpdate.push(fileWithThumb);
        } else {
            console.log('Unsupported file type:', fileType);
        }
    }

    const vidoeValidationResult = validateVideoFiles(filesToUpdate);
    if(!vidoeValidationResult?.isValid){
        toastr.error(vidoeValidationResult.message);
        return;
    }

    let message = null;
    if(validationResult.inValidFileMsg || vidoeValidationResult.inValidFileMsg){
      message = validationResult.inValidFileMsg || vidoeValidationResult.inValidFileMsg;
    }
    if(validationResult.inValidImageSizeMsg){
      message = message ? `${message}<br><br>` : '';
      message = `${message}${validationResult.inValidImageSizeMsg}`;
    }
    if(vidoeValidationResult.inValidVideoDurationMsg){
      message = message ? `${message}<br><br>` : '';
      message = `${message}${vidoeValidationResult.inValidVideoDurationMsg}`;
    }

    if(message){
      toastr.error(message);
    }

    return vidoeValidationResult.files;
}

export const isPrivatePost = (postVisibility) => postVisibility === POST_VISIBILITY.private;

export const validateWhoCanView = (postVisibility, closeFriends) => {
  if(isPrivatePost(postVisibility) && (!closeFriends || !isObject(closeFriends) || (isObject(closeFriends) && !Object.keys(closeFriends).length))){
    toastr.error(i18n.t('post.whoCanViewThisPost.emptyCloseFriendsValidationMsg'));
    return false;
  }
  return true;
}

export const getValidInputPayload = (inputPayload) => {
    if(!inputPayload) return inputPayload;
    // Convert close firends object as userId array
    const inputPayloadToUpdate = cloneDeep(inputPayload);
    if(isPrivatePost(inputPayloadToUpdate.postVisibility) && isObject(inputPayloadToUpdate.closeFriends)){
        inputPayloadToUpdate.closeFriends = Object.keys(inputPayloadToUpdate.closeFriends);
    }
    else{
        inputPayloadToUpdate.closeFriends = null;
    }
    return inputPayloadToUpdate;
}