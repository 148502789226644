import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReportedPostDetails, reportPost } from "../actions";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import toastr from "toastr";

export const reportPostAction = createAsyncThunk(
    'report/post', 
    async (userInputData, thunkAPI) => await reportPost(userInputData, thunkAPI)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null
}

const reportPostSlice = createSlice({
    name: 'reportPost',
    initialState,
    reducers: {
        resetReportPost() {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(reportPostAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(reportPostAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
        })
        .addCase(reportPostAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export const { resetReportPost } = reportPostSlice.actions;
export const reportPostReducer = reportPostSlice.reducer;


export const getReportedPostDetailsAction = createAsyncThunk(
    'get/reported/post/details', 
    async (userInputData, thunkAPI) => await getReportedPostDetails(userInputData, thunkAPI)
)

const getReportedPostInitState = {
    loading: ACTION_IDLE,
    error: null,
    data: null
};

const getReportedPostDetailsSlice = createSlice({
    name: 'getReportedPostDetails',
    initialState: getReportedPostInitState,
    reducers: {
        resetGetReportedPostDetails() {
            return getReportedPostInitState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getReportedPostDetailsAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(getReportedPostDetailsAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.data = payload?.data;
            if(state.data?.isReported){
                toastr.error(payload.message);
            }
        })
        .addCase(getReportedPostDetailsAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export const { resetGetReportedPostDetails } = getReportedPostDetailsSlice.actions;
export const getReportedPostDetailsReducer = getReportedPostDetailsSlice.reducer;