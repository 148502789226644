import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { generateInviteCode } from "./actions";

export const generateInviteCodeAction = createAsyncThunk(
    'generate/invite/code', 
    async (userInputData, thunkAPI) => await generateInviteCode(userInputData, thunkAPI)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: {}
}

const generateInviteCodeSlice = createSlice({
    name: 'generateInviteCode',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(generateInviteCodeAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(generateInviteCodeAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.data = payload?.data || {};
        })
        .addCase(generateInviteCodeAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export default generateInviteCodeSlice.reducer;