import uuidv4 from 'uuid/v4';
import { UPDATE_USER_LANGUAGE_SUCCESS } from "./Constants"



export const updateUserLanguage = (data) => {
    return {
        type: UPDATE_USER_LANGUAGE_SUCCESS,
        payload: {
            id: uuidv4(),
            data
        }
    }
}