import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { fetchUserProfile, profileImageUpload } from "./actions";
import { storeAuthUserInLocalStorage } from "MainApp/Services/Login/helpers";
import { formatUserIdToJid } from "Helpers/Chat/User";
import { fetchGuestUserProfileByUserId } from "../Shared/actions";
import { LoginService } from "MainApp/Services/Login/LoginService";

const FETCH_LOCAL_USER_PROFILE = 'local/userProfile/fetch';
export const fetchLocalUserProfileAction = createAsyncThunk(
    FETCH_LOCAL_USER_PROFILE, 
    async (userInputData, thunkAPI) => await fetchUserProfile(userInputData, thunkAPI)
)

const localUserInitState = {
    loading: ACTION_IDLE,
    error: null,
    data: {},
    actionType: '',
    userInputData: {}
}

const localUserProfileSlice = createSlice({
    name: 'localUserProfile',
    initialState: localUserInitState,
    reducers: {
        updatelocalUserProfile(state, { payload }){
            const { user } = payload;
            state.data = user;
            storeAuthUserInLocalStorage(user);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLocalUserProfileAction.pending, (state) => {
            state.loading = ACTION_PENDING;
            state.actionType = FETCH_LOCAL_USER_PROFILE;
        })
        .addCase(fetchLocalUserProfileAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.actionType = FETCH_LOCAL_USER_PROFILE;
            state.userInputData = payload.userInputData;
            state.data = payload.data;
            storeAuthUserInLocalStorage(payload.data);
        })
        .addCase(fetchLocalUserProfileAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.actionType = FETCH_LOCAL_USER_PROFILE;
            state.userInputData = payload.userInputData;
            state.data = payload.data
        })
    }
});

export const { updatelocalUserProfile } = localUserProfileSlice.actions;
export const localUserProfileSliceReducer = localUserProfileSlice.reducer;

const FETCH_USER_PROFILE = 'userProfile/fetch';

export const fetchUserProfileThunk = createAsyncThunk(
    FETCH_USER_PROFILE, 
    async (userInputData, thunkAPI) => LoginService.isUserLoggedIn() ? await fetchUserProfile({...userInputData, detailedInfo: true}, thunkAPI): await fetchGuestUserProfileByUserId(userInputData, thunkAPI)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: {},
    actionType: '',
    userInputData: {},
    message: null,
    status: null
}

const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
        resetUserProfile(){
            return initialState;
        },
        updateUserProfile(state, { payload }){
            const { user } = payload;
            state.data = user;
        },
        updateUserProfileFollowStatus(state, { payload }){
            if(state?.data?.userId === payload?.userId){
                state.data.following = payload.following;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserProfileThunk.pending, (state) => {
            state.loading = ACTION_PENDING;
            state.actionType = FETCH_USER_PROFILE;
            state.message = null;
        })
        .addCase(fetchUserProfileThunk.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.actionType = FETCH_USER_PROFILE;
            state.userInputData = payload.userInputData;
            state.data = payload.data;
            if(payload.status === 204){
                state.message = payload.message;
            }
        })
        .addCase(fetchUserProfileThunk.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.actionType = FETCH_USER_PROFILE;
            state.userInputData = payload.userInputData;
            state.data = payload.data;
            state.message = null;
        })
        .addMatcher(
            (action) => action.type === "unblock/user/fulfilled",
            (state, action) => {
                if(state?.data?.isUserBlocked){
                    state.data.isUserBlocked = 0;
                }
                return state;
            }
        )
        .addMatcher(
            (action) => action.type === "block/user/fulfilled",
            (state, action) => {
                if(!state?.data?.isUserBlocked){
                    state.data.isUserBlocked = 1;
                }
                return state;
            }
        );
    }
});

export const { resetUserProfile, updateUserProfile, updateUserProfileFollowStatus } = userProfileSlice.actions;

export default userProfileSlice.reducer;


const profileMsgInitState = {
    user: null  
}

const profileMessageUserSlice = createSlice({
    name: 'profileMessage',
    initialState: profileMsgInitState,
    reducers: {
        setProfileMessageUser(state, { payload }){
            const { user } = payload; 
            state.chatInitiated = false;           
            state.user = {
                "image": user.image,
                "thumbImage": null,
                "isAdminBlocked": false,
                "status": null,
                "name": user.username,
                "nickName": user.displayName,
                "email": user.email,
                "mobileNumber": user.mobileNumber,
                "userId": user.userId,
                "userJid": formatUserIdToJid(user.userId),
                "isFriend": true,
                "isDeletedUser": false
            };
        },
        resetProfileMessageUser(){
            return profileMsgInitState;
        }
    }
});

export const { setProfileMessageUser, resetProfileMessageUser, chatInitiatedProfileMessageUser } = profileMessageUserSlice.actions;

export const profileMessageUserSliceReducer = profileMessageUserSlice.reducer;