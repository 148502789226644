import { cloneDeep } from 'lodash';
import { updatePostLikeCount } from '../Post/helpers';
import { OPEN_SOCIAL_POPUP, CLOSE_SOCIAL_POPUP, OPEN_OVERLAY_SOCIAL_POPUP, CLOSE_OVERLAY_SOCIAL_POPUP, OPEN_NESTED_SOCIAL_POPUP } from './actionTypes';
import { POPUP_COMPONENT_TYPES } from './constants';
import { isGivenSocialPopupOpen } from './helpers';
import { UPDATE_LIKE_STATE_TO_STORE_POST } from '../PostLike/actionTypes';

const initialState = {
  isOpen: false,
  type: null,
  nestedType: null,
  data: null,
  overlayPopup: null // Maintain the secondary popup details here
};


const popupReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SOCIAL_POPUP:
      return {
        ...state,
        isOpen: true,
        type: action.payload.type,
        data: action.payload.data,
        nestedType: null,
        overlayPopup: null
      };
    case CLOSE_SOCIAL_POPUP:
      return {
        ...state,
        isOpen: false,
        type: null,
        nestedType: null,
        data: null,
        overlayPopup: null
      };
    case OPEN_NESTED_SOCIAL_POPUP:
      return {
        ...state,
        nestedType: action.payload.type
      }
    case OPEN_OVERLAY_SOCIAL_POPUP:
      return {
        ...state,
        overlayPopup: {...action.payload}
      }
    case CLOSE_OVERLAY_SOCIAL_POPUP:
      return {
        ...state,
        overlayPopup: null
      }
    case UPDATE_LIKE_STATE_TO_STORE_POST:
      const likeNewState = cloneDeep(state);
      if(isGivenSocialPopupOpen(POPUP_COMPONENT_TYPES.previewPost, likeNewState) && likeNewState.data.post){
        const post = likeNewState.data.post;
        const postToUpdate = updatePostLikeCount(post, action.payload);
        likeNewState.data.post = postToUpdate;
      }
      return likeNewState;
    default:
      return state;
  }
};

export default popupReducer;