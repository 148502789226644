export const en = {
    "internet": {
        'noInternet': 'No Internet',
        'popupOfflineMsg': 'Please check your internet connection and try again'
    },
    "common": {
        "label": {
            "tryAgain": "Try again"
        }
    },
    "invalidUrl":{
        "title": "Sorry, This page isn't available.",
        "description": "The link you followed may be broken, Or the page may have been removed. ",
        "linkLabel": "Go back to ENKRIP",
        "invalidUrl": "Invalid URL"
    }
}