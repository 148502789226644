import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import webchatReducers from "../Reducers";
import socialReducers from "Social/Reducers";
import mainAppReducer from "MainApp/Reducers";

const rootReducers = combineReducers({...webchatReducers, ...socialReducers, ...mainAppReducer});

const Store = createStore(rootReducers, applyMiddleware(thunkMiddleware));

export const getStoreState = (store) => {
    store = store || Store;
    return (store.getState && store.getState()) || {};
}

export default Store;
