import i18n from "MainApp/features/Languages/i18n";
import moment from "moment";

export const addZinUTCDateTime = (utcDateTime) => {
    const formatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
    if(!utcDateTime || !formatRegex.test(utcDateTime)) return utcDateTime;
    if(utcDateTime.charAt(utcDateTime.length - 1) !== 'Z'){
        return `${utcDateTime}Z`;
    }
    return utcDateTime;
}

const getUserTimezoneOffset = () => {
    const currentDate = new Date();
    const timezoneOffset = currentDate.getTimezoneOffset();
    return timezoneOffset;
}

/**
 * Convert the UTC string into 2018-01-18T21:39:45.000Z format
 * @param {*} utcString 
 * @returns 
 */
export const convertUTCStrToValidFormat = (utcString) => {
    if (!utcString) return utcString;
    const utcDate = new Date(utcString);
    return utcDate.toISOString();
}

export const getLocalDatePartsFromUTC = (utcDatetimeString) => {
    if (!utcDatetimeString) return utcDatetimeString;
    // Create a new Date object from the UTC datetime string
    const utcDate = new Date(addZinUTCDateTime(utcDatetimeString));

    // Get the user's local date and time components
    const year = utcDate.getFullYear();
    const month = utcDate.getMonth(); // Month is zero-indexed (January = 0)
    const day = utcDate.getDate();

    // Format the date parts for user's locale
    const formatter = new Intl.DateTimeFormat([], {
        day: 'numeric',
        month: 'long', // Full month name
        year: 'numeric'
    });

    const formattedDate = formatter.format(utcDate);

    return formattedDate;
}

export const calculateTimeAgo = (utcDateTime, currentUtcDateTime, shorthandText=false) => {
    const currentTime = currentUtcDateTime ? new Date(convertUTCStrToValidFormat(currentUtcDateTime)) : new Date();
    const localDateTime = new Date(addZinUTCDateTime(utcDateTime));

    // Calculate the difference in milliseconds
    const timeDifference = currentTime - localDateTime;

    // Convert milliseconds to seconds, minutes, hours, days, and years
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 28);
    const years = Math.floor(days / 365);

    // Return the appropriate time format
    let time = !shorthandText ? i18n.t("common.label.justNow") : i18n.t("common.label.now");

    if (years > 0) {
        time = shorthandText ? `${years}y` : (years > 1 ? i18n.t("post.calculateTimeAgo.yearsAgo", {years}) : i18n.t("post.calculateTimeAgo.aYearAgo"));
    } else if (months > 0) {
        time = shorthandText ? `${months}m` : (months > 1 ? i18n.t("post.calculateTimeAgo.monthsAgo", {months}) : i18n.t("post.calculateTimeAgo.aMonthAgo"));
    } else if (weeks > 0) {
        time = shorthandText ? `${weeks}w` : (weeks > 1 ? i18n.t("post.calculateTimeAgo.weeksAgo", {weeks}) : i18n.t("post.calculateTimeAgo.aWeekAgo"));
    } else if (days > 0) {
        if (days === 1 && !shorthandText) {
            time = i18n.t("post.calculateTimeAgo.yesterday");
        } else if (days < 7) {
            time = `${!shorthandText ? i18n.t("post.calculateTimeAgo.daysAgo") : 'd'}`;
            time = `${days}${time}`;
        }
    } else if (hours > 0) {
        time = shorthandText ? `${hours}h` : (hours > 1 ? i18n.t("post.calculateTimeAgo.hoursAgo", {hours}) : i18n.t("post.calculateTimeAgo.anHourAgo"));
    } else if (minutes > 0) {
        time = shorthandText ? `${minutes}m` : (minutes > 1 ? i18n.t("post.calculateTimeAgo.minutesAgo", {minutes}) : i18n.t("post.calculateTimeAgo.aMinuteAgo"));
    } else if(seconds > 1){
        time = !shorthandText ? i18n.t("common.label.justNow") : i18n.t("common.label.now");
    }

    return { time, date: getLocalDatePartsFromUTC(utcDateTime) }
};

export const getUTCMilliseconds = () => {
    // Create a new Date object with UTC time
    const utcDate = new Date();
    utcDate.setUTCFullYear(utcDate.getFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
    utcDate.setUTCHours(utcDate.getUTCHours(), utcDate.getUTCMinutes(), utcDate.getUTCSeconds(), utcDate.getUTCMilliseconds());

    // Get the UTC timestamp in milliseconds
    return utcDate.getTime();
}

export const getUtcDateTimeString = () => {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // Add leading zero for single-digit months
    const day = String(now.getUTCDate()).padStart(2, '0'); // Add leading zero for single-digit days
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}

export const convertDateToDDMMYYYY = (dateString) => {
    // Create a JavaScript Date object from the input string
    const dateObj = new Date(dateString);

    // If the date is invalid, return an empty string
    if (isNaN(dateObj.getTime())) {
        return '';
    }

    // Extract day, month, and year components
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear().toString();   


    // Combine components into DD-MM-YYYY format
    return `${day}-${month}-${year}`;
}

export const detectDateFormat = (dateString) => {

    const formats = [
        'DD-MM-YYYY',
        'YYYY-MM-DD'
    ];
    
      for (const format of formats) {
        if (moment(dateString, format, true).isValid()) {
          return format;
        }
      }
    // If no format matches, return an empty string
    return null;
}