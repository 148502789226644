import { MUTUAL_CONTACT_DATA_LIST } from '../Actions/Constants';

const MUTUAL_CONTACT_LIST_DEFAULT_STATE = {
    id: null,
    contacts: [],
    pageNumber:1
  }

export function MutualContacList(state = MUTUAL_CONTACT_LIST_DEFAULT_STATE, action = {}) {
 
  switch (action.type) {
    case MUTUAL_CONTACT_DATA_LIST:
      return {
        ...state,
        contacts: action.payload.contacts,
        pageNumber: action.payload.pageNumber,
      };
    default:
      return state;
  }
}
