import { OPEN_SOCIAL_POPUP, CLOSE_SOCIAL_POPUP, OPEN_OVERLAY_SOCIAL_POPUP, CLOSE_OVERLAY_SOCIAL_POPUP, OPEN_NESTED_SOCIAL_POPUP } from './actionTypes';

export const openSocialPopup = (type, data) => ({
  type: OPEN_SOCIAL_POPUP,
  payload: { type, data }
});

export const closeSocialPopup = () => ({
  type: CLOSE_SOCIAL_POPUP
});

/**
 * Show sub content of opened popup. Like opening next popup 
 * (Eg: create post - select media -> crop media -> previe media -> ...)
 * 
 * @param {*} type 
 */
export const openNestedSocialPopup = (type) => ({
  type: OPEN_NESTED_SOCIAL_POPUP,
  payload: {
    type
  }
})

/**
 * open another popup on top of an existing popup
 * @param {*} type - Popup type
 * @param {*} data - Needed data in popup screen
 * @param {*} overlayLevel - Popup level, 1, 2 or 3rd level popup
 */
export const openOverlaySocialPopup = (type, data) => ({
  type: OPEN_OVERLAY_SOCIAL_POPUP,
  payload: {
    isOpen: true,
    type,
    data
  }
});

export const closeOverlaySocialPopup = () => ({
  type: CLOSE_OVERLAY_SOCIAL_POPUP
})