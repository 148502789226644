import Store from "Store";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "./constants";

export function toState(stateful) {
    if (stateful) {
        if (typeof stateful === 'function') {
            return stateful();
        }

        const { getState } = stateful;

        if (typeof getState === 'function') {
            return getState();
        }
    }

    return Store.getState();
}

export const isActionIdle = (loading) => ACTION_IDLE === loading;
export const isActionPending = (loading) => ACTION_PENDING === loading;
export const isActionSucceeded = (loading) => ACTION_SUCCEEDED === loading;
export const isActionFailed = (loading) => ACTION_FAILED === loading;