import { chatTagAction } from "Actions/ChatTagAction";
import SDK from "Components/SDK";
import { renderDisplayName } from "Helpers/Chat/RecentChat";
import { getContactNameFromRosterUpdate } from "Helpers/Chat/User";
import { getHighlightedText } from "Helpers/Utility";
import i18n from "MainApp/features/Languages/i18n";
import Store from "Store";
import { toState } from "Store/helpers";
import toastr from "toastr";

const hasDuplicateName = (formData) => {
    const chatTags = toState()?.chatTags?.data || [];
    let nameExists = false;
    if(formData.chatTagId){
        nameExists = chatTags.some((tag) => tag.chatTagId !== formData.chatTagId && tag.name === formData.name);
    }
    else{
        nameExists = chatTags.some((tag) => tag.name === formData.name);
    }
    return nameExists;
}

export const validateForm = (formData, showToast = true) => {
    const newErrors = {};

    if (!formData.name.trim()){
        newErrors.name = i18n.t('chatTag.createTag.validation.tagNameEmpty');
    }

    const duplicateName = hasDuplicateName(formData);
    if(formData.name.trim() && duplicateName){
        newErrors.name = i18n.t('chatTag.createTag.validation.tagNameDuplicate');
    }

    if(formData.name.trim() && !duplicateName && !formData.taggedUsers){
        newErrors.selectedChats = i18n.t('chatTag.createTag.validation.selectChatsEmpty');
        if(showToast){
            toastr.clear();
            toastr.error(newErrors.selectedChats, '', {positionClass: "toast-top-left"});
        }
    }

    return {isValid: Object.keys(newErrors).length === 0, errors: newErrors }; // Return true if no errors
};

export const countWordOccurrences = (text, word) => {
    const regex = new RegExp(`\\b${word}\\b`, 'gi'); // Word boundary for exact matches
    const matches = text.match(regex);
    return matches ? matches.length : 0; 
}

export const hasSubstring = (mainString, searchString) => mainString.includes(searchString);

export const removePartOfString = (mainString, stringToRemove) => {
    const regex = new RegExp(`,?${stringToRemove},?`); // Matches the string and optional commas
    return mainString.replace(regex, ",").replace(/^,|,$/g, ""); // Remove extra commas if any
}

export const appendString = (mainString, newString) => (mainString ? mainString + "," : "") + newString;

export const getUnMappedChatId = (modifiedChatId, existingChatId) => {
    const modifiedChatIdArr = modifiedChatId ? modifiedChatId.split(",") : [];
    const existingChatIdArr = existingChatId ? existingChatId.split(",") : [];
    return (existingChatIdArr.filter(item => !modifiedChatIdArr.includes(item))).join();
}

export const getChatTags = async() => {
    const chatTags = await SDK.getChatTags();
    if(chatTags?.status === 200 || chatTags?.status === 204){
        Store.dispatch(chatTagAction(chatTags.data));
    }
}

export const getChatTagChatDisplayName = (roster, fromUserId, searchTerm) => {
    const { consolidatedName: contactName,  registeredName } = getContactNameFromRosterUpdate(roster, true);
    const hightlightText = contactName ? getHighlightedText(contactName, searchTerm) : fromUserId;
    return renderDisplayName(hightlightText, registeredName)
}

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

export const sortedChatTags = (chatTags, chatTagsIdOrder) => {
    return chatTags.sort((tagA, tagB) => {
        const indexA = chatTagsIdOrder.indexOf(tagA.chatTagId);
        const indexB = chatTagsIdOrder.indexOf(tagB.chatTagId);
    
        // Handle cases where a chatTagId might not be in the order array
        if (indexA === -1) return 1; // Put tagA at the end
        if (indexB === -1) return -1; // Put tagB at the end
    
        return indexA - indexB; // Sort based on position in chatTagsIdOrder
    })

};

export const hasChatTagsData = (chatTags) => !!(Array.isArray(chatTags) && chatTags?.length > 0)