import { isObject } from "MainApp/features/Helpers/Object";
import ApiService from "../Api/ApiService";
import { encryptAndStoreInLocalStorage, getFromLocalStorageAndDecrypt } from "Components/WebChat/WebChatEncryptDecrypt";
import { LoginService } from "../Login/LoginService";

const SettingsService = {
    fetchSettings: () => {
        if(!LoginService.isUserLoggedIn()) return;
        const apiService = new ApiService();
        apiService.get('users/settings')
        .then((res) => {
            if(res?.status === 200 && res?.data){
                const existingSettings = SettingsService.getLocalSettings() || {};
                const data = isObject(res?.data) ? res?.data : {};
                
                encryptAndStoreInLocalStorage('settings_social', {...existingSettings, ...data})
            }
        })
        .catch((err) => {});
    },
    getLocalSettings: () => {
        return getFromLocalStorageAndDecrypt("settings_social");
    }
}

export default SettingsService;