import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createPostComment, fetchPostComments, postCommentDelete, postCommentLike } from "./actions";
import { POST_COMMENTS_PER_PAGE } from "../constants";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { clearMapData, deleteMapDataById, formatDataArrayAsMap, setDataInMapByIndex } from "MainApp/features/Helpers/Map";
import { getNewCommentObj, updatePostCommentLike } from "../helpers";
import { fetchGuestPostComments } from "Social/features/Shared/actions";
import { PAGE_TYPE } from "MainApp/features/App/constants";
import { LoginService } from "MainApp/Services/Login/LoginService";

export const POST_COMMENTS_CREATE = 'comments/create';
export const POST_COMMENTS_FETCH = 'comments/fetch';
export const POST_COMMENTS_LIKE = 'comments/like';
export const POST_COMMENTS_DELETE = 'comments/delete';

export const createPostCommentThunk = createAsyncThunk(
    POST_COMMENTS_CREATE, 
    async (userInputData, options) => {
        return await createPostComment(userInputData, options)
    }
)

export const fetchPostCommentsThunk = createAsyncThunk(
    POST_COMMENTS_FETCH,
    async (userInputData, options) => LoginService.isUserLoggedIn() ? await fetchPostComments(userInputData, options) : await fetchGuestPostComments(userInputData, options)
)

export const postCommentLikeThunk = createAsyncThunk(
    POST_COMMENTS_LIKE,
    async (userInputData, options) => await postCommentLike(userInputData, options)
)

export const postCommentDeleteThunk = createAsyncThunk(
    POST_COMMENTS_DELETE,
    async (userInputData, options) => await postCommentDelete(userInputData, options)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: new Map(),
    count: POST_COMMENTS_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1
}

const postCommentsSlice = createSlice({
    name: 'postComments',
    initialState,
    reducers: {
        resetPostComments(state){
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createPostCommentThunk.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
            state.actionType = POST_COMMENTS_CREATE;
        })
        .addCase(createPostCommentThunk.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.actionType = POST_COMMENTS_CREATE;
            state.userInputData = payload.userInputData;
            if(payload?.userInputData?.comments && payload?.data?.commentId){
                const newComment = getNewCommentObj({comments: payload?.userInputData?.comments, commentId: payload?.data?.commentId});
                state.data = setDataInMapByIndex(state.data, payload?.data?.commentId, newComment);
            }
        })
        .addCase(createPostCommentThunk.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.actionType = POST_COMMENTS_CREATE;
            state.userInputData = payload.userInputData;
            state.error = payload.error;
        })
        .addCase(fetchPostCommentsThunk.pending, (state) => {
            state.loading = ACTION_PENDING;
            state.actionType = POST_COMMENTS_FETCH;
        })
        .addCase(fetchPostCommentsThunk.fulfilled, (state, { payload }) => {
            state.actionType = POST_COMMENTS_FETCH;
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            //Clear the old data If call is initiated for page 1
            if(payload.userInputData.pageNumber === 1){
                state.data = clearMapData(state.data);
            }
            const { userInputData, count, data, totalRecords } = payload;
            if(data){
                state.data = formatDataArrayAsMap('commentId', data, state.data);
                state.count = count || state.count;
                state.totalRecords = totalRecords || state.totalRecords;
                state.pageNumber = userInputData.pageNumber;
            }
        })
        .addCase(fetchPostCommentsThunk.rejected, (state, { payload }) => {
            state.actionType = POST_COMMENTS_FETCH;
            state.loading = ACTION_FAILED;
            state.userInputData = payload.userInputData;
            state.error = payload.error;
        })
        .addCase(postCommentLikeThunk.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
            state.actionType = POST_COMMENTS_LIKE;
        })
        .addCase(postCommentLikeThunk.fulfilled, (state, { payload }) => {
            state.actionType = POST_COMMENTS_LIKE;
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            state.data = updatePostCommentLike(state.data, payload.userInputData);
        })
        .addCase(postCommentLikeThunk.rejected, (state, { payload }) => {
            state.actionType = POST_COMMENTS_LIKE;
            state.loading = ACTION_FAILED;
            state.userInputData = payload.userInputData;
            state.error = payload.error;
        })
        .addCase(postCommentDeleteThunk.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
            state.actionType = POST_COMMENTS_DELETE;
        })
        .addCase(postCommentDeleteThunk.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.actionType = POST_COMMENTS_DELETE;
            state.userInputData = payload.userInputData;
            state.data = deleteMapDataById(state.data, payload.userInputData.commentId);
        })
        .addCase(postCommentDeleteThunk.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.actionType = POST_COMMENTS_DELETE;
            state.userInputData = payload.userInputData;
            state.error = payload.error;
        })
    }
});

export const { resetPostComments } = postCommentsSlice.actions

export default postCommentsSlice.reducer;