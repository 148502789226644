import { clearMapData, formatDataArrayAsMap } from "MainApp/features/Helpers/Map";
import { POST_COMMENT_LIKED_USERS_PER_PAGE } from "../constants";
import { FETCH_POST_COMMENT_LIKED_USERS_FAILURE, FETCH_POST_COMMENT_LIKED_USERS_REQUEST, FETCH_POST_COMMENT_LIKED_USERS_RESET, FETCH_POST_COMMENT_LIKED_USERS_SUCCESS } from "./actionTypes";

const postCommentLikedUsersInitState = {
    isPending: false,
    error: null,
    data: new Map(),
    count: POST_COMMENT_LIKED_USERS_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1
}

export const fetchPostCommentLikedUsersReducer = (state=postCommentLikedUsersInitState, action = {}) => {
    const { type, payload = {} } = action;
    switch(type){
        case FETCH_POST_COMMENT_LIKED_USERS_REQUEST:
            return {
                ...state,
                isPending: true
            }
        case FETCH_POST_COMMENT_LIKED_USERS_SUCCESS:
            return {
                ...state,
                isPending: false,
                error: null,
                ...payload,
                data: formatDataArrayAsMap("userId", payload.data, state.data)
            }
        case FETCH_POST_COMMENT_LIKED_USERS_FAILURE:
            return {
                ...state,
                isPending: false,
                ...payload
            }
        case FETCH_POST_COMMENT_LIKED_USERS_RESET:
            const resetNewState = { ...state };
            return {...postCommentLikedUsersInitState, data: clearMapData(resetNewState.data) };
        default:
            return state;
    }
}