import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearMapData, formatDataArrayAsMap } from "MainApp/features/Helpers/Map";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { SUGGESTED_USERS_PER_PAGE } from "./constants";
import { fetchSuggestedUsers, removeSuggestedUsers } from "./actions";
import toastr from 'toastr';


export const FETCH_SUGGESTED_USERS = 'fetch/suggested/users';
export const REMOVE_SUGGESTED_USERS = 'remove/suggested/users';

export const fetchSuggestedUsersAction = createAsyncThunk(
    FETCH_SUGGESTED_USERS, 
    async (userInputData, thunkAPI) => await fetchSuggestedUsers(userInputData, thunkAPI)
)

export const removeSuggestedUsersAction = createAsyncThunk(
    REMOVE_SUGGESTED_USERS, 
    async (userInputData, thunkAPI) => await removeSuggestedUsers(userInputData, thunkAPI)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: new Map(),
    initData: new Map(),
    count: SUGGESTED_USERS_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1,
    actionType: FETCH_SUGGESTED_USERS
}

const suggestedUsersSlice = createSlice({
    name: 'suggestedUsers',
    initialState,
    reducers: {
        resetSuggestedUsers(state){
            const newStateToUpdate = {...initialState};
            newStateToUpdate.initData = state.initData;
            return newStateToUpdate;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSuggestedUsersAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
            state.actionType = FETCH_SUGGESTED_USERS;
        })
        .addCase(fetchSuggestedUsersAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            state.actionType = FETCH_SUGGESTED_USERS;
            //Clear the old data If call is initiated for page 1
            if(payload.userInputData.pageNumber === 1){
                state.data = clearMapData(state.data);
            }

            if(payload.userInputData.pageNumber === 1 && !payload.userInputData.search){
                state.initData = clearMapData(state.initData);
            }

            const { userInputData, count, data, totalRecords } = payload;
            if(Array.isArray(data)){
                if(payload.userInputData.pageNumber === 1 && !payload.userInputData.search){
                    state.initData = formatDataArrayAsMap('userId', [...data], state.initData);
                }
                state.data = formatDataArrayAsMap('userId', data, state.data);
                state.count = count || state.count;
                state.totalRecords = totalRecords || state.totalRecords;
                state.pageNumber = userInputData.pageNumber;
            }
        })
        .addCase(fetchSuggestedUsersAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.userInputData = payload.userInputData;
            state.actionType = FETCH_SUGGESTED_USERS;
        })
        .addCase(removeSuggestedUsersAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
            state.actionType = REMOVE_SUGGESTED_USERS;
        })
        .addCase(removeSuggestedUsersAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            state.actionType = REMOVE_SUGGESTED_USERS;
            toastr.success(payload.message);
        })
        .addCase(removeSuggestedUsersAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.userInputData = payload.userInputData;
            state.actionType = REMOVE_SUGGESTED_USERS;
        })
    }
});

export const { resetSuggestedUsers } = suggestedUsersSlice.actions;
export default suggestedUsersSlice.reducer;