import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { profileImageUpload, updateProfile } from "./actions";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";

export const updateProfileAction = createAsyncThunk(
    'update/user/profile', 
    async (userInputData, thunkAPI) => await updateProfile(userInputData, thunkAPI)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null
}

const updateProfileSlice = createSlice({
    name: 'updateProfile',
    initialState,
    reducers: {
        resetUpdateProfile(state){
            return initialState;
        },
        requestUpdateProfile(state){
            state.loading = ACTION_PENDING;
        },
        failedUpdateProfile(state){
            state.loading = ACTION_FAILED;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateProfileAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(updateProfileAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
        })
        .addCase(updateProfileAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export const { resetUpdateProfile, requestUpdateProfile, failedUpdateProfile} = updateProfileSlice.actions;

export const updateProfileReducer = updateProfileSlice.reducer;

export const profileImageUploadAction = createAsyncThunk(
    'update/profile/image/upload',
    async (userInputData, thunkAPI) => await profileImageUpload(userInputData, thunkAPI)
)

const profileImageInitState = {
    loading: ACTION_IDLE,
    error: null
}

const profileImageUploadSlice = createSlice({
    name: 'profileImageUpload',
    initialState: profileImageInitState,
    reducers: {
        resetProfileImageUpload(state){
            return profileImageInitState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(profileImageUploadAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(profileImageUploadAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
        })
        .addCase(profileImageUploadAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export const { resetProfileImageUpload } = profileImageUploadSlice.actions;
export const profileImageUploadReducer = profileImageUploadSlice.reducer;