import { clearMapData, deleteMapDataById, isMapDataHasRecord, setDataInMapByIndex } from "MainApp/features/Helpers/Map";
import { UPDATE_FOLLOW_STATE_TO_STORE_USER } from "../Follow/actionTypes";
import { UPDATE_LIKE_STATE_TO_STORE_POST } from "../PostLike/actionTypes";
import { POSTS_LIST_API_DATETIME,FETCH_POSTS_FAILURE,FETCH_POSTS_REQUEST,FETCH_POSTS_SUCCESS,FETCH_POSTS_RESET,DELETE_POST_REQUEST,DELETE_POST_SUCCESS,DELETE_POST_FAILURE,DELETE_POST_RESET, FETCH_POSTS_DELETE_BY_POST_ID} from "./actionTypes";
import { POSTS_PER_PAGE } from "./constants";
import { formatPostsArrayAsMap,getPostById,updatePostById,updatePostLikeCount,updatePostUserFollowState } from "./helpers";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchPostByPostId, deletePostByPostId } from "./actions";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";

const fetchPostsInitState = {
  count: POSTS_PER_PAGE,
  data: new Map(),
  totalRecords: 0,
  isFetching: false,
  error: null,
  pageNumber: 1,
  fetchAfterCreatePost: false
};

export const fetchPostsReducer = (state = fetchPostsInitState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_POSTS_REQUEST:
      return {
        ...state,
        error: null,
        ...payload
      };
    case FETCH_POSTS_SUCCESS:
      let newData = state.data;
      if (payload.fetchAfterCreatePost) {
        // Handle after post new content, should display the new content as first post.
        if (
          payload?.data?.[0]?.posts.id &&
          !isMapDataHasRecord(state.data, payload?.data?.[0]?.posts.id) &&
          Array.isArray(payload?.data?.[0]?.posts?.files) &&
          payload?.data?.[0]?.posts?.files?.length > 0 &&
          payload?.data?.[0]?.postedUser
        ) {
          payload.data[0].posts.files = payload.data[0].posts.files.filter((file) => file);
          if (payload.data[0].posts.files.length > 0) {
            newData = setDataInMapByIndex(state.data, payload?.data?.[0]?.posts.id, payload?.data?.[0]);
          }
        }
      } else {
        //Clear the old data If call is initiated for page 1
        if(payload?.pageNumber === 1){
          clearMapData(state.data);
        }
        newData = formatPostsArrayAsMap(state.data, payload.data);
      }
      return {
        ...state,
        isFetching: false,
        error: null,
        ...payload,
        data: newData
      };
    case FETCH_POSTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        ...payload
      };
    case FETCH_POSTS_RESET:
      const newState = { ...state };
      newState?.data?.clear();
      return { ...fetchPostsInitState, data: newState.data };
    case UPDATE_LIKE_STATE_TO_STORE_POST:
      const post = getPostById({ ...state }, payload.postId);
      if (post) {
        const postToUpdate = updatePostLikeCount(post, payload);
        return updatePostById({ ...state }, payload.postId, postToUpdate);
      }
      return state;
    case UPDATE_FOLLOW_STATE_TO_STORE_USER:
      if (payload.postId) {
        const post = getPostById({ ...state }, payload.postId);
        if (post) {
          const postToUpdate = updatePostUserFollowState(post, payload);
          return updatePostById({ ...state }, payload.postId, postToUpdate);
        }
      }
      return state;
    case FETCH_POSTS_DELETE_BY_POST_ID:
      return {
        ...state,
        data: deleteMapDataById(state.data, payload?.postId)
      }
    default:
      return state;
  }
};

const deletePostInitState = {
  data: null,
  error: null,
  fetchAfterDeletePost: false,
  loading: ACTION_IDLE
};

export const deletePostReducer = (state = deletePostInitState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DELETE_POST_REQUEST:
      return {
        ...state,
        error: null,
        ...payload,
        fetchAfterDeletePost: false,
        loading: ACTION_PENDING
      };
    case DELETE_POST_SUCCESS:
      let updatedData = payload?.userInputData?.postId;
      state.data = updatedData;
      state.fetchAfterDeletePost = true;
      return {
        ...state,
        data: updatedData,
        loading: ACTION_SUCCEEDED,
        userInputData: payload?.userInputData
      };
    case DELETE_POST_FAILURE:
      return {
        ...state,
        fetchAfterDeletePost: true,
        ...payload,
        loading: ACTION_FAILED
      };
    case DELETE_POST_RESET:
      return {...deletePostInitState};
    default:
      return state;
  }
};

const listapiDatetimeInitState = {
  dateTimeString: new Date()
};

export const postsListApiDatetimeReducer = (state = listapiDatetimeInitState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case POSTS_LIST_API_DATETIME:
      return {
        dateTimeString: payload?.datetimeString
      };
    default:
      return state;
  }
};

export const fetchPostByPostIdAction = createAsyncThunk(
  "fetch/post/by/postId",
  async (userInputData, options) => await fetchPostByPostId(userInputData, options)
);

const initialState = {
  loading: ACTION_IDLE,
  error: null,
  data: null
};

const fetchPostByPostIdSlice = createSlice({
  name: "fetchPostByPostId",
  initialState,
  reducers: {
    resetUserPost() {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPostByPostIdAction.pending, (state, { payload }) => {
        state.loading = ACTION_PENDING;
      })
      .addCase(fetchPostByPostIdAction.fulfilled, (state, { payload }) => {
        state.loading = ACTION_SUCCEEDED;
        state.data = payload?.data?.[0] || null;
      })
      .addCase(fetchPostByPostIdAction.rejected, (state, { payload }) => {
        state.loading = ACTION_FAILED;
        state.error = payload.error;
        state.userInputData = payload.userInputData;
      })
      .addMatcher(
        (action) => UPDATE_LIKE_STATE_TO_STORE_POST === action.type,
        (state, action) => {
          // Update state based on any action from otherReducer
          if(action?.payload?.postId === state?.data?.posts?.id){
            const post = state.data;
            const postToUpdate = updatePostLikeCount(post, action.payload);
            state.data = postToUpdate;
          } 
          return state;
        }
      );
  }
});

export const { resetUserPost } = fetchPostByPostIdSlice.actions;
export const fetchPostByPostIdReducer = fetchPostByPostIdSlice.reducer;

const initialStateDelete = {
  loading: ACTION_IDLE,
  error: null,
  data: {}
};

const videoPlayerOptionSlice = createSlice({
  name: "videoPlayerOption",
  initialState: {
    mute: true
  },
  reducers: {
    toggleVideoMute(state) {
      state.mute = !state.mute;
    }
  }
});

export const { toggleVideoMute } = videoPlayerOptionSlice.actions;
export const videoPlayerOptionReducer = videoPlayerOptionSlice.reducer;
