import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { closeSocialPopup, isGivenSocialPopupOpen, openInfoPopup } from "../Popup/helpers";
import { resetPostComments } from "../PostComments/Comments/commentsSlice";
import { deletePostReset, fetchPostsDeleteByPostId } from "../Post/actions";
import { PAGE_TYPE } from "MainApp/features/App/constants";
import { deleteUserPostsByPostId } from "../Profile/userSlice";
import { fetchPostLikedUsersReset, togglePostLikeReset } from "../PostLike/actions";
import { fetchPostCommentLikedUsersReset } from "../PostComments/Comments/actions";
import { resetPostCommentReplies } from "../PostComments/Replies/repliesSlice";
import { resetPostCommentReplyLikedUsers } from "../PostComments/Replies/replyLikedUsersSlice";
import { deletePostByIdFromPostsArr } from "../Post/helpers";
import { isActionSucceeded } from "Store/helpers";
import { resetUserPost } from "../Post/reducer";
import { POPUP_COMPONENT_TYPES } from "../Popup/constants";
import { useTranslation } from "react-i18next";

export const DELETED_POST_STATUS = 410;
export const DELETED_POST_COMMENT_STATUS = 424;
export const useDeletedPostActionHandler = (pageType, postsArr = []) => {
    const dispatch = useDispatch();
    const postComments = useSelector((state) => state.postComments);
    const deletePost = useSelector((state) => state.deletePost);
    const togglePostLike = useSelector((state) => state.togglePostLike);
    const postLikedUsers = useSelector((state) => state.postLikedUsers);
    const postCommentLikedUsers = useSelector((state) => state.postCommentLikedUsers);
    const postCommentReplies = useSelector((state) => state.postCommentReplies);
    const postCommentReplyLikedUsers = useSelector((state) => state.postCommentReplyLikedUsers);
    const post = useSelector((state) => state.post);
    const [updatedPosts, setUpdatedPosts] = useState(null);
    const [hasChangeOccurred, setHasChangeOccurred] = useState(null);

    const showPostDeleteInfo = (storeData, dispatchAction, showInfoPopup = true) => {
        let postId = null;
        if(storeData){
            postId = storeData?.userInputData?.postId
        }

        if(dispatchAction){
            dispatch(dispatchAction());
        }

        if(postId){
            const payload = {postId};
            if(pageType === PAGE_TYPE.profile){
                dispatch(deleteUserPostsByPostId(payload));
                setHasChangeOccurred((new Date()).getTime());
            }

            if(pageType === PAGE_TYPE.home){
                dispatch(fetchPostsDeleteByPostId(payload));
            }

            if(pageType === PAGE_TYPE.globalSearch || pageType === PAGE_TYPE.explore){
                const posts = deletePostByIdFromPostsArr(postsArr, postId);
                setUpdatedPosts(posts);
            }
        }

        if(showInfoPopup){
            openInfoPopup({
                title: storeData?.error?.message,
                action: () =>{
                    closeSocialPopup(true);
                }
            });
        }
    }

    useEffect(() => {
        
        if(isActionSucceeded(deletePost.loading)){
            showPostDeleteInfo(deletePost, undefined, false);
        }

        if(deletePost?.error?.status === DELETED_POST_STATUS){
            showPostDeleteInfo(deletePost, deletePostReset);
        }

        if(postComments?.error?.status === DELETED_POST_STATUS){
            showPostDeleteInfo(postComments, resetPostComments);
        }

        if(togglePostLike?.error?.status === DELETED_POST_STATUS){
            showPostDeleteInfo(togglePostLike, togglePostLikeReset);
        }

        if(postLikedUsers?.error?.status === DELETED_POST_STATUS){
            showPostDeleteInfo(postLikedUsers, fetchPostLikedUsersReset);
        }

        if(postCommentLikedUsers?.error?.status === DELETED_POST_STATUS){
            showPostDeleteInfo(postCommentLikedUsers, fetchPostCommentLikedUsersReset);
        }

        if(postCommentReplies?.error?.status === DELETED_POST_STATUS){
            showPostDeleteInfo(postCommentReplies, resetPostCommentReplies);
        }

        if(postCommentReplyLikedUsers?.error?.status === DELETED_POST_STATUS){
            showPostDeleteInfo(postCommentReplyLikedUsers, resetPostCommentReplyLikedUsers);
        }

        if(post?.error?.status === DELETED_POST_STATUS){
            const intervalId = setInterval(() => {
                if(!isGivenSocialPopupOpen(POPUP_COMPONENT_TYPES.previewPost)){
                    clearInterval(intervalId);
                    showPostDeleteInfo(post, resetUserPost);
                }
            }, 100);
        }

    }, [postComments, deletePost, togglePostLike, postLikedUsers, postCommentLikedUsers, postCommentReplies, postCommentReplyLikedUsers, post]);

    return {hasChangeOccurred, updatedPosts};
}

export const useDeletedCommentActionHandler = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const postComments = useSelector((state) => state.postComments);
    const postCommentLikedUsers = useSelector((state) => state.postCommentLikedUsers);
    const postCommentReplies = useSelector((state) => state.postCommentReplies);
    const postCommentReplyLikedUsers = useSelector((state) => state.postCommentReplyLikedUsers);
    const [hasChangeOccurred, setHasChangeOccurred] = useState(null);

    const showPostDeleteInfo = (storeData) => {

        if(storeData?.userInputData?.postId){
            dispatch(resetPostComments());
            dispatch(fetchPostCommentLikedUsersReset());
            dispatch(resetPostCommentReplies());
            dispatch(resetPostCommentReplyLikedUsers());
            setHasChangeOccurred((new Date()).getTime());
        }

        openInfoPopup({
            title: storeData?.error?.message || t('post.postCommentRemovedByOwnerMsg'),
            action: () =>{
                closeSocialPopup();
            }
        });
    }

    useEffect(() => {
        if(postComments?.error?.status === DELETED_POST_COMMENT_STATUS){
            showPostDeleteInfo(postComments);
        }

        if(postCommentLikedUsers?.error?.status === DELETED_POST_COMMENT_STATUS){
            showPostDeleteInfo(postCommentLikedUsers);
        }

        if(postCommentReplies?.error?.status === DELETED_POST_COMMENT_STATUS){
            showPostDeleteInfo(postCommentReplies);
        }

        if(postCommentReplyLikedUsers?.error?.status === DELETED_POST_COMMENT_STATUS){
            showPostDeleteInfo(postCommentReplyLikedUsers);
        }
        
    }, [postComments, postCommentLikedUsers, postCommentReplies, postCommentReplyLikedUsers]);

    return {hasChangeOccurred};
}