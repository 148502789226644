import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMyAccoutProfileData, updateMyAccoutProfileData } from "./actions";


const GET_MY_ACCOUNT_PROFILE = '/users/profile';
export const UPDATE_MY_ACCOUNT_PROFILE = 'update/users/profile';

export const getMyAccoutProfileDataAction = createAsyncThunk(
    GET_MY_ACCOUNT_PROFILE,
    async (userInputData, options) => await getMyAccoutProfileData(userInputData, options)
)

export const updateMyAccoutProfileDataAction = createAsyncThunk(
    UPDATE_MY_ACCOUNT_PROFILE,
    async (userInputData, options) => await updateMyAccoutProfileData(userInputData, options)
)


const myAccountDataInitState = {
    loading: ACTION_FAILED,
    error: null,
    data: {},
    actionType: ''
}

const myAccountProfileDataSlice = createSlice({
    name: 'interestData',
    initialState: myAccountDataInitState,
    reducers:{
        resetMyAccountprofileData(state){
            return myAccountDataInitState
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getMyAccoutProfileDataAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(getMyAccoutProfileDataAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.data = payload.data;
            state.actionType = "";
        })
        .addCase(getMyAccoutProfileDataAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
        .addCase(updateMyAccoutProfileDataAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(updateMyAccoutProfileDataAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.data = payload.data;
            state.actionType = UPDATE_MY_ACCOUNT_PROFILE;
        })
        .addCase(updateMyAccoutProfileDataAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }

})

export const { resetMyAccountprofileData } = myAccountProfileDataSlice.actions;

export const settingMyAccountDataReducer = myAccountProfileDataSlice.reducer;