export const isObject = (data) => {
  return data && typeof data === 'object' && !Array.isArray(data);
}

export const deepMerge = (target, ...sources) => {
  
    const merger = (obj, source) => {
      for (const key of Object.keys(source)) {
        const targetValue = obj[key];
        const sourceValue = source[key];
  
        if (isObject(targetValue) && isObject(sourceValue)) {
          // Recursive merge for nested objects
          Object.assign(targetValue, deepMerge(targetValue, sourceValue));
        } else if (sourceValue !== undefined) {
          // Replace target value with source value (excluding undefined)
          obj[key] = sourceValue;
        }
      }
    };
  
    // Merge source objects into target
    target = target || {};
    sources.forEach(source => merger(target, source));
  
    return target;
}