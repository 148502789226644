import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en as webchatEn } from 'Languages/Locales/en';
import { id as webchatId } from 'Languages/Locales/id';
import { en as socialAppEn } from 'Social/features/Languages/Locales/en';
import { id as socialAppId } from 'Social/features/Languages/Locales/id';
import { en as mainAppEn } from 'MainApp/features/Languages/Locales/en';
import { id as mainAppId } from 'MainApp/features/Languages/Locales/id';
import { deepMerge } from '../Helpers/Object';
import Store from 'Store';
import { updateUserLanguage } from 'Actions/LanguageAction';

const en = deepMerge({}, webchatEn, socialAppEn, mainAppEn);
const id = deepMerge({}, webchatId, socialAppId, mainAppId);

const SUPPORTED_LANG = ['en', 'id'];
export const DEFAULT_LANGUAGE = SUPPORTED_LANG[0];

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format, lng) {
        if(this?.language?.includes('en')){
          if (format === 'uppercase') return value.toUpperCase();
          if (format === 'lowercase') return value.toLowerCase();
          if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        }
        return value;
      }
    },
    resources: {
      en: {
        translation: en
      },
      id: {
        translation: id
      }
    },
    supportedLngs: SUPPORTED_LANG
  });

export default i18n;

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

// Convert the given string to localisation language key format
export const convertToLocalizeKey = (str) => {
    return str.replace(/-/g, '_').replace(/\s+/g, '').toLowerCase();
}

export const changeLanguage = (language, onSuccessCallback) => {
  // Check if the language is valid and supported  
  if (i18n.isInitialized && SUPPORTED_LANG.includes(language)) {
    i18n.changeLanguage(language, (err) => {
      if (err) {
        // console.error('Error changing language:', err);
      } else {
        // Language changed successfully, call the onSuccessCallback
        if(typeof onSuccessCallback === 'function'){
          onSuccessCallback(language);
        }
        localStorage.setItem("i18nextLng", language);
        Store.dispatch(updateUserLanguage({ language }));
      }
    });
  } else {
    // console.error('Invalid or unsupported language:', language);
  }
}

export const getSelectedLang = () => {
  return i18n.language == "en" || i18n.language == "en-GB" ? "en" : "id";
}