import { encryptAndStoreInLocalStorage, getFromLocalStorageAndDecrypt } from "Components/WebChat/WebChatEncryptDecrypt";
import { REACT_APP_WEBAPP_DEVICE_TYPE } from "MainApp/features/App/constants";

export const getLoginObj = (data) => {
    return {
        userId: data?.userId,
        password: data?.password,
        deviceId: data?.deviceId,
        deviceType: REACT_APP_WEBAPP_DEVICE_TYPE
    }
}

export const getAuthUserDetails = () => {
    return getFromLocalStorageAndDecrypt("auth_user_social");
}

export const storeAuthUserInLocalStorage = (data) => {
    const authUser = getAuthUserDetails() || {};
    const authUserToUpdate = { ...authUser, ...data };
    encryptAndStoreInLocalStorage("auth_user_social", {...authUserToUpdate});
    encryptAndStoreInLocalStorage("auth_user", { ...authUserToUpdate, username: authUserToUpdate.userId });
}

export const isLocalAuthUser = (userId) => {
    return userId === getAuthUserDetails()?.userId;
}