import { sliceActionHandler } from "MainApp/Services/Api/helpers";
import { HASH_TAGS_PER_PAGE } from "./contants";
import ApiService from "MainApp/Services/Api/ApiService";

export const fetchHashTags = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();

    const response = await sliceActionHandler(() => apiService.get('/post/hashTags', { page:userInputData.pageNumber, search: userInputData.search, size: userInputData.size || HASH_TAGS_PER_PAGE}), {
        userInputData,
        thunkAPI
    });
    return response;
}