import React, { Suspense } from 'react';
import ComingSoon from 'Social/features/ComingSoon/Components/ComingSoon';

const Login = React.lazy(() => import('MainApp/features/Login/Components/Login'));
const Home = React.lazy(() => import('Social/features/Home/Components/Home'));
const Webchat = React.lazy(() => import('Components/Layouts/Webchat'));
const InviteCode = React.lazy(() => import('Social/features/InviteCode/Components/InviteCode'));
const ViewSharedPost = React.lazy(() => import('Social/features/Shared/Post/Components/ViewSharedPost'));
const ViewSharedProfile = React.lazy(() => import('Social/features/Shared/Profile/Components/ViewSharedProfile'));
const ExploreMainPage = React.lazy(() => import('Social/features/Explore/Components/ExploreMainPage'));
const HashTagPosts = React.lazy(() => import('Social/features/HashTagPosts/Components/HashTagPosts'));
const Settings = React.lazy(() => import('Social/features/Settings/Components/Settings'));
const UserProfile = React.lazy(() => import('Social/features/Profile/Components/UserProfile'));
const GlobalSearchMainPage = React.lazy(() => import('Social/features/Global/Components/GlobalSearchMainPage'));

/** 
 * Object of all the route's path
 */
export const routesPath = {
    root: '/',
    createpost: '/createpost',
    login: '/login',
    webchat: '/webchat',
    explore: '/explore',
    saved: '/saved',
    settings: '/settings',
    profile: '/user/profile',
    inviteCode: '/invite/:userId/:name',
    hashTag: '/hashtag',
    globalSearch: '/globalsearch',
    sharedPost: '/post',
    sharedProfile: '/profile'
}

export const publicRoutes = [
    {
        path: routesPath.login,
        name: 'login',
        component: Login,
        icon: null,
        route: 'public'
    },
    {
        path: routesPath.inviteCode,
        name: 'invite',
        component: InviteCode,
        icon: null,
        route: 'public'
    },
    // {
    //     path: `${routesPath.sharedPost}/:postId`,
    //     name: 'sharedPost',
    //     component: ViewSharedPost,
    //     icon: null,
    //     route: 'public'
    // }
];

export const privateRoutes = [
    {
        path: `${routesPath.sharedPost}/:postId`,
        name: 'sharedPost',
        component: ViewSharedPost,
        icon: null,
        route: 'public'
    },
    {
        path: `${routesPath.sharedProfile}/:userId`,
        name: 'sharedProfile',
        component: ViewSharedProfile,
        icon: null,
        route: 'public'
    },
    {
        path: routesPath.root,
        name: '',
        icon: null,
        component: Home,
        exact: true,
        route: 'private'
    },
    {
        path: routesPath.createpost,
        name: '',
        icon: null,
        component: Home,
        exact: true,
        route: 'private'
    },
    {
        path: routesPath.explore,
        name: '',
        icon: null,
        component: ExploreMainPage,
        route: 'private'
    },
    {
        path: routesPath.saved,
        name: '',
        icon: null,
        component: ComingSoon,
        route: 'private'
    },
    {
        path: `${routesPath.settings}/:menuType?`,
        name: 'settings',
        icon: null,
        component: Settings,
        route: 'private'
    },
    {
        path: routesPath.webchat,
        name: 'webchat',
        icon: null,
        component: Webchat,
        route: 'private'
    },
    {
        path: `${routesPath.profile}/:userId?`,
        name: 'profile',
        icon: null,
        component: UserProfile,
        route: 'private'
    },
    {
        path: `${routesPath.hashTag}/:hashTagName`,
        name: 'hashTag',
        icon: null,
        component: HashTagPosts,
        route: 'private'
    },
    {
        path: `${routesPath.globalSearch}`,
        name: 'globalSearch',
        icon: null,
        component: GlobalSearchMainPage,
        route: 'private'
    },
    
];