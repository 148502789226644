import { RICH_TEXT_EDITOR_MAX_LENGTH } from "MainApp/features/App/constants";

export const MAX_MEDIA_FILES_ALLOWED = 10;
export const IMAGE_MAX_ALLOWED_SIZE_MB = 20; // In MB
export const VIDEO_MAX_ALLOWED_SIZE_MB = 5000; // In MB
export const VIDEO_MAX_ALLOWED_DURATION_SEC = 90; // In Seconds
export const IMAGE_MAX_ALLOWED_SIZE_BYTES = IMAGE_MAX_ALLOWED_SIZE_MB * 1024 * 1024; // In MB
export const VIDEO_MAX_ALLOWED_SIZE_BYTES = VIDEO_MAX_ALLOWED_SIZE_MB * 1024 * 1024; // In MB
export const CAPTION_MAXIMUM_LENGTH = RICH_TEXT_EDITOR_MAX_LENGTH; // In characters
export const IMAGE_ALLOWED_EXT = ['jpg', 'jpeg', 'png'];
export const VIDEO_ALLOWED_EXT = ['mp4', 'mov', 'mkv', 'webm'];
export const VIDEO_ALLOWED_STREAMING_EXT = ['m3u8'];
export const POST_VISIBILITY = {
    public: "PUBLIC",
    private: "PRIVATE"
}