import ApiService from "MainApp/Services/Api/ApiService";
import { POST_COMMENTS_PER_PAGE, POST_COMMENT_LIKED_USERS_PER_PAGE } from "../constants";
import { FETCH_POST_COMMENT_LIKED_USERS_FAILURE, FETCH_POST_COMMENT_LIKED_USERS_REQUEST, FETCH_POST_COMMENT_LIKED_USERS_RESET, FETCH_POST_COMMENT_LIKED_USERS_SUCCESS } from "./actionTypes";
import Store from "Store";
import { postsListApiDatetime } from "Social/features/Post/actions";
import { addHashTagsInPayload } from "MainApp/features/HashTagPopup/helpers";

export const fetchPostComments = async (userInputData, options) => {
  const apiService = new ApiService();
  try{
      const response = await apiService.get(`/comment/${userInputData.postId}`, { page:userInputData.pageNumber, size:POST_COMMENTS_PER_PAGE, commentId: userInputData.commentId });
      response['userInputData'] = userInputData;
      Store.dispatch(postsListApiDatetime(response.options.serverUTCDate));
      return response;
  }
  catch(error){
      return options.rejectWithValue({userInputData, error})
  }
}

export const postCommentLike = async (userInputData, options) => {
  const apiService = new ApiService();
  try{
      const response = await apiService.post(`/comment/${userInputData.commentId}/like`, {like: !!userInputData.like});
      response['userInputData'] = userInputData;
      return response;
  }
  catch(error){
      return options.rejectWithValue({userInputData, error})
  }
}

export const postCommentDelete = async (userInputData, options) => {
  const apiService = new ApiService();
  try{
      const response = await apiService.delete(`/comment/${userInputData.commentId}`);
      response['userInputData'] = userInputData;
      return response;
  }
  catch(error){
      return options.rejectWithValue({userInputData, error})
  }
}

export const createPostComment = async (userInputData, options) => {
  const apiService = new ApiService();
  try{
      let inputPayload = {comments: userInputData.comments};
      inputPayload = addHashTagsInPayload(inputPayload, inputPayload.comments);

      const response = await apiService.post(`/comment/${userInputData.postId}`, inputPayload);
      response['userInputData'] = userInputData;
      return response;
  }
  catch(error){
      return options.rejectWithValue({userInputData, error})
  }
}

export const fetchPostCommentLikedUsersRequest = () => {
  return {
    type: FETCH_POST_COMMENT_LIKED_USERS_REQUEST
  };
}

export const fetchPostCommentLikedUsersSuccess = (likeUsersObj) => {
  return {
    type: FETCH_POST_COMMENT_LIKED_USERS_SUCCESS,
    payload: {
      ...likeUsersObj
    }
  };
}

export const fetchPostCommentLikedUsersFailure = (error, userInputData) => {
  return {
    type: FETCH_POST_COMMENT_LIKED_USERS_FAILURE,
    payload: {
      error,
      userInputData
    } 
  };
}

export const fetchPostCommentLikedUsersReset = () => {
  return {
    type: FETCH_POST_COMMENT_LIKED_USERS_RESET
  };
}

export const fetchPostCommentLikedUsers = ({dataId, pageNumber = 1, postId}) => {
  const apiService = new ApiService();
  return (dispatch) => {
      dispatch(fetchPostCommentLikedUsersRequest());
      apiService.get(`/comment/${dataId}/like`, { page:pageNumber, size:POST_COMMENT_LIKED_USERS_PER_PAGE})
      .then((response) => {
          if(response?.status === 200){
            const{count, data, totalRecords} = response;
            dispatch(fetchPostCommentLikedUsersSuccess({count, data, totalRecords, pageNumber}));
          }
          else{
            throw response;
          }
      })
      .catch((error) => {
          dispatch(fetchPostCommentLikedUsersFailure(error, {postId}));
      });

  }
}