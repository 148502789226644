import { encryptAndStoreInLocalStorage, getFromLocalStorageAndDecrypt } from "Components/WebChat/WebChatEncryptDecrypt";

const ThemeService = {
    themeNames: {
        dark: 'dark-body',
        light: 'light-body'
    },
    init: function() {
        this.detectSystemThemeChange();
    },
    getDefaultTheme: () => this.themeNames.light,
    isSystemPreferDarkMode: function() {
        return window?.matchMedia('(prefers-color-scheme: dark)')?.matches;
    },
    detectSystemThemeChange: function(){
        // Register event to detect the system theme change
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const toggleTheme = (event) => {
            const newTheme = event.matches ? this.themeNames.dark : this.themeNames.light;
            this.setTheme(newTheme);
        };
        mediaQuery.addEventListener('change', toggleTheme);
        // Check initial theme on page load
        const currentTheme = this.getCurrentTheme();
        if(!currentTheme){
            toggleTheme(mediaQuery);
        }
        else{
            this.setTheme(currentTheme);
        }
    },
    isDarkTheme: function(themeName = this.getCurrentTheme()) {
        return themeName === this.themeNames.dark;
    },
    setTheme: function(themeName) {
        const currentTheme = this.getCurrentTheme();
        themeName = themeName || (this.isSystemPreferDarkMode() ? this.themeNames.dark : this.themeNames.light) || this.getDefaultTheme();
        if(currentTheme){
            document.body.classList.remove(currentTheme);
        }
        document.body.classList.add(themeName);
        encryptAndStoreInLocalStorage('themeName', themeName);
    },
    getCurrentTheme: function() {
        return getFromLocalStorageAndDecrypt('themeName');
    },
    changeTheme: function() {
        const currentTheme = this.getCurrentTheme();
        const themeName = this.isDarkTheme(currentTheme) ? this.themeNames.light : this.themeNames.dark;
        this.setTheme(themeName);
        return themeName;
    }
}

export default ThemeService;