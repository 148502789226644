import { NOTIFICATION_TYPE } from "Social/features/InAppNotification/constants";

export const id = {
    "sidebar":{
        "home": "Rumah",
        "explore": "Mengeksplorasi",
        "saved": "Diselamatkan",
        "chat": "Mengobrol",
        "settings": "Pengaturan"
    },
    "common": {
        "button": {
            "follow": "Mengikuti",
            "following": "Mengikuti",
            "unfollow": "Berhenti mengikuti",
            "createPost": "Buat Postingan",
            "upload": "Mengunggah",
            "discard": "Membuang",
            "cancel": "Membatalkan",
            "post": "Pos",
            "likes": "Suka",
            "reply": "Membalas",
            "viewReplies": "Lihat Balasan",
            "hideReplies": "Sembunyikan Balasan",
            "watchAgain": "Lihat lagi",
            "delete": "Menghapus",
            "editProfile": "Sunting profil",
            "followBack": "Ikuti kembali",
            "message": "Pesan",
            "addLink": "Tambahkan Tautan",
            "update": "Memperbarui",
            "seeAll": "Lihat semua",
            "remove": "Menghapus",
            "save": "Menyimpan",
            "done": "Selesai",
            "clearAll": "Bersihkan semua",
            "forYou": "Untukmu",
            "accounts": "Akun",
            'tags': "Tag",
            "copyLink": "Salin tautan",
            "block": "Memblokir",
            "unblock": "Buka blokir"
        },
        "label": {
            "like": "Menyukai",
            "unlike": "Berbeda dengan",
            "comment": "Komentar",
            "crop": "Tanaman",
            "next": "Berikutnya",
            "share": "Membagikan",
            "commentOptions": "Opsi komentar",
            "or": 'atau',
            "posts": "Postingan",
            "tagged": "Ditandai",
            "followers": "Pengikut",
            "images": "Gambar-gambar",
            "videos": "Video",
            "search": "Cari",
            "changePhoto": "Ubah Foto",
            "uploadPhoto": "Mengunggah foto",
            "suggestions": "Saran",
            'seeAllResult': "Lihat semua hasil",
            'NoRelevantResultFound': "Tidak ditemukan hasil yang relevan",
            "about": "Tentang",
            "help": "Membantu",
            "privacy": "Privasi",
            "terms": "Ketentuan",
            "language": "Bahasa",
            "enkripVerified": "Enkrip Terverifikasi",
            "ok": "Oke",
            "donwloadUsOn": "Unduh kami di",
            "justNow": "Baru saja",
            "now": "Sekarang",
            "report": "Laporan",
            "continue": "Melanjutkan",
            "reportThisPost": "Laporkan Postingan ini",
            "reportThisUser": "Laporkan Pengguna ini"
        }
    },
    "post": {
        "createNewPost": "Buat postingan baru",
        "tagPeople": "Tandai orang",
        "newPostPostingMsg": "Posting sebentar....",
        "newPostPostedSuccessfullyMsg": "Berhasil diposting",
        "noPostFound": "Tidak Ada Postingan yang Ditemukan",
        "discardPost": {
            "discardPost": "Buang postingan?",
            "warningMessage": "Jika Anda keluar, hasil edit Anda tidak akan disimpan."
        },
        "comment":{
            "emptyMsg": "Belum ada komentar",
            "addComment": "Tambahkan komentar",
            "replyingTo": "Membalas"
        },
        "likedUsersPopupTitle": "Siapa yang menyukai postingan ini",
        "createPostCaptionPlaceholder": "Apa yang Anda pikirkan?",
        "createPost":{
            "dragAndDropMessage": "Drag and drop, images and videos here",
            "maxFilesAllowedMessage": "Anda dapat mengunggah maksimal {{MAX_MEDIA_FILES_ALLOWED}} file",
            "deletePost":{
                "video":{
                    "title":"Buang videonya",
                    "message":"Ini akan menghapus video dari postingan Anda."
                },
                "image":{
                    "title":"Buang foto",
                    "message":"Ini akan menghapus foto dari postingan Anda."
                },
                "alert":{
                    "alertContext": "Hapus postingan ini?"
                }
            }
        },
        "rejectPost":{
            "title": "Postingan yang diunggah dihapus",
            "desc": "Postingan terbaru Anda dihapus karena pelanggaran kebijakan moderasi."
        },
        "cropOptions":{
            "label": "Tanaman",
            "options":{
                "original":"Asli"
            }
        },
        "validation":{
            "message":{
                "emptyFiles": 'Tidak ada file yang dipilih',
                "maximumAllowedFiles": "Maksimum {{MAX_MEDIA_FILES_ALLOWED}} file media diperbolehkan",
                "unsupportedFilesMessage": "Format file tidak didukung. File yang diizinkan: {{MEDIA_FILES_ALLOWED}}.",
                "inValidImageSizeMsg": "${fileCount} gambar yang dipilih melebihi batas ukuran masing-masing ${IMAGE_MAX_ALLOWED_SIZE_MB} MB.",
                "inValidVideoDurationMsg": "${fileCount} video yang dipilih melebihi batas durasi ${VIDEO_MAX_ALLOWED_DURATION_SEC} detik.",
                "usernameRequired": "Nama pengguna diperlukan",
                "usernamePattern": "Hanya boleh berisi kombinasi huruf kecil, angka, dan _. Membutuhkan setidaknya 3 huruf.",
                "displayNameRequired": 'Nama Tampilan wajib diisi',
                "urlInvalid": 'Silakan masukkan URL yang valid',
                "usernameUnderscorePattern": "Nama pengguna tidak boleh diawali dengan garis bawah dan tidak boleh memiliki garis bawah berturut-turut.",
                "requires3Alphabets": "Membutuhkan setidaknya 3 huruf"
            }
        },
        "profile":{
            "noPostsYet": "belum ada postingan",
            "whyNotCreateOne": "Mengapa tidak membuatnya?",
            "profilePhoto": "Foto profil",
            "removeCurrentPhoto": "Hapus foto saat ini",
            "updateProfileSuccess": "Profil berhasil diperbarui",
            "noUserFound": "Tidak ada pengguna yang ditemukan",
            "noUsersFound": "Tidak ada pengguna yang ditemukan",
            "editProfileCancelConfirmTitle": "Apakah Anda yakin ingin menyimpan perubahan?",
            "dragAndAdjust": "Seret dan Sesuaikan",
            "unfollowedSuccessMsg": "Anda berhasil berhenti mengikuti {{name}}",
            "block":{
                "info":{
                    "step1": "Mereka tidak akan dapat mengirimi Anda pesan atau menemukan profil atau konten Anda di ENKRIP",
                    "step2": "Mereka tidak akan diberi tahu bahwa Anda memblokirnya.",
                    "step3": "Anda dapat membatalkan pemblokirannya kapan saja di Pengaturan"
                },
                "blockedViewProfileMsg": "Anda tidak dapat melihat profil pengguna ini",
                "blockedViewPostMsg": "Anda telah memblokir pengguna ini. Buka blokir untuk melihat postingan",
                "noBlockedAccount": "Tidak ada akun yang diblokir"
            }
        },
        "user":{
            "username": "Nama belakang",
            "displayName": "Nama tampilan",
            "bio": "Biografi",
            "links": "Tautan",
            "title": "Judul",
            "url": "URL",
        },
        "inviteCode":{
            "inviteCode": "Kode invitasi",
            "invitedByInfo": "Anda telah diundang oleh",
            "mobileNumberInfo": "Silakan gunakan nomor yang sama untuk login ke aplikasi Enkrip",
            "copyCodeInfo": "Silakan masukkan kode undangan di bawah ini pada aplikasi Enkrip",
            "generateInviteCode": "Hasilkan Kode Undangan"
        },
        "peopleYouMightKnow": "Orang yang mungkin Anda kenal",
        "removeSuggestedUserWarningTitle": "Apakah Anda yakin ingin menghapus {{USER_NAME}}?",
        "unfollowUserWarningTitle": "Yakin ingin berhenti mengikuti {{USER_NAME}}?",
        "whoCanViewThisPost":{
            "whoCanViewThisPost": "Siapa yang dapat melihat postingan ini?",
            "everyone": "Setiap orang",
            "closeFriends": "Teman dekat",
            "postAudience": "Posting audiens",
            "whoCanSeeYourPost": "Siapa yang dapat melihat postingan Anda?",
            "whoCanSeeYourPostSubTitle": "Postingan Anda akan muncul di Feed, di profil Anda, dan di hasil pencarian.",
            "addPeople": "Tambahkan orang",
            "peopleWithCount": "{{COUNT}} Orang",
            "selectedFriends": "Teman terpilih",
            "emptyCloseFriendsValidationMsg": "Silakan pilih setidaknya satu teman"
        },
        "hashTag":{
            "lengthValidationMsg": "Anda hanya dapat menambahkan {{MAX_ALLOWED_HASH_TAG}} hashtag"
        },
        "notifications":{
            "title":"Pemberitahuan",
            "emptyNotificationsMsg":"Tidak ada pemberitahuan yang ditemukan",
            "content": {
                [NOTIFICATION_TYPE.follow]: "{{USER_NAME}} sudah mulai mengikuti Anda",
                [NOTIFICATION_TYPE.comment]: "{{USER_NAME}} telah mengomentari postingan Anda",
                [NOTIFICATION_TYPE.commentLike]: "{{USER_NAME}} menyukai komentar Anda",
                [NOTIFICATION_TYPE.commentReply]: "{{USER_NAME}} membalas komentar Anda",
                [NOTIFICATION_TYPE.commentReplyLike]: "{{USER_NAME}} menyukai komentar Anda",
                [NOTIFICATION_TYPE.postLike]: "{{USER_NAME}} menyukai postingan Anda",
            }
        },
        "sharePost":{
            "title": "Bagikan Postingan",
            "copyLink": "Salin Tautan",
            "copyLinkSuccess": "Tautan disalin",
            "invalidSharedPostUrl": "Tautan tersebut mungkin dihapus atau dibatasi. Silakan, Periksa aplikasi untuk pembaruan terkini dan konten segar!"
        },
        "calculateTimeAgo": {
            "aYearAgo": "setahun yang lalu",
            "yearsAgo": "{{years}} tahun yang lalu",
            "aMonthAgo": "sebulan yang lalu",
            "monthsAgo": "{{months}} bulan yang lalu",
            "aWeekAgo": "seminggu yang lalu",
            "weeksAgo": "{{weeks}} minggu yang lalu",
            "yesterday": "Kemarin",
            "daysAgo": " beberapa hari yang lalu",
            "anHourAgo": "satu jam yang lalu",
            "hoursAgo": "{{hours}} jam yang lalu",
            "aMinuteAgo": "semenit yang lalu",
            "minutesAgo": "{{minutes}} menit yang lalu"
        },
        "postRemovedByOwnerMsg": "Postingan ini telah dihapus oleh pemilik postingan",
        "postCommentRemovedByOwnerMsg": "Komentar ini telah dihapus oleh pemilik komentar",
        "deletePost":{
            "successMsg": "Postingan berhasil dihapus"
        },
        "report": {
            "reasonPopup": {
                "title": "Mengapa Anda melaporkan postingan ini?",
                "info": "Masukan Anda Sangat Penting! Sampaikan Kekhawatiran Anda kepada Kami untuk Membantu Kami Menjaga Komunitas yang Aman, Mendukung, dan Menghormati Semua Orang."
            },
            "successPopup":{
                "title": "Terima kasih telah memberi tahu kami",
                "desc": "Saat Anda melihat sesuatu yang tidak Anda sukai di enkrip, Anda dapat melaporkannya jika hal tersebut tidak mengikuti <span>Pedoman</span> kami, atau Anda dapat menghapus orang yang membagikannya dari pengalaman Anda.",
                "otherSteps": "Langkah lain yang dapat Anda ambil"  
            }
        }
    },
    "settings":{
        "settingsSidebar":{
            "accountSettings": "Pengaturan Akun",
            "privacyAndSafety": "Privasi & Keamanan",
            "pushNotification": "Notifikasi Push",
            "languageSettings": "Pengaturan Bahasa",
            "deleteMyAccount": "Hapus akun saya",
            "logout": "Keluar",
            "inviteFriends": {
                "title": "Undang Teman",
                "copyLinkSuccessMsg": "Tautan undangan teman berhasil disalin"
            },
            "aboutUs": "Tentang Kami",
            "description":{
                "accountSettings": "Lihat informasi akun Anda seperti nomor telepon dan alamat email Anda.",
                "privacyAndSafety": "Kelola informasi apa yang Anda lihat dan bagikan di enkrip.",
                "pushNotification": "Pilih jenis notifikasi yang Anda terima tentang aktivitas, minat, dan rekomendasi Anda",
                "languageSettings": "Kelola bahasa mana yang digunakan untuk mempersonalisasi pengalaman Enkrip Anda.",
                "deleteMyAccount": "Cari tahu bagaimana Anda dapat menonaktifkan akun Anda.",
                "inviteFriends": "Bagikan tautan undangan ini agar teman internasional Anda dapat dengan mudah mendaftar dan mulai menggunakan aplikasi.",
                "aboutUs": "Temukan perjalanan dan misi kami saat kami bertransformasi dengan ENKRIP"
            }
        },
        "headers":{
            "myAccount": "Akun Saya",
            "privacyAndSafety": "Privasi dan keamanan",
            "pushNotifications": "Notifikasi Push",
            "languageSettings": "Pengaturan Bahasa",
            "deleteMyAccount": "Hapus akun saya",
            "headerDescription":{
                "myAccount": "Melihat informasi tentang akun Anda, mengunduh arsip data Anda, atau mempelajari opsi penonaktifan akun Anda.",
                "pushNotifications": "Pilih jenis notifikasi yang Anda terima tentang aktivitas, minat, dan rekomendasi Anda.",
                "languageSettings": "Pilih bahasa pilihan Anda untuk judul, tombol, dan teks lain dari enkrip.",
                "deleteMyAccount": "Di bawah langkah-langkah tambahan untuk menghapus akun, kami akan menunjukkannya di web",
                "blockedAccounts": "Lihat akun yang telah Anda blokir."
            },
            "blockedAccounts": "Akun yang Diblokir"
        },
        "common":{
            "button":{
                "update": "Perbarui",
                "deleteAccount": "Hapus Akun",
                "loadMore": "Muat lebih banyak"
            }
        },
        "accountDetails":{
            "firstName": "Nama Depan",
            "lastName": "Nama Belakang",
            "noLastName": "Tidak Ada Nama Belakang",
            "phoneNumber": "Nomor Telepon",
            "emailAddress": "Alamat Email",
            "gender": "Jenis Kelamin",
            "dateOfBirth": "Tanggal Lahir",
            "validationMsg": {
                "nameMinLength": "Diperlukan minimal {{LENGTH}} karakter",
                "invalidEmail": "Alamat email tidak valid",
                "firstnameReq": "Nama Depan wajib diisi",
                "lastnameReq": "Nama Belakang wajib diisi",
                "emailReq": "Alamat Email diperlukan",
                "dob": "Tanggal Lahir wajib diisi"
            },
            "updateSuccess": "Profil Berhasil Diperbarui"
        },
        "interests":{
            "interest": "Minat",
            "header": `Ini adalah beberapa minat yang cocok dengan Anda berdasarkan profil, aktivitas, dan Topik yang Anda ikuti. Ini digunakan untuk mempersonalisasi pengalaman Anda di X, termasuk iklan yang Anda lihat. Anda dapat menyesuaikan minat Anda jika ada yang tidak beres. Perubahan apa pun yang Anda buat mungkin memerlukan waktu beberapa saat untuk diterapkan.`,
            "interestUpdateSuccess": "Minat berhasil diperbarui"
        },
        "pushNotification":{
            "off": "Matikan",
            "fromProfileIFollow": "Dari Profil yang Saya Ikuti",
            "fromEveryone": "Dari Semua Orang",
            "likes": "Suka",
            "comments": "Komentar",
            "commentLikes": "Suka Komentar",
            "taggedPosts": "Post yang Ditandai",
            "acceptFollowRequest": "Terima Permintaan Mengikuti"
        },
        "deleteAccount":{
            "deleteMyAccount": "Hapus akun saya",
            "step1": "Masuk ke akun ENKRIP Anda di Aplikasi Seluler",
            "step2": "Buka 'Halaman Profil' dengan mengetuk gambar profil (kiri atas layar) > Pengaturan (kanan atas layar) > Hapus Akun Saya",
            "step3": "Pilih alasan dan Klik \"Lanjutkan\"",
            "step4": "Masukkan nomor Ponsel dan OTP",
            "step5": "Di halaman Hapus Akun, Klik \"Lanjutkan\"",
            "step6": "Akun akan segera dihapus setelah tombol \"Hapus\" diklik",
        },
        "logout":{
            "logout": "Keluar"
        },
        "popups":{
        "confirmLogout": "Konfirmasi Keluar",
        "wouldYouLikeToLogOut": "Apakah Anda yakin ingin keluar?",
        "cancel": "Batal",
        "confirm": "Konfirmasi",
        "confirmLanguageChange": "Konfirmasi Perubahan Bahasa",
        "changeLanguageDescription": "Apakah Anda yakin ingin mengubah bahasa dari Indonesia ke Inggris?",
        "yes": "Ya",
        "no": "Tidak"
        }
    },
    "login": {
        "title": "Masuk ke ENKRIP dengan kode QR",
        "loginSteps": {
            "step1": "Buka ENKRIP di ponsel Anda",
            "step2": "Buka Profil Anda &gt;	Pengaturan &gt; Perangkat Tertaut",
            "step3": "Arahkan ponsel Anda ke layar ini untuk mengambil Kode QR untuk Masuk"
        }
    }
}