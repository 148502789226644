import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { updatePostView } from "./actions";

export const updatePostViewAction = createAsyncThunk(
    "update/post/view",
    async (userInputData, options) => await updatePostView(userInputData, options)
)

const initialState = {
    loading: ACTION_IDLE,
    postIds: []
}

const updatePostViewSlice = createSlice({
    name: 'updatePostView',
    initialState,
    reducers: {
        resetUpdatePostView(){
            return initialState;
        },
        addPostIdInUpdatePostView(state, { payload }){
            if(state.postIds.indexOf(payload.postId) === -1){
                state.postIds = [...state.postIds, payload.postId];
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updatePostViewAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
        })
        .addCase(updatePostViewAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
        })
        .addCase(updatePostViewAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
        })
    }
});

export const { resetUpdatePostView, addPostIdInUpdatePostView } = updatePostViewSlice.actions;
const updatePostViewReducer = updatePostViewSlice.reducer;

export default updatePostViewReducer;