import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HASH_TAG_POSTS_PER_PAGE } from "./constants";
import { ACTION_FAILED, ACTION_IDLE, ACTION_PENDING, ACTION_SUCCEEDED } from "Store/constants";
import { clearMapData, formatDataArrayAsMap } from "MainApp/features/Helpers/Map";
import { fetchPostsByHashTag, followHashTag } from "./actions";

export const FETCH_POSTS_BY_HASH_TAG = 'fetch/posts/by/hashtag';
export const fetchPostsByHashTagAction = createAsyncThunk(
    FETCH_POSTS_BY_HASH_TAG,
    async (userInputData, options) => await fetchPostsByHashTag(userInputData, options)
)

export const FOLLOW_HASH_TAG = 'follow/hashtag';
export const followHashTagAction = createAsyncThunk(
    FOLLOW_HASH_TAG,
    async (userInputData, options) => await followHashTag(userInputData, options)
)

const initialState = {
    loading: ACTION_IDLE,
    error: null,
    data: new Map(),
    hashTagFollowing: false,
    count: HASH_TAG_POSTS_PER_PAGE,
    totalRecords: 0,
    pageNumber: 1,
    actionType: FETCH_POSTS_BY_HASH_TAG
}

const hashTagPostsSlice = createSlice({
    name: 'hashTagPosts',
    initialState,
    reducers: {
        resetHashTagPosts(){
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPostsByHashTagAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
            state.actionType = FETCH_POSTS_BY_HASH_TAG;
        })
        .addCase(fetchPostsByHashTagAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.userInputData = payload.userInputData;
            state.actionType = FETCH_POSTS_BY_HASH_TAG;
            //Clear the old data If call is initiated for page 1
            if(payload.userInputData.pageNumber === 1){
                state.data = clearMapData(state.data);
            }
            const { userInputData, count, data, totalRecords } = payload;
            const { hashTagFollowing, relatedPosts } = data;
            if(Array.isArray(relatedPosts)){
                state.data = formatDataArrayAsMap('posts.id', relatedPosts, state.data);
                state.count = count || state.count;
                state.totalRecords = totalRecords || state.totalRecords;
                state.pageNumber = userInputData.pageNumber;
            }
            state.hashTagFollowing = hashTagFollowing;
        })
        .addCase(fetchPostsByHashTagAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.actionType = FETCH_POSTS_BY_HASH_TAG;
        })
        builder.addCase(followHashTagAction.pending, (state, { payload }) => {
            state.loading = ACTION_PENDING;
            state.actionType = FOLLOW_HASH_TAG;
        })
        .addCase(followHashTagAction.fulfilled, (state, { payload }) => {
            state.loading = ACTION_SUCCEEDED;
            state.actionType = FOLLOW_HASH_TAG;
            state.hashTagFollowing = payload.userInputData.follow;
        })
        .addCase(followHashTagAction.rejected, (state, { payload }) => {
            state.loading = ACTION_FAILED;
            state.actionType = FOLLOW_HASH_TAG;
        })
    }
});

export const { resetHashTagPosts } = hashTagPostsSlice.actions;

export default hashTagPostsSlice.reducer;