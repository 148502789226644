export const POPUP_COMPONENT_TYPES = {
    previewPost: 'previewPost',
    createPost: 'createPost',
    discardCreatePost: 'discardCreatePost',
    createPostCropSelectedMedia: 'createPostCropSelectedMedia',
    createPostPreviewCreatePost: 'createPostPreviewCreatePost',
    postLikedUsersList: 'postLikedUsersList',
    postCommentDelete: 'postCommentDelete',
    postCommentLikedUsersList: 'postCommentLikedUsersList',
    onlineOfflinePopup: 'onlineOfflinePopup',
    followersList: 'followersList',
    followingList: 'followingList',
    profileImageCrop: 'cropSelectedImagePopup',
    changeProfileImage: 'changeProfileImagePopup',
    suggestedUsersPopup: 'suggestedUsersPopup',
    createPostWhoCanView: 'createPostWhoCanView',
    createPostCloseFriendsList: 'createPostCloseFriendsList',
    sharePost: 'sharePost',
    inviteFriends: 'inviteFriends',
    profileMoreOptions: 'profileMoreOptions',
    rejectPostPopup: 'rejectPostPopup',
    infoPopup: 'infoPopup',
    reportReasonsPopup: 'reportReasonsPopup',
    reportSuccessPopup: 'reportSuccessPopup',
    blockUserPopup: 'blockUserPopup'
}