import { toState } from "Store/helpers";
import i18n from "../Languages/i18n";
import toastr from "toastr";

export const isAppOnline = () => {
    const appOnlineStatus = toState().appOnlineStatus;
    return !!(appOnlineStatus && appOnlineStatus.isOnline);
  };
  
export const blockOfflineAction = (msg = null,) => {
    const {t} = i18n
    if (!isAppOnline()) {
        msg = msg || t('errors.pleasecheckyourinternetconnection');
        toastr.clear();
        toastr.warning(msg);
        return true;
    }
    return false;
};