import { isActionIdle, toState } from "Store/helpers";
import { fetchPostLikedUsersReset } from "../PostLike/actions";
import { closeOverlaySocialPopup as closeOverlaySocialPopupAction, closeSocialPopup as closeSocialPopupAction, openNestedSocialPopup, openOverlaySocialPopup, openSocialPopup } from "./actions";
import { POPUP_COMPONENT_TYPES } from "./constants";
import Store from "Store";
import { fetchPostCommentLikedUsersReset } from "Social/features/PostComments/Comments/actions";
import { resetPostComments } from "../PostComments/Comments/commentsSlice";
import { resetCreatePost, resetCreatePostMediaUpload } from "../CreatePost/createPostSlice";
import { resetUserFollowers, resetUserFollowing } from "../Profile/userSlice";
import { resetPostCommentReplies } from "../PostComments/Replies/repliesSlice";
import { resetSuggestedUsers } from "../SuggestedUsers/suggestedUsersSlice";
import { resetCloseFriends } from "../CloseFriends/closeFriendsSlice";
import { resetUserPost } from "../Post/reducer";
import { resetReportPost } from "../Post/slice/reportSlice";
import { resetReportUser } from "../Profile/reportSlice";

export const openCreatePostPopup = () => {
    Store.dispatch(openSocialPopup(POPUP_COMPONENT_TYPES.createPost))
}

export const openCpCropSelectedMediaPopup = () => {
    Store.dispatch(openNestedSocialPopup(POPUP_COMPONENT_TYPES.createPostCropSelectedMedia))
}

export const openCpPreviewCreatePostPopup = () => {
    Store.dispatch(openNestedSocialPopup(POPUP_COMPONENT_TYPES.createPostPreviewCreatePost))
}

export const openCpWhoCanViewPopup = () => {
    Store.dispatch(openNestedSocialPopup(POPUP_COMPONENT_TYPES.createPostWhoCanView))
}

export const openCpCloseFriendsListPopup = () => {
    Store.dispatch(openNestedSocialPopup(POPUP_COMPONENT_TYPES.createPostCloseFriendsList))
}

export const openDiscardCreatePostPopup = (data) => {
    Store.dispatch(openOverlaySocialPopup(POPUP_COMPONENT_TYPES.discardCreatePost, data))
}

export const closeSocialPopup = (closeAllPopup = false) => {
    if(!closeAllPopup && isOverlaySocialPopupOpen()){
        closeOverlaySocialPopup();
    }
    else{
        resetPopupStoreData();
        Store.dispatch(closeSocialPopupAction());
    }
}

export const closeOverlaySocialPopup = () => {
    Store.dispatch(closeOverlaySocialPopupAction());
    resetOverlayPopupStoreData();
}

export const openPostLikedUsersListPopup = (data = {}) => {
    const socialPopup = toState().socialPopup;
    if(isGivenSocialPopupOpen(POPUP_COMPONENT_TYPES.previewPost, socialPopup)){
        Store.dispatch(openOverlaySocialPopup(POPUP_COMPONENT_TYPES.postLikedUsersList, data));
    }
    else {
        Store.dispatch(openSocialPopup(POPUP_COMPONENT_TYPES.postLikedUsersList, data));
    }
}

/**
 * Delete the data stored in redux related to popup when close the popup to free up the space
 */
export const resetPopupStoreData = () => {
    const state = toState();
    if(state.postComments?.data?.size){
        Store.dispatch(resetPostComments());
        Store.dispatch(resetPostCommentReplies());
    }
    
    if(!isActionIdle(state.createPost.loading)){
        Store.dispatch(resetCreatePost());
        Store.dispatch(resetCreatePostMediaUpload());
    }

    if(!isActionIdle(state.userFollowers.loading)){
        Store.dispatch(resetUserFollowers());
    }

    if(!isActionIdle(state.userFollowing.loading)){
        Store.dispatch(resetUserFollowing());
    }

    if(!isActionIdle(state.suggestedUsers.loading)){
        Store.dispatch(resetSuggestedUsers());
    }

    if(!isActionIdle(state.closeFriends.loading)){
        Store.dispatch(resetCloseFriends());
    }

    if(!isActionIdle(state.post.loading)){
        Store.dispatch(resetUserPost());
    }

    if(!isActionIdle(state.reportPost.loading)){
        Store.dispatch(resetReportPost());
    }

    if(!isActionIdle(state.reportUser.loading)){
        Store.dispatch(resetReportUser());
    }

    resetOverlayPopupStoreData();
}

export const resetOverlayPopupStoreData = () => {
    const state = toState();
    if(state.postLikedUsers?.data?.size){
        Store.dispatch(fetchPostLikedUsersReset());
    }

    if(state.postCommentLikedUsers?.data?.size){
        Store.dispatch(fetchPostCommentLikedUsersReset());
    }
}

/**
 * Check given popupType is open or not
 * @param {*} popupType 
 * @param {*} socialPopup - Store social popup state
 * @returns 
 */
export const isGivenSocialPopupOpen = (popupType, socialPopup = toState()?.socialPopup) => socialPopup?.isOpen && (socialPopup?.type === popupType || socialPopup?.nestedType === popupType);
export const isSocialPopupOpen = () => {
    const state = toState();
    return state?.socialPopup?.isOpen;
}
export const isOverlaySocialPopupOpen = () => {
    const state = toState();
    return state?.socialPopup?.overlayPopup?.isOpen;
}

export const isGivenOverlaySocialPopupOpen = (popupType) => {
    const state = toState();
    return !!(isOverlaySocialPopupOpen() && (state?.socialPopup?.overlayPopup?.type === popupType));
}

export const openPostCommentDeletePopup = (data) => {
    Store.dispatch(openOverlaySocialPopup(POPUP_COMPONENT_TYPES.postCommentDelete, data));
}

export const openPostCommentLikedUsersPopup = (data) => {
    Store.dispatch(openOverlaySocialPopup(POPUP_COMPONENT_TYPES.postCommentLikedUsersList, data));
}

export const openOnlineOfflinePopup = (data) => {
    Store.dispatch(openOverlaySocialPopup(POPUP_COMPONENT_TYPES.onlineOfflinePopup, data));
}

export const openUserFollowersPopup = (data) => {
    Store.dispatch(openSocialPopup(POPUP_COMPONENT_TYPES.followersList, data));
}

export const openUserFollowingPopup = (data) => {
    Store.dispatch(openSocialPopup(POPUP_COMPONENT_TYPES.followingList, data));
}

export const openProfileImageCropPopup = (data) => {
    Store.dispatch(openSocialPopup(POPUP_COMPONENT_TYPES.profileImageCrop, data));
}

export const openChangeProfileImagePopup = (data) => {
    Store.dispatch(openSocialPopup(POPUP_COMPONENT_TYPES.changeProfileImage, data));
}

export const openSuggestedUsersPopup = (data) => {
    Store.dispatch(openSocialPopup(POPUP_COMPONENT_TYPES.suggestedUsersPopup, data));
}

export const openSharePostPopup = (data) => {
    if(isSocialPopupOpen()){
        Store.dispatch(openOverlaySocialPopup(POPUP_COMPONENT_TYPES.sharePost, data));
    }
    else{
        Store.dispatch(openSocialPopup(POPUP_COMPONENT_TYPES.sharePost, data));
    }
}

export const openInviteFriendsPopup = (data) => {
    Store.dispatch(openSocialPopup(POPUP_COMPONENT_TYPES.inviteFriends, data))
}

export const openProfileMoreOptionsPopup = (data) => {
    Store.dispatch(openSocialPopup(POPUP_COMPONENT_TYPES.profileMoreOptions, data))
}

export const openRejectPostPopup = (data) => {
    if(isSocialPopupOpen()){
        Store.dispatch(openOverlaySocialPopup(POPUP_COMPONENT_TYPES.rejectPostPopup, data));
    }
    else{
        Store.dispatch(openSocialPopup(POPUP_COMPONENT_TYPES.rejectPostPopup, data));
    }
}

export const openInfoPopup = (data) => {
    Store.dispatch(openOverlaySocialPopup(POPUP_COMPONENT_TYPES.infoPopup, data))
}

export const openReportReasonsPopup = (data) => {
    Store.dispatch(openOverlaySocialPopup(POPUP_COMPONENT_TYPES.reportReasonsPopup, data))
}

export const openReportSuccessPopup = (data) => {
    Store.dispatch(openSocialPopup(POPUP_COMPONENT_TYPES.reportSuccessPopup, data))
}

export const openBlockUserPopup = (data) => {
    Store.dispatch(openOverlaySocialPopup(POPUP_COMPONENT_TYPES.blockUserPopup, data));
}