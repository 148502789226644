import { Outlet, Link } from "react-router-dom";
// import { SocialIcon, SearchIcon, NotificationIcon, SavepostIcon, ChatIcon,  SettingsIcon, EnkripWhiteLogo } from "Social/assets/images/svg";
// import user1 from "Social/assets/images/user_1.png";

const ComingSoon = () => {
  return (
    <>
      <h2 className="comingsoon_text">Coming Soon...!!!</h2>
    </>
  )
};

export default ComingSoon;