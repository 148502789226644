import { GET_CHAT_TAGS, SET_REORDER_CHAT_TAGS } from "./Constants";
import uuidv4 from "uuid/v4";

export const chatTagAction = (data) => {
    return {
        type: GET_CHAT_TAGS,
        payload: {
            id: uuidv4(),
            data
        }
    }
}

export const setReorderChatTagsAction = (data) => {
    return {
        type: SET_REORDER_CHAT_TAGS,
        payload: {
            id: uuidv4(),
            data
        }
    }
}