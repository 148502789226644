import ApiService from "MainApp/Services/Api/ApiService";
import { sliceActionHandler } from "MainApp/Services/Api/helpers";
import { POST_COMMENTS_PER_PAGE } from "../PostComments/constants";
import Store from "Store";
import { postsListApiDatetime } from "../Post/actions";
import { USER_POSTS_PER_PAGE } from "../Profile/constants";
import { encryptSharedData } from "./helpers";

export const fetchGuestPostByPostId = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    const postId = encryptSharedData(userInputData.postId); 
    const response = await sliceActionHandler(() => apiService.get(`/post/guest`, {postId}), {
        userInputData,
        thunkAPI
    });
    return response;
}

export const fetchGuestPostComments = async (userInputData, options) => {
    const apiService = new ApiService();
    try{
        const postId = encryptSharedData(userInputData.postId); 
        const response = await apiService.get(`/comment/guest`, { postId, page:userInputData.pageNumber, size:POST_COMMENTS_PER_PAGE });
        response['userInputData'] = userInputData;
        Store.dispatch(postsListApiDatetime(response.options.serverUTCDate));
        return response;
    }
    catch(error){
        return options.rejectWithValue({userInputData, error})
    }
}

export const fetchGuestPostCommentReplies = async (userInputData, options) => {
    const apiService = new ApiService();
    try{
        const commentId = encryptSharedData(userInputData.commentId);
        const response = await apiService.get(`/comment/reply/guest`, { commentId, page:userInputData.pageNumber, size:POST_COMMENTS_PER_PAGE });
        response['userInputData'] = userInputData;
        return response;
    }
    catch(err){
        return options.rejectWithValue(err)
    }
}

export const fetchGuestUserProfileByUserId = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    const userId = encryptSharedData(userInputData.userId);
    const response = await sliceActionHandler(() => apiService.get(`/users/profile/guest`, {userId, detailedInfo: true}), {
        userInputData,
        thunkAPI
    });
    return response;
}

export const fetchGuestUserPostsByUserId = async (userInputData, thunkAPI) => {
    const apiService = new ApiService();
    
    const userId = encryptSharedData(userInputData.userId);
    const response = await sliceActionHandler(() => apiService.get(`/post/user/guest`, {userId, page:userInputData.pageNumber, size:USER_POSTS_PER_PAGE}), {
        userInputData,
        thunkAPI
    });
    return response;
}