export const en = {
    "common": {
        "text": {
            "enkripcomingsoon...": "Enkrip Coming Soon...",
            "hi":"Hi",
            "trythewholesomeexperienceofENKRIP":"Try the wholesome experience of ENKRIP",
            "profile":"Profile",
            "contacts":"Contacts",
            "noresponsefrom":"No response from",
            "s":"s",
            "an":"an",
            "a":"a",
            "call":"call",
            "call":"Call",
            "at":"at",
            "offline":"offline",
            "notsent":"notsent",
            "sent":"sent",
            "processing":"processing",
            "left":"left",
            "removedyou":"removed you",
            "removed":"removed",
            "youupdated":"You updated",
            "updated":"updated",
            "profile":"profile",
            "retry":"Retry",
            "loading":"Loading...",
            "connected":"CONNECTED",
            "enkrip":"ENKRIP",
            "termsandprivacypolicyus":"Terms and Privacy Policy Us",
            "contactpermission":"Contact permission",
            "thelast5messagesfromthis":"The last 5 messages from this",
            "single":"single",
            "contact":"contact",
            "willbeforwardedtoadmin":"will be forwarded to admin.",
            "nooneinthisgroupwillbenotified":"No one in this group will be notified.",
            "onunblockingthiscontactwillbeabletocallyouorsendmessagestoyou":"On unblocking, this contact will be able to call you or send messages to you.",
            "onblockingthiscontactwillnotbeabletocallyouorsendmessagestoyou":" 'On blocking, this contact will not be able to call you or send messages to you.",
            "unblock":"Unblock",
            "block":"Block",
            "mutualFriends": "Mutual Friends"
        },
        "button": {
            "next": "Next",
            "cancel":"Cancel",
            "clear":"Clear",
            "no":"No",
            "delete":"Delete",
            "send":"send",
            "continue":"Continue",
            "ok":"Ok",
            "okay":"Okay",
            "remove":"Remove",
            "deleteaction":"DeleteAction",
            "clearaction":"ClearAction",
            "pleaseselectareason":"Please select a reason",
            "deleteforme":"Delete For Me",
            "deleteforeveryone":"Delete For Everyone",
            "thisuserisnolongeravailable":"This user is no longer available",
            "clickon":"Click on ",
            "ccancel":"CANCEL",
            "switch":"SWITCH",
            "decline":"DECLINE",
            "accept":"ACCEPT",
            "usehere":"Use Here",
            "yes": "Yes",
            "create":"Create",
            "update":"Update",
            "add": "Add",
            "clearCallLog": "Clear call log",
            "viewProfile": "View Profile"
        }  
    },
    "label": {
        "search": "Search",
        "closesearch":"CloseSearch",
        "yesterday":"Yesterday",
        "you":"You",
        "contactinfo":"Contact info",
        "aboutandphonenumber":"About and phone number",
        "blockcontact":"Block Contact",
        "admin":"Admin",
        "chat":"Chat",
        "chats":"Chats",
        "archivedsettings":"Archived Settings",
        "notifications":"Notifications",
        "starred":"Starred",
        "unstarred":"UnStarred",
        "starredmessages":"Starred Messages",
        "boxedview":"Boxed View",
        "translatemessage":"Translate Message",
        "enabletranslatemessagetochoosetranslationlanguage":"Enable Translate Message to choose Translation Language",
        "choosetranslationlanguage":"Choose Translation Language",
        "defaultselectedactive":"defaultSelected active",
        "defaultselected":"defaultSelected",
        "selectanylanguage":"Select any language",
        "mouseoverreceivedmessagechatbubblewhichdisplaystranslatemessageicon":"Mouse over received message chat bubble which displays Translate Message icon,",
        "clickittotranslatethemessage":"Click it to translate the message",
        "keepchatsarchived":"Keep chats archived",
        "archivedchatswillremainarchivedwhenyoureceiveanewmessage":"Archived chats will remain archived when you receive a new message",
        "unstarall":"Unstar all",
        "1messageunstarred":"1 message unstarred",
        "nostarredmessages":"No Starred Messages",
        "title":"Title",
        "titleisrequired":"Title is required.",
        "description":"Description",
        "descriptionisrequired":"Description is required.",
        "send":"Send",
        "country":"Country",
        "mobilenumber":"Mobile number",
        "entermobilenumber":"Enter Mobile Number",
        "starttyping":"Start Typing...",
        "groupcall":"Group Call",
        "inviteparticipants":"Invite Participants",
        "callnow":"Call now",
        "aboutandemail":"About and email",
        "tryingtoconnect":"Trying to connect",
        "userseemstobeofflinetryingtoconnect":"User seems to be offline, Trying to connect",
        "videocall":"Video call",
        "audiocall":"Audio call",
        "youarealreadyincall":"You are already in call",
        "hangup":"Hangup",
        "calldisabled":"calldisabled",
        "mute":"Mute",
        "unmute":"Unmute",
        "muteunmute":"Mute / Unmute",
        "reply":"Reply",
        "forward":"Forward",
        "unstar":"Unstar",
        "star":"Star",
        "oops":"Oops...",
        "callended":"Call Ended!",
        "nooneelseishere":"No one else is here",
        "maximum":"Maximum",
        "charactersallowed":"characters allowed",
        "membersallowedinacall":"members allowed in a call",
        "to":"to",
        "recentchat":"Recent Chat",
        "user":"User",
        "message":"message",
        "deleted":"deleted",
        "pinned":"Pinned",
        "unpinned":"Unpinned",
        "moreoptions":"More options",
        "back":"Back",
        "thisgroupisnolongeravailable":"This group is no longer available",
        "chooseacountry":"Choose a country",
        "pleaseentervalidmobilenumber":"Please enter valid mobile number",
        "pleaseselectcountry":"Please select country",
        "backtohome ":"Back To Home ",
        "youhave":"You have ",
        "snewchat":"new chat",
        "total":"Total",
        "unreadmessage":"unread message",
        "orsearchtostartacall":"or Search to start a Call!",
        "members":"members",
        "members":"members",
        "make":"Make",
        "fromthisgroup?":"from this group?",
        "deleteduser":"Deleted User",
        "enteryourname":"Enter your name",
        "makegroupadmin":"Make group admin",
        "exit":"Exit",
        "sunday":"Sunday",
        "monday":"Monday",
        "tuesday":"Tuesday", 
        "wednesday":"Wednesday", 
        "thursday":"Thursday", 
        "friday":"Friday", 
        "saturday":"Saturday",
        "today":"Today",
        "jan":"Jan", 
        "feb":"Feb", 
        "mar":"Mar", 
        "apr":"Apr", 
        "may":"May", 
        "jun":"Jun", 
        "jul":"Jul", 
        "aug":"Aug", 
        "sep":"Sep", 
        "oct":"Oct", 
        "nov":"Nov", 
        "dec":"Dec",
        "isopeninanotherwindow":"is open in another window.",
        "clickuseheretouse":"Click “Use Here” to use",
        "inthiswindow":"in this window.",
        "yourenkripaccounthasbeendeleted":"Your ENKRIP account has been deleted.",
        "morehave":"more have",
        "joined":"joined",
        "left":"left",
        "and":"and",
        "thisapplicationisnolongeravailableforyou":"This application is no longer available for you.",
        "pleasecontactadminifyouhaveanyquery":"Please contact admin if you have any query.",
        "mailto":"mail to:",
        "readby":"Read By",
        "deliveredto":"Delivered to",
        "member":"member",
        "members":"members",
        "termsandconditions": "Terms and Conditions",
        "privacypolicy": "Privacy Policy."

    },
    "icontext": {
        "chatting":"CHATTING",
        "voice":"VOICE",
        "photo":"Photo",
        "audio":"Audio",
        "video":"Video",
        "photovideo":"Photo / Video",
        "file":"File",
        "contact":"Contact",
        "location":"Location",
        "gif":"Gif",
        "sticker":"Sticker",
        "viewphoto":"View Photo",
        "takephoto":"Take Photo",
        "removephoto":"Remove Photo",
        "uploadphoto":"Upload Photo",
        "newchat":"New Chat",
        "mute":"Mute",
        "report":"Report",
        "participants":"Participants",
        "addparticipants":"Add Participants",
        "addgroupparticipants":"Add group participants",
        "saddgroupparticipants":"Add Group Participants",
        "exitgroup":"Exit Group",
        "reportgroup":"Report Group",
        "deletegroup":"Delete Group",
        "document":"Document",
        "camera":"Camera",
        "stoprecord":"Stop record",
        "sendrecord":"Send record",
        "addfile":"Add File",
        "recapture":"Re capture",
        "updateprofile":"Update Profile",
        "participantismuted":"Participant is muted",
        "participanthasstoppedthecamera":"Participant has stopped the camera",
        "backtochat":"Back to chat",
        "toggletileview":"Toggle tile view",
        "clearsearch":"Clear search",
        "record":"Record",
        "previous":"Previous",
        "findinchat":"Find in Chat",
        "close":"Close",
        "attachment":"Attachment",
        "gifsandstickers":"GIFs and Stickers",
        "messageInfo":"MessageInfo",
        "addasfavourite":"Add as favourite",
        "removefromfavourite":"Remove from favourite",
        "selectgroupmembers":"Select group members",
        "creategroup":"Create group",
        "editname":"Edit name",
        "editstatus":"Edit status",
    },
    "infotext": {
        "yourstatus":"Your Status",
        "profileimagenameandstatuscanbechangedatanytime":"Profile image, name and status can be changed at anytime",
        "itseemsliketherearenocontacts":"It seems like there are no contacts",
        "calllogs":"Call Logs",
        "nocallloghistoryfound":"No call log history found",
        "ohsnapitseemsliketheyrenocallhistory":"Oh snap It seems like they’re no call history!",
        "anynewcallswillappearhere":"Any new calls will appear here",
        "noresultsfound":"No results found",
        "nocontactsfound":"No contacts found",
        "newgroup":"New Group",
        "archived":"Archived",
        "archivedchat":"Archived Chat",
        "archivedchats":"Archived Chats",
        "unarchivedchat":"Unarchived Chat",
        "settings":"Settings",
        "logout":"Logout",
        "addgroupicon":"Add Group Icon",
        "typegroupnamehere":"Type group name here...",
        "noarchivedchats":"No archived chats",
        "ohsnapitseemsliketherearenochat":"Oh snap It seems like there are no chat!",
        "tryingtoconnect":"Trying to connect",
        "userseemstobeofflinetryingtoconnect":"User seems to be offline, Trying to connect",
        "media":"Media",
        "docs":"Docs",
        "links":"Links",
        "mediaanddocs":"Media and Docs",
        "thereisnoconversation":"There is no conversation.",
        "shortstoriestxt":"Short-stories.txt",
        "clearchat":"Clear Chat",
        "deletechat":"Delete Chat",
        "report":"Report",
        "onblockingthiscontactwillnotbeabletocallyouorsendmessagestoyou":"On blocking, this contact will not be able to call you or send messages to you.",
        "thelast5messagesfromthiscontactwillbeforwardedtoadminthiscontactwillnotbenotified.":"The last 5 messages from this contact will be forwarded to admin. This contact will not be notified.",
        "groupinfo":"Group info",
        "nochatsorcontactsfound":"No chats or contacts found",
        "thelast5messagesfromthisgroupwillbeforwardedtoadminnooneinthisgroupwillbenotified.":"The last 5 messages from this group will be forwarded to admin. No one in this group will be notified.",
        "blocked":"Blocked",
        "blockedcontacts":"Blocked Contacts",
        "noblockedcontactsfound":"No Blocked Contacts Found",
        "aboutandhelp":"About and Help",
        "deletemyaccount":"Delete My Account",
        "releasedon":"Released On:",
        "version":"Version:",
        "thereisnostarredmessage":"There is no starred message.",
        "youdeletedthismessage":"You deleted this message",
        "thismessagewasdeleted":"This message was deleted",
        "aboutus":"About Us",
        "enkripisareadytogomessagingsolutionforbuildingenterprisegraderealtimechatimapplicationsthatmeetvariousdegreesofrequirementsliketeamdiscussiondatasharingtaskdelegationandinformationhandlingonthego":"ENKRIP is a ready-to-go messaging solution for building enterprise-grade real-time chat IM applications that meet various degrees of requirements like team discussion, data sharing, task delegation and information handling on the go.",
        "contactus":"Contact Us",
        "tohaveadetailedinteractionwithourexperts":"To have a detailed interaction with our experts",
        "FAQ":"FAQ",
        "kindlycheckoutFAQsectionfordoubtsregardingenkripwemighthavealreadyansweredyourquestion":"Kindly checkout FAQ section for doubts regarding ENKRIP. We might have already answered your question.",
        "ENKRIP":"ENKRIP",
        "thankyouforcontactingus":"Thank you for contacting us!",
        "termsandprivacypolicy":"Terms and Privacy Policy",
        "searchfor":"Search for",
        "gifs":"GIFs",
        "stickers":"Stickers",
        "wehatetoseeyouleaveTelluswhyyouaredeletingyouraccount":"We hate to see you leave! Tell us why you are deleting your account:",
        "tellushowwecanimprove":"Tell us how we can improve",
        "wewillstoreyourfeedbackforfuturepurpose":"We will store your feedback for future purpose.",
        "iamchangingmydevice":"I am changing my device",
        "iamchangingmyphonenumber":"I am changing my phone number",
        "enkripismissingafeature":"ENKRIP is missing a feature",
        "enkripisnotworking":"ENKRIP is not working",
        "other":"Other",
        "nomediafound":"No Media Found",
        "preview":"Preview",
        "addacaption":"Add a caption",
        "retake":"Retake",
        "youcreatedthisgroup":"You created this group",
        "youcreated":"You created ",
        "created":"created",
        "thisgroup":"this group",
        "broadcast":"broadcast",
        "youadded":"You added",
        "isnowadmin":"is now admin",
        "isnowanadmin":"is now an admin",
        "isremovedfromgroup":"is removed from group",
        "youremoved ":"You removed",
        "added":"added",
        "removed":"removed",
        "dragandadjust":"Drag and Adjust",
        "cameranotfound":"Camera Not Found!",
        "makeadmin":"Make admin",
        "removefromgroup":"Remove from Group",
        "today":"Today",
        "online":"Online",
        "lastseentodayat":"last seen today at",
        "lastseenyesterdayat":"last seen yesterday at",
        "lastseenon":"last seen on",
        "lastseen":"last seen",
        "now":"Now",
        "minago":"min ago",
        "allmembersofyourcontactsarealreadyinthecall":"All members of your contacts are already in the call.",
        "calllinkcopied":"Call Link Copied",
        "calllink":"Call Link",
        "microphoneoff":"Microphone off",
        "cameraoff":"Camera off",
        "archivedchatsaremuted":"Archived chats are muted",
        "youcantsendmessagetothisblockedcontact":"You can't send message to this blocked contact.",
        "youcantsendmessagestothisgroupbecauseyourenolongeraparticipant":"You can't send messages to this group because you're no longer a participant.",
        "thismessagewillbeforwardedtoadmin":"This message will be forwarded to admin.",
        "thiscontactwillnotbenotified":"This contact will not be notified.",
        "messageinfo":"Message Info",
        "download":"Download",
        "report":"Report",
        "selected":"Selected",
        "drag":"Drag",
        "dropyourfileshere":"Drop Your Files Here",
        "eraseyourmessagehistory":"Erase your message history",
        "invalidcalllink":"Invalid call link",
        "returntochat":"Return to chat",
        "viewcontact":"View contact",
        "forwardmessage":"Forward message",
        "filedoesntexists":"File doesn't exists",
        "requestdeclined":"Request declined",
        "cannotreportdeleteduser":"Cannot report deleted user",
        "thisusernolongeravailable":"This user no longer available",
        "featureunavailableforyourplan":"Feature unavailable for your plan",
        "youareonlyonememberinthegroup":"You are only one member in the group",
        "thesessionhasbeenloggedout":"The session has been logged out",
        "nomessagestoreport":"No Messages To Report",
        "cannotreportdeletedusersmessage":"Cannot report deleted user's message",
        // "1messageunstarred":"1 message unstarred",
        "incoming":"Incoming",
        "group":"Group",
        "newincomingcall":"New Incoming Call",
        "youmissed":"You missed",
        "newmissedcall":"New Missed Call",
        "audio":"audio",
        "sgroup":"group",
        "ongoing":"Ongoing",
        "youarenowanadmin":"You are now an admin",
        "orsearchtostartaconvo":"or Search to start a Convo!",
        "forwardmessageuserlimit": "You can only forward with up to {{size}} users or groups",

    },
    "alertText": {
        "areyousureyouwantto": "Are you sure you want to",
        "areyousureyouwanttoclearthechat?":"Are you sure you want to Clear the chat ?",
        "areyousureyouwanttodeletethechat?":"Are you sure you want to Delete the chat ?",
        "report?":"Report ?",
        "areyousureyouwanttoexitfromthegroup?":"Are you sure you want to Exit from the group ?",
        "areyousureyouwanttodeletethegroup?":"Are you sure you want to Delete the Group ?",
        "areyousureyouwanttologout?":"Are you sure you want to logout?",
        "areyousureyouwanttounstarallmessages?":"Are you sure you want to Unstar all messages?",
        "deletingyouraccountwill":"Deleting your account will:",
        "deleteyouraccountfromenkrip":"Delete your account from ENKRIP",
        "eraseyourmessagehistory":"Erase your message history",
        "deleteyoufromallofyourenkripgroups":"Delete you from all of your ENKRIP groups",
        "todeleteyouraccountconfirmyourcountryandenteryourphonenumber":"To delete your account, confirm your country and enter your phone number.",
        "selectminimumoftwocontacts":"Select minimum of two contacts",
        "unblockcontact":"Unblock Contact",
        "removeyourprofilephoto":"Remove your profile photo?",
        "anadminforthisgroup?":"an admin for this group?",
        "nomessagedeliveredyet":"No message delivered yet!",
        "nooneisreadyet":"No one is read yet!",
        "clickittotranslatethemessage":"Click it to translate the message",
        "chatarchived":"Chat archived",
        "chatunarchived":"Chat unarchived",
        "pinchat":"Pin chat",
        "unpinchat":"Unpin chat",
        "mouseoverreceivedmessagechatbubblewhichdisplaystranslatemessageicon,":" Mouse over received message chat bubble which displays Translate Message icon,",
        "sorrycouldn'tstartthecallbecauseofnetworkerrorpleasecheckyourinternetconnectionandtryagain":"Sorry Couldn't start the call because of network error. Please check your internet connection and try again",
        "deleteyoufromallofyourenkripgroups":"Delete you from all of your ENKRIP groups",
        "todeleteyouraccountconfirmyourcountryandenteryourphonenumber":"To delete your account, confirm your country and enter your phone number.",
        "readytojoin":"Ready to join ?",
        "pleasecheckyourinternetconnection":"Please check your internet connection",
        "joinnow":"Join Now",
        "yourealreadyinacall":"You're already in a call",
        "joinvideocall":"Join video call",
        "proceedtodeleteyouraccount?":"Proceed to delete your account?",
        "deletingyouraccountispermanentyourdatacannotberecoveredifyoureactivateyourenkripaccountinfuture.":"Deleting your account is permanent. Your data cannot be recovered if you reactivate your ENKRIP account in future.",
        "areyousureyouwanttodeleteselectedmessage":"Are you sure you want to delete selected message",
        "doyouwanttoleaveyourcalltojointhisone":"Do you want to leave your call to join this one?",
        "areyousureyouwanttoswitchtovideocall?":"Are you sure you want to switch to Video call?",
        "requestingtoswitchtovideocall":"Requesting to switch to video call.",
        "allmembersofthegrouparealreadyinthecall":"All members of the group are already in the call.",
        "alltheusersofyourcontactsalreadyincall":"All the users of your contacts already in call.",
        "cameraandmicrophoneaccessrequiredtocontinuevideocallpleaseenableandtryagain":"Camera and Microphone access required to continue video call, please enable and try again!",
        "microphoneaccessrequiredtocontinuetheaudiocallpleaseenableandtryagain":"Microphone access required to continue the audio call, please enable and try again!",
        "cameraaccessrequiredtosharethevideopleaseenableandtryagain":"Camera access required to share the video, please enable and try again!",
        "seemslikeyouhaveaproblemwithyourmicrophonepleasecheckandtryagain":"Seems like you have a problem with your microphone, please check and try again!",
        "clearCallLogConfirmMsg":"Do you want to clear your entire call log?"
    },
    "toastsucess": {
        "profileupdatedsuccessfully": "Profile updated successfully",
        "success":"success",
        "youwerenolongerfromthegroup":"You were no longer from the group.",
        "profileupdatedsuccessfully":"profile updated successfully",
        "profilepictureremovedsuccessfully":"Profile picture removed successfully"
    },
    "toasterror": {
        "cantsharemorethan": "Can't share more than",
        "mediaitems":"media items.",
        "unsupportedfileformatfilesallowed":"Unsupported file format. Files allowed:",
        "pngjpgjpeggifbmp":"png, jpg, jpeg, gif, bmp",
        "hasbeenunblocked":"has been unblocked",
        "hasbeenblocked":"has been blocked",
        "reportsent":"Report sent",
        "tryagain":"Try Again",
        "pleaseselectanyparticipant":"Please select any Participant",
        "imagetypeonlyallowedtoupload":"Image type only allowed to upload",
        "unabletorecordaudiowhileoncall":"Unable to record audio while on call."
    },
    "permission": {
        "cameranotauthorizedpleasecheckyourmediapermissionssettings": "Camera not authorized. Please check your media permissions settings",
        "audiomicrophonenotauthorizedpleasecheckyourmediapermissionssettings":"Audio Microphone not authorized. Please check your media permissions settings",
        "permissiondenied":"Permission denied",
        "camerapermissiondisabled":"Camera Permission Disabled",
        "startstopcamera":"Start / Stop Camera",
        "notallowed":"not-allowed",
        "pleaseenablebrowsernotifications":"Please enable browser notifications"
    },
    "validation": {
        "makesuretouploadajpgjpegpngmp3mp4pgfxlsxlsxdocdocpptpptxrarzipcsvortxtfile": " Make sure to upload a 'JPG, JPEG, PNG, MP3, MP4, PDF, XLS, XLSX, DOC, DOC, PPT, PPTX, RAR, ZIP,CSV or TXT' file",
        "membersallowedincall":"members allowed in call!",
        "imagetypeonlyallowedtoupload":"Image type only allowed to upload",
        "selectminimumofonecontacts":"Select minimum of one contacts",
        "cantplaceanewcallwhileyourealreadyinacall":"Can't place a new call while you're already in a call.",
    },
    "errors": {
        "pleaseprovidegroupname":"Please provide group name",
        "themobilenumberyouentereddoesntmatchyouraccount":"The mobile number you entered doesn't match your account.",
        "statuscannotbeempty":"Status cannot be empty.",
        "checkyournetworkconnectivity":"Check your network connectivity.",
        "filesizeshouldnotexceed2mb":"File size should not exceed 2MB.",
        "haveexceededthesizelimitof":"have exceeded the size limit of",
        "therewasanerrorwithaccessingthecamera":"There was an error with accessing the camera.",
        "pleasecheckyourinternetconnection":"Please check your Internet connection.",
        "makesureyourcomputerhasactiveinternetconnection":"Make sure your computer has active internet connection.",
        "theserverisnotrespondingpleasetryagainlater":"The server is not responding. Please try again later.",
        "pleaseenteryouremail":"Please enter your email",
        "pleaseentervalidemail":"Please enter valid email",
        "pleaseenteryourusername":"Please enter your username",
        "usernameistooshort":"Username is too short",
        "pleaseenteryourstatus":"Please enter your status",
        "somethingwentwrongpleasetryagain":"Something went wrong. Please try again."


    },
    "call": {
        "status":{
            "reconnecting": "Reconnecting",
            "connected": "Connected",
            "disconnected": "Disconnected",
            "connecting": "Connecting",
            "calling": "Calling",
            "engaged": "Engaged",
            "ringing": "Ringing",
            "busy": "Busy",
            "hold": "Hold"
        },
        "message": {
            "callonhold": "Call on hold",
            "userisbusy": "User is busy...",
            "busyonanothercall": "Busy on another call"
        }
    },
    "chatTag":{
        "title": "Chat Tags",
        "createTag":{
            "title": "Create Tag",
            "tagLine": "Create chat tags for different groups of chats and quick switch between them.",
            "buttonLabel": "Create New Tag",
            "tagName": "Tag Name",
            "includePeopleorGroup": "Include People or Group",
            "tagLine1": "Choose people or groups that will appear under this tag",
            "addPeopleOrGroup": "Add People or Group",
            "addPeople": "Add People",
            "discardConfirmMsg": "This tag is Unsaved. Are you sure to go back?",
            "validation":{
                "tagNameEmpty": "Tag name is required",
                "tagNameDuplicate": "Tag name already exist",
                "selectChatsEmpty": "Please select people or group"
            },
            "maxAllowedErrMsg": "You have already created a maximum number of chat tags"
        },
        "deleteTag":{
            "title": "Delete Tag",
            "deleteConfirmMsg": "This will remove your chat tag. Your chats will not be deleted."
        },
        "recommendedTags":{
            "title": "Recommended Chat Tags",
            "optionsTagLine": "Choose people or groups that will appear under this tag",
            "option1":{
                "title": "Friends",
            },
            "option2":{
                "title": "Family",
            },
            "option3":{
                "title": "Personal",
            }
        },
        "editTag": {
            "title": "Edit Tag",
            "tagLine": "Tap -Edit’ to change the order delete tags"
        }
    },
    "webchatUserHere": {
        "useHereBtn": "USE HERE",
        "desc": "CHAT is open in another window. Click “Use Here” to use CHAT in this window."
    }
}

