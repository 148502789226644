/**
 * Get posts list
 */
export const FETCH_POSTS_REQUEST = 'FETCH_POSTS_REQUEST';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE';
export const FETCH_POSTS_DELETE_BY_POST_ID = 'FETCH_POSTS_DELETE_BY_POST_ID';
export const FETCH_POSTS_RESET = 'FETCH_POSTS_RESET';
export const DELETE_POST_REQUEST = 'DELETE_POST_REQUEST';
export const DELETE_POST_SUCCESS  = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAILURE = 'DELETE_POST_FAILURE';
export const DELETE_POST_RESET = 'DELETE_POST_RESET';

export const POSTS_LIST_API_DATETIME = 'POSTS_LIST_API_DATETIME';
