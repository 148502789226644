import { GLOBAL_SEARCH_INPUT_STATE_UPDATE } from "./actionTypes";


const initialState = {
  searchQuery:'',
  type:'posts'
};

export const globalSearchInputStateReducer = (state = initialState, action) => {

    switch (action.type) {
        case GLOBAL_SEARCH_INPUT_STATE_UPDATE:
          return {
            ...state,
            type: action.payload.data.type,
            searchQuery: action.payload.data.searchQuery,
          };
        default:
          return state;
      }
}
